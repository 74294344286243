import Big from 'big.js'
import { match } from 'ts-pattern'

import { defaultMakerFeeRate } from '../gateway/coinbase/fees'
import { LimitOrderType } from '../types/index'

const getPostOnly = (post_only: 'true' | 'false' | undefined): boolean => {
  return match(post_only)
    .with('true', () => true)
    .with('false', () => false)
    .with(undefined, () => false)
    .otherwise(() => false)
}

const createLimitSellOrder = (
  have: Big,
  need: Big,
  tradepair: string,
  increment: number,
  limitPrice: number,
  post_only: 'true' | 'false' | undefined,
): LimitOrderType => {
  const totalQuantity = have.minus(need)

  const total = totalQuantity.minus(totalQuantity.mod(increment))
  const quantity = total.valueOf()

  const fee = total.mul(defaultMakerFeeRate).div(100)
  const totalWithoutFees = total.minus(fee)
  const amount = totalWithoutFees.mul(limitPrice)

  return {
    side: 'sell',
    quantity,
    tradepair,
    price: limitPrice,
    amount,
    post_only: getPostOnly(post_only),
  }
}

const createLimitBuyOrder = (
  have: Big,
  need: Big,
  tradepair: string,
  increment: number,
  limitPrice: number,
  post_only: 'true' | 'false' | undefined,
): LimitOrderType => {
  const availableToBuy = need.minus(have)

  const fee = defaultMakerFeeRate.div(100)
  const totalQuantity = availableToBuy.div(fee.plus(1))

  const total = totalQuantity.minus(totalQuantity.mod(increment))
  const quantity = total.valueOf()

  const amount = availableToBuy.mul(limitPrice)

  return {
    side: 'buy',
    quantity,
    tradepair,
    price: limitPrice,
    amount,
    post_only: getPostOnly(post_only),
  }
}

export default function getLimitOrder(
  baseBalanceInBaseCurrency: Big,
  needQuanity: Big,
  tradepair: string,
  baseIncrement: number,
  limitPrice: number,
  post_only: 'true' | 'false' | undefined,
): LimitOrderType {
  // Both of these limit orders have to be submitted in the base currency
  const order = baseBalanceInBaseCurrency.gt(needQuanity)
    ? createLimitSellOrder(
      baseBalanceInBaseCurrency,
      needQuanity,
      tradepair,
      baseIncrement,
      limitPrice,
      post_only,
    )
    : createLimitBuyOrder(
      baseBalanceInBaseCurrency,
      needQuanity,
      tradepair,
      baseIncrement,
      limitPrice,
      post_only,
    )

  return order
}
