import type { PyodideClient } from '../'
import { parsePlotParameters } from '../'
import { PyProxyPlotParameters } from '../codecs'

import { Unsafe } from './unsafe'

export const plot = ({
  addPlot,
  pyodideClient,
}: {
  addPlot: (name: string, values: number[], color: string) => void
  pyodideClient: PyodideClient
}) =>
  (unsafePlotParameters: Unsafe<PyProxyPlotParameters>): void => {
    const parameters = parsePlotParameters(pyodideClient)(unsafePlotParameters)
    const color = parameters.color ?? '#03a9f4'

    addPlot(parameters.name, parameters.series, color)
  }
