import { match } from 'ts-pattern'

import {
  fifteenMinute,
  fiveMinute,
  oneDay,
  oneHour,
  oneMinute,
  sixHour,
  Timeframe as tf,
} from '@stratos-trade/io-ts'

import { Timeframe } from '../types/index'

export function coinbaseToTimeframeUnit(timeframe: Timeframe) {
  return match(timeframe)
    .with(60, (): tf => oneMinute)
    .with(300, (): tf => fiveMinute)
    .with(900, (): tf => fifteenMinute)
    .with(3600, (): tf => oneHour)
    .with(21600, (): tf => sixHour)
    .with(86400, (): tf => oneDay)
    .run()
}
