import { API } from 'aws-amplify'

import type { OrderResponse } from '../..'
import { getCurrentUser } from '../../../components/Auth/AppUser'
const apiName = 'Endpoint'

export const deleteOrder = ({ isTestnet }: { isTestnet: boolean }) =>
  async ({
    productId,
    orderId,
  }: {
    productId: string
    orderId: string
  }): Promise<OrderResponse> => {
    return await API.del(apiName, 'backend/coinbase/delete', {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        orderId: orderId,
        productId: productId,
        email: getCurrentUser().email,
        useSandbox: isTestnet,
      },
    })
      .then((response): OrderResponse => {
        return response === orderId
          ? { type: 'ok', order: `${orderId} deleted` }
          : { type: 'error', message: 'error deleting order' }
      })
      .catch((err: Error): OrderResponse => ({ type: 'error', message: String(err) }))
  }
