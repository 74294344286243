import Big from 'big.js'
import { match } from 'ts-pattern'

import type { Balances, Gateway, KrakenGateway, LimitOrderBook } from '../gateway'
import { CoinbaseTradepairs } from '../gateway/coinbase/rest/codecs'
import { CandleEvent } from '../types/index'

import { compareLimitOrders } from './compare-limit-orders'
import { compareKrakenLimitOrders } from './compare-kraken-limit-orders'
import { deleteOrders, deleteKrakenOrders } from './delete-orders'
import { getCurrentState } from './get-current-state'
import { handleLimitSignal, handleMarketSignal } from './handle-signal'

import type { EventLogEvent, UserSignalEventGroup } from '.'
import { KrakenTradepairs } from '../gateway/kraken/rest/codecs'
import { handleKrakenLimitSignal, handleKrakenMarketSignal } from './handle-kraken-signal'

/*
 * This is required to be at least 9 to display the user balances
 * in written out instead of numerial form. This will match that
 * of Coinbase.
 */
Big.NE = -9

/**
 * Ensure all signals from the `latestSignal` event group have been
 * successfully handled. This means <dot dot dot, still figuring this
 * out / to be continued>
 */
export const dispatchOrders = ({
  tradepair,
  balances,
  candles,
  orderBook,
  eventlog,
  latestSignal,
  gateway,
  products,
}: {
  tradepair: string
  balances: Balances
  candles: CandleEvent
  orderBook: LimitOrderBook
  eventlog: EventLogEvent[]
  latestSignal: UserSignalEventGroup | undefined
  gateway: Gateway
  products: CoinbaseTradepairs
}): void => {
  // We need this so that when it isn't in execute we immediately return
  if (latestSignal === undefined) {
    return
  }
  // see if we can submit order with current state
  // otherwise return
  // if we can submit orders get the current signal (returned from currnt state)
  const ready = getCurrentState(eventlog)

  function last(array: readonly EventLogEvent[]) {
    return array[array.length - 1]
  }

  if (ready) {
    const signal = last(eventlog)
    const unhandledSignalEvents = latestSignal.signals === []
      ? match(signal)
        .with({ type: 'signal' }, (signal) => {
          return signal.signals
        })
        .otherwise(() => {
          return []
        })
      : latestSignal.signals

    let candlePrice: number
    if (candles.event === 'new series') {
      return
    } else {
      candlePrice = candles.candle.close
    }

    const marketOrders = unhandledSignalEvents
      .flatMap((value) => value.type === 'market' ? value : [])

    const limitOrdersAbovePrice = unhandledSignalEvents
      .flatMap((value) => value.type === 'limit' ? value : [])
      .filter((value) => value.price >= candlePrice)
      .sort((a, b) => {
        return a.price - b.price
      })

    const limitOrdersBelowPrice = unhandledSignalEvents
      .flatMap((value) => value.type === 'limit' ? value : [])
      .filter((value) => value.price <= candlePrice)
      .sort((a, b) => {
        return b.price - a.price
      })

    // TODO(ST-173): We need to move this filter upstream so we aren't filtering
    // on every render
    // Check to see if we have any limit orders on the books that may
    // need to be deleted filtering by selected tradepair
    const areLimitOrders = Array.from(orderBook.bids.values()).concat(
      Array.from(orderBook.asks.values()),
    )
      .map((value) => value.filter((item) => item.productID === tradepair))
      .filter((value) => value.length > 0)

    if (areLimitOrders.length > 0) {
      // This is the case in which we have limit orders on the books, but no
      // limit orders to be placed in the current signal. So we know we need
      // to delete whatever is on the books because we only have a market order.
      if (limitOrdersAbovePrice.length === 0 && limitOrdersBelowPrice.length === 0) {
        deleteOrders(
          orderBook,
          eventlog,
          tradepair,
          gateway,
        )
        return
      }

      // We need to trigger a delete if we have fewer desired orders than current orders on the books
      if (
        areLimitOrders.length
        > limitOrdersAbovePrice.length + limitOrdersBelowPrice.length
      ) {
        deleteOrders(
          orderBook,
          eventlog,
          tradepair,
          gateway,
        )
        return
      }

      const totalLimitsToSubmit = limitOrdersAbovePrice.length
        + limitOrdersBelowPrice.length
      // console.log('total', totalLimitsToSubmit)
      // console.log('total2', limitOrdersAbovePrice)

      // Step 1: Check to see if our new limit orders will be above the threshold
      // Check limit orders above and below price, return if at least one is above threshold
      const isAboveThreshold = compareLimitOrders(
        tradepair,
        balances,
        products,
        limitOrdersAbovePrice,
        false,
        orderBook,
        candlePrice,
        totalLimitsToSubmit,
        areLimitOrders,
      ).then((value) => {
        if (value) {
          return value
        } else {
          return compareLimitOrders(
            tradepair,
            balances,
            products,
            limitOrdersBelowPrice,
            true,
            orderBook,
            candlePrice,
            totalLimitsToSubmit,
            areLimitOrders,
          )
        }
      })

      // Step 2: If one limit order is above the quantity threshold begin deleting
      isAboveThreshold.then((isAbove) => {
        if (isAbove) {
          deleteOrders(
            orderBook,
            eventlog,
            tradepair,
            gateway,
          )
          return
        }
      })

      return
    }

    // Step 3: Once you have checked in the event log is ready and
    // successfully delete any open orders, we can now submit orders
    // form the latest signal

    // First we want to handle the market signal because it should execute first
    const productBalances = handleMarketSignal(
      tradepair,
      balances,
      products,
      marketOrders,
      eventlog,
      candlePrice,
      gateway,
    )
    const baseBalanceInBaseCurrency = productBalances.baseBalanceInBaseCurrency
    const quoteBalance = productBalances.quoteBalance
    const baseIncrement = productBalances.baseIncrement
    const quoteIncrement = productBalances.quoteIncrement
    const minSize = productBalances.minSize

    // Second handle limit signals that are above price using the "supposed"
    // new balances upon the fill of the market orders (if any)
    handleLimitSignal(
      tradepair,
      limitOrdersAbovePrice,
      eventlog,
      gateway,
      baseBalanceInBaseCurrency,
      quoteBalance,
      baseIncrement,
      quoteIncrement,
      minSize,
      candlePrice,
      false,
    )

    // Third handle limit signals that are below price using the "supposed"
    // new balances upon the fill of the market orders (if any)
    handleLimitSignal(
      tradepair,
      limitOrdersBelowPrice,
      eventlog,
      gateway,
      baseBalanceInBaseCurrency,
      quoteBalance,
      baseIncrement,
      quoteIncrement,
      minSize,
      candlePrice,
      true,
    )

  }

  return
}


export const dispatchKrakenOrders = ({
  tradepair,
  balances,
  candles,
  orderBook,
  eventlog,
  latestSignal,
  gateway,
  products,
}: {
  tradepair: string
  balances: Balances
  candles: CandleEvent
  orderBook: LimitOrderBook
  eventlog: EventLogEvent[]
  latestSignal: UserSignalEventGroup | undefined
  gateway: KrakenGateway
  products: KrakenTradepairs
}): void => {
  // We need this so that when it isn't in execute we immediately return
  if (latestSignal === undefined) {
    return
  }
  // see if we can submit order with current state
  // otherwise return
  // if we can submit orders get the current signal (returned from currnt state)
  const ready = getCurrentState(eventlog)

  function last(array: readonly EventLogEvent[]) {
    return array[array.length - 1]
  }

  if (ready) {
    const signal = last(eventlog)
    const unhandledSignalEvents = latestSignal.signals === []
      ? match(signal)
        .with({ type: 'signal' }, (signal) => {
          return signal.signals
        })
        .otherwise(() => {
          return []
        })
      : latestSignal.signals

    let candlePrice: number
    if (candles.event === 'new series') {
      return
    } else {
      candlePrice = candles.candle.close
    }

    const marketOrders = unhandledSignalEvents
      .flatMap((value) => value.type === 'market' ? value : [])

    const limitOrdersAbovePrice = unhandledSignalEvents
      .flatMap((value) => value.type === 'limit' ? value : [])
      .filter((value) => value.price >= candlePrice)
      .sort((a, b) => {
        return a.price - b.price
      })

    const limitOrdersBelowPrice = unhandledSignalEvents
      .flatMap((value) => value.type === 'limit' ? value : [])
      .filter((value) => value.price <= candlePrice)
      .sort((a, b) => {
        return b.price - a.price
      })

    // TODO(ST-173): We need to move this filter upstream so we aren't filtering
    // on every render
    // Check to see if we have any limit orders on the books that may
    // need to be deleted filtering by selected tradepair
    const areLimitOrders = Array.from(orderBook.bids.values()).concat(
      Array.from(orderBook.asks.values()),
    )
      .map((value) => value.filter((item) => item.productID === tradepair))
      .filter((value) => value.length > 0)

    if (areLimitOrders.length > 0) {
      // This is the case in which we have limit orders on the books, but no
      // limit orders to be placed in the current signal. So we know we need
      // to delete whatever is on the books because we only have a market order.
      if (limitOrdersAbovePrice.length === 0 && limitOrdersBelowPrice.length === 0) {
        deleteKrakenOrders(
          orderBook,
          eventlog,
          tradepair,
          gateway,
        )
        return
      }

      // We need to trigger a delete if we have fewer desired orders than current orders on the books
      if (
        areLimitOrders.length
        > limitOrdersAbovePrice.length + limitOrdersBelowPrice.length
      ) {
        deleteKrakenOrders(
          orderBook,
          eventlog,
          tradepair,
          gateway,
        )
        return
      }

      const totalLimitsToSubmit = limitOrdersAbovePrice.length
        + limitOrdersBelowPrice.length

      // Step 1: Check to see if our new limit orders will be above the threshold
      // Check limit orders above and below price, return if at least one is above threshold
      const isAboveThreshold = compareKrakenLimitOrders(
        tradepair,
        balances,
        products,
        limitOrdersAbovePrice,
        false,
        orderBook,
        candlePrice,
        totalLimitsToSubmit,
        areLimitOrders,
      ).then((value) => {
        if (value) {
          return value
        } else {
          return compareKrakenLimitOrders(
            tradepair,
            balances,
            products,
            limitOrdersBelowPrice,
            true,
            orderBook,
            candlePrice,
            totalLimitsToSubmit,
            areLimitOrders,
          )
        }
      })

      // Step 2: If one limit order is above the quantity threshold begin deleting
      isAboveThreshold.then((isAbove) => {
        if (isAbove) {
          deleteKrakenOrders(
            orderBook,
            eventlog,
            tradepair,
            gateway,
          )
          return
        }
      })

      return
    }

    // Step 3: Once you have checked in the event log is ready and
    // successfully delete any open orders, we can now submit orders
    // form the latest signal

    // First we want to handle the market signal because it should execute first
    const productBalances = handleKrakenMarketSignal(
      tradepair,
      balances,
      products,
      marketOrders,
      eventlog,
      candlePrice,
      gateway,
    )
    const baseBalanceInBaseCurrency = productBalances.baseBalanceInBaseCurrency
    const quoteBalance = productBalances.quoteBalance
    const minSize = productBalances.minSize

    // Second handle limit signals that are above price using the "supposed"
    // new balances upon the fill of the market orders (if any)
    handleKrakenLimitSignal(
      tradepair,
      limitOrdersAbovePrice,
      eventlog,
      gateway,
      baseBalanceInBaseCurrency,
      quoteBalance,
      minSize,
      candlePrice,
      false,
    )

    // Third handle limit signals that are below price using the "supposed"
    // new balances upon the fill of the market orders (if any)
    handleKrakenLimitSignal(
      tradepair,
      limitOrdersBelowPrice,
      eventlog,
      gateway,
      baseBalanceInBaseCurrency,
      quoteBalance,
      minSize,
      candlePrice,
      true,
    )

  }

  return
}