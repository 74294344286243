import { API } from 'aws-amplify'
import * as E from 'fp-ts/Either'
import { KrakenTradepairDataGroup } from './codecs'

export const getTradepairData = () =>
    async (): Promise<KrakenTradepairDataGroup> => {
        const apiName = 'Endpoint'

        return await API.get(apiName, 'backend/kraken/assets', {})
            .then((response: any): KrakenTradepairDataGroup => {
                const tradepairData = KrakenTradepairDataGroup.decode(Object.values(response.result))

                if (E.isLeft(tradepairData)) {
                    throw new Error('Received unexpected data from kraken Assets endpoint')
                }

                return tradepairData.right
            })
    }
