import Big from 'big.js'
import { match } from 'ts-pattern'

import type { Balances, KrakenGateway, OrderResponse } from '../gateway'
import { KrakenProductBalance } from '../types/index'

import { UserSignalLimitEvent, UserSignalMarketEvent } from './event-log'
import { getCurrentTradepairPorfolio, getKrakenBalances } from './get-current-portfolio'
import { getDesiredTradepairPortfolio } from './get-desired-portfolio'

import type { EventLogEvent } from '.'
import getKrakenLimitOrder from './get-kraken-limit-order'
import { KrakenTradepairs } from '../gateway/kraken/rest/codecs'
import getKrakenMarketOrder from './get-kraken-market-order'
import { Observable, of } from 'rxjs'

const $ = require('jquery')

let productBalances: KrakenProductBalance = {
  baseBalanceInBaseCurrency: new Big(0),
  quoteBalance: new Big(0),
  minSize: 0,
}

export function handleKrakenMarketSignal(
  tradepair: string,
  balances: Balances,
  products: KrakenTradepairs,
  marketOrders: UserSignalMarketEvent[],
  eventlog: EventLogEvent[],
  candlePrice: number,
  gateway: KrakenGateway,
): KrakenProductBalance {

  productBalances = getKrakenBalances(
    tradepair,
    balances,
    products,
  )

  marketOrders.map(async (signal): Promise<void> => {
    // Immediately record the order in motion
    eventlog.push({ type: 'order requested', signal: signal })

    // TODO: display notification of order
    const recordSuccess = () =>
      eventlog.push({
        type: 'order placed successfully',
        signal: signal,
      })
    const recordFailure = () =>
      eventlog.push({
        type: 'order submission failed',
        signal: signal,
      })

    const submitOrder = (): Observable<OrderResponse> => {
      const currentPortfolio = getCurrentTradepairPorfolio(
        candlePrice,
        productBalances.baseBalanceInBaseCurrency,
        productBalances.quoteBalance,
      )

      const needQuantity = getDesiredTradepairPortfolio(
        signal.exposure,
        currentPortfolio.totalBalanceInBase,
      )

      const order = getKrakenMarketOrder(
        productBalances.baseBalanceInBaseCurrency,
        tradepair,
        needQuantity,
        candlePrice,
      )

      // DISCUSS: what do we want this threshold to be?
      // minSize is in the quote currency
      if (
        order.side === 'sell'
        // we have to convert it to the base currency, since sell orders are in
        // base currency
        && productBalances.minSize >= parseFloat(order.quantity)
      ) {
        return of({
          type: 'ok',
          order: 'Below min order size',
        })
      } else if (
        order.side === 'buy'
        // Buy orders use funds in quote currency
        && productBalances.minSize >= parseFloat(order.quantity)
      ) {
        return of({
          type: 'ok',
          order: 'Below min order size',
        })
      }

      productBalances.baseBalanceInBaseCurrency = order.side === 'buy'
        ? productBalances.baseBalanceInBaseCurrency.plus(order.quantity)
        : productBalances.baseBalanceInBaseCurrency.minus(order.quantity)

      productBalances.quoteBalance = order.side === 'buy'
        ? productBalances.quoteBalance.minus(order.amount)
        : productBalances.quoteBalance.plus(order.amount)

      return gateway.submitMarketOrder(order)
    }

    // Record the outcome of the order request, when known
    submitOrder().subscribe((order) => {
      match(order.type)
        .exhaustive()
        .with('ok', () => {
          recordSuccess()
        })
        .with('error', () => {
          const value = new Date().toLocaleTimeString()
          document.getElementById('toast-date')!.innerHTML = value
          $('.toast').toast('show')
          return recordFailure()
        })
        .run()
    })
  })

  return productBalances
}


let updatedBaseBalanceInBaseCurrency = new Big(0)
let updatedQuoteBalance = new Big(0)
let updatedMinSize = 0

export async function handleKrakenLimitSignal(
  tradepair: string,
  limitOrders: UserSignalLimitEvent[],
  eventlog: EventLogEvent[],
  gateway: KrakenGateway,
  baseBalanceInBaseCurrency: Big,
  quoteBalance: Big,
  minSize: number,
  candlePrice: number,
  bids: boolean,
) {
  updatedBaseBalanceInBaseCurrency = baseBalanceInBaseCurrency
  updatedQuoteBalance = quoteBalance
  updatedMinSize = minSize

  limitOrders.map(async (signal): Promise<void> => {
    // Immediately record the order in motion
    eventlog.push({ type: 'order requested', signal: signal })

    // TODO: display notification of order
    const recordSuccess = () => {
      eventlog.push({
        type: 'order placed successfully',
        signal: signal,
      })
    }

    const recordFailure = () => {
      eventlog.push({
        type: 'order submission failed',
        signal: signal,
      })
    }

    const limitPrice = signal.price

    const submitOrder = (): Observable<OrderResponse> => {
      const currentPorfolio = getCurrentTradepairPorfolio(
        limitPrice,
        updatedBaseBalanceInBaseCurrency,
        updatedQuoteBalance,
      )

      const needQuantity = getDesiredTradepairPortfolio(
        signal.exposure,
        currentPorfolio.totalBalanceInBase,
      )

      // Pirce must be specified in quote_increment product units
      const order = getKrakenLimitOrder(
        updatedBaseBalanceInBaseCurrency,
        needQuantity,
        tradepair,
        limitPrice,
      )
      // Check to see if order is too small
      if (updatedMinSize >= parseFloat(order.quantity)) {
        return of({
          type: 'ok',
          order: 'Below min order size',
        })
      }

      // Check to see if the order would go through as a market, if so don't submit
      if (order.side === 'sell' && candlePrice > signal.price) {
        return of({
          type: 'ok',
          order: 'Limit order would submit at incorrect price',
        })
      }

      if (order.side === 'buy' && candlePrice < signal.price) {
        return of({
          type: 'ok',
          order: 'Limit order would submit at incorrect price',
        })
      }

      if (signal.price === candlePrice) {
        if (bids && order.side === 'sell') {
          return of({
            type: 'ok',
            order: 'Handled by other side',
          })
        }
        if (!bids && order.side === 'buy') {
          return of({
            type: 'ok',
            order: 'Handled by other side',
          })
        }
      }

      updatedBaseBalanceInBaseCurrency = order.side === 'buy'
        ? updatedBaseBalanceInBaseCurrency.plus(order.quantity)
        : updatedBaseBalanceInBaseCurrency.minus(order.quantity)

      updatedQuoteBalance = order.side === 'buy'
        ? updatedQuoteBalance.minus(order.amount)
        : updatedQuoteBalance.plus(order.amount)

      return gateway.submitLimitOrder(order)
    }

    // Record the outcome of the order request, when known
    submitOrder().subscribe((order) => {
      match(order.type)
        .exhaustive()
        .with('ok', () => {
          recordSuccess()
        })
        .with('error', () => {
          const value = new Date().toLocaleTimeString()
          document.getElementById('toast-date')!.innerHTML = value
          $('.toast').toast('show')
          return recordFailure()
        })
        .run()
    })
  })
}
