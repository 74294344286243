import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as PathReporter from 'io-ts/lib/PathReporter'
import * as $ from 'rxjs'
import { WebSocketSubject } from 'rxjs/webSocket'
import { match } from 'ts-pattern'
import type { Candle, KrakenTimeframe, Timeframe } from '../../../types/index'
import {
    KrakenConnectionMessage,
    KrakenHeartbeatMessage,
    KrakenSubscriptionMessage,
    KrakenTickerMessage,
    KrakenTickerWebsocketMessage,
    TickerMessage
} from '../websocket/codecs'
import { API } from 'aws-amplify'
import { KrakenHistoricalCandleGroupFromKrakenHistoricalCandles } from '../rest/codecs'
import D from 'od'

//Should we move this out?
const getStartOfCandle = (timeframe: KrakenTimeframe, etime: Date) => {
    switch(timeframe) {
        case 1: return D.subtract('minute', 1, etime)
        case 5: return D.subtract('minute', 5, etime)
        case 15: return D.subtract('minute', 15, etime)
        case 30: return D.subtract('minute', 30, etime)
        case 60: return D.subtract('hour', 1, etime)
        case 240: return D.subtract('hour', 4, etime)
        case 1440: return D.subtract('day', 1, etime)
    }
}

const getHistoricalCandles = async ({
    timeframe,
    productID
}: {
    timeframe: KrakenTimeframe,
    productID: string
}): Promise<Candle[]> => {
    const apiName = 'Endpoint'

    return await API.post(apiName, 'backend/kraken/candles', {
        body: {
            timeframe: timeframe,
            tradepair: productID.replace('/', '')
        }
    })
        .then(async (response: any) => {
            const maybeCandles = KrakenHistoricalCandleGroupFromKrakenHistoricalCandles.decode(response)

            if (E.isLeft(maybeCandles)) {
                throw new Error('Unexpected candles API response')
            }

            const candles = maybeCandles.right

            return candles.asset.map(candle => {
                return {
                    time: candle[0],
                    open: candle[1],
                    high: candle[2],
                    low: candle[3],
                    close: candle[4],
                    volume: candle[6]
                }
            })
        })
}

const subscribeToExchangeMatches = async ({
    timeframe,
    productID,
    mainWebsocket,
}: {
    timeframe: Timeframe
    productID: string
    mainWebsocket: WebSocketSubject<unknown>
}): Promise<$.Observable<Candle>> => {
    const subscribeToTickerMessage = {
        event: "subscribe",
        pair: [
            productID
        ],
        subscription: {
            "interval": timeframe,
            "name": "ohlc"
        }
    } as const


    const unsubscribeToTickerMessage = {
        "event": "unsubscribe",
        "pair": [
            productID
        ],
        "subscription": {
            "interval": timeframe,
            "name": "ohlc",
        }
    } as const

    const tickerWebsocket = pipe(
        mainWebsocket.multiplex(
            () => (subscribeToTickerMessage),
            () => (unsubscribeToTickerMessage),
            // This is where we filter the message
            (message: any): boolean => message
        ),
        // Let's decide if we need to add this later
        // $.retry(),
    )

    type Ticker = {
        channelId: number,
        ohlcValues: [],
        channelName: string,
        pair: string
    }

    const reduceTicker = (ticker: KrakenTickerMessage) => {
        const keys = ['channelId', 'ohlcValues', 'channelName', 'pair']
        const ohlcKeys = ['time', 'etime', 'open', 'high', 'low', 'close', 'vwap', 'volume', 'count']

        const tickerObject = keys.reduce((acc, element, index) => {
            return { ...acc, [element]: ticker[index] }
        }, {} as Ticker)

        const fullTickerObject = tickerObject.ohlcValues.reduce((acc, element, index) => {
            if (index === 0 || index === 1) {
                return { ...acc, [ohlcKeys[index]]: Math.floor(element) }
            }
            return { ...acc, [ohlcKeys[index]]: element }
        }, {})

        return pipe(
            TickerMessage.decode(fullTickerObject),
            E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
            E.map((ticker) => ticker),
            E.getOrElseW((error) => {
                throw new Error('Ticker feed error: ' + error)
            }),
        )
    }

    return pipe(
        tickerWebsocket,
        $.mergeMap((websocketMessage) =>
            pipe(
                KrakenTickerWebsocketMessage.decode(websocketMessage),
                E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
                E.map((message) =>
                    match(message)
                        .when(KrakenTickerMessage.is, (ticker) => $.of(reduceTicker(ticker)))
                        .when(KrakenHeartbeatMessage.is, () => $.EMPTY)
                        .when(KrakenSubscriptionMessage.is, () => $.EMPTY)
                        .when(KrakenConnectionMessage.is, () => $.EMPTY)
                        .otherwise(() => {
                            console.warn('unmatched ticker message:', message)
                            return $.EMPTY
                        }),
                ),
                E.getOrElseW((error) => {
                    throw new Error('Ticker feed error: ' + error)
                }),
            ),
        ),
        $.map((candle) => {
            const nextCandle: Candle = {
                open: candle.open,
                low: candle.low,
                high: candle.high,
                close: candle.close,
                volume: candle.volume,
                time: getStartOfCandle(timeframe as KrakenTimeframe, candle.etime)
            }
            return nextCandle
        }),
    )
}

export const subscribeToCandles = ({
    mainWebsocket,
}: { mainWebsocket: WebSocketSubject<unknown> }) =>
    (parameters: {
        timeframe: KrakenTimeframe
        tradepair: string
    }): $.Observable<Candle> => {

        const historical = pipe(
            $.from(
                getHistoricalCandles({
                    timeframe: parameters.timeframe,
                    productID: parameters.tradepair,
                }),
            ),
            $.mergeMap((candles) => candles),
        )

        const realtime = pipe(
            $.from(
                subscribeToExchangeMatches({
                    timeframe: parameters.timeframe,
                    productID: parameters.tradepair,
                    mainWebsocket,
                }),
            ),
            // I don't love this pattern here but it got me compiling when I need it. Happy to
            // replace it
            $.mergeMap((observable) => observable),
        )
        return $.concat(historical, realtime)
    }