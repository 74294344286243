import Big from 'big.js'
import { match } from 'ts-pattern'

import type { Balances, Gateway, OrderResponse } from '../gateway'
import { CoinbaseTradepairs } from '../gateway/coinbase/rest/codecs'
import { ProductBalance } from '../types/index'

import { UserSignalLimitEvent, UserSignalMarketEvent } from './event-log'
import { getBalances, getCurrentTradepairPorfolio } from './get-current-portfolio'
import { getDesiredTradepairPortfolio } from './get-desired-portfolio'
import getLimitOrder from './get-limit-order'
import getMarketOrder from './get-market-order'

import type { EventLogEvent } from '.'

const $ = require('jquery')

let productBalances: ProductBalance = {
  baseBalanceInBaseCurrency: new Big(0),
  quoteBalance: new Big(0),
  baseIncrement: 0,
  quoteIncrement: 0,
  minSize: 0,
}

export function handleMarketSignal(
  tradepair: string,
  balances: Balances,
  products: CoinbaseTradepairs,
  marketOrders: UserSignalMarketEvent[],
  eventlog: EventLogEvent[],
  candlePrice: number,
  gateway: Gateway,
): ProductBalance {
 
  productBalances = getBalances(
    tradepair,
    balances,
    products,
  )

  marketOrders.map(async (signal): Promise<void> => {
    // Immediately record the order in motion
    eventlog.push({ type: 'order requested', signal: signal })

    // TODO: display notification of order
    const recordSuccess = () =>
      eventlog.push({
        type: 'order placed successfully',
        signal: signal,
      })
    const recordFailure = () =>
      eventlog.push({
        type: 'order submission failed',
        signal: signal,
      })

    const submitOrder = async (): Promise<OrderResponse> => {
      const currentPortfolio = getCurrentTradepairPorfolio(
        candlePrice,
        productBalances.baseBalanceInBaseCurrency,
        productBalances.quoteBalance,
      )

      const needQuantity = getDesiredTradepairPortfolio(
        signal.exposure,
        currentPortfolio.totalBalanceInQuote,
      )

      const order = getMarketOrder(
        productBalances.baseBalanceInBaseCurrency,
        currentPortfolio.baseBalanceInQuoteCurrency,
        tradepair,
        productBalances.baseIncrement,
        productBalances.quoteIncrement,
        needQuantity,
        candlePrice,
      )

      productBalances.baseBalanceInBaseCurrency = order.side === 'buy'
        ? productBalances.baseBalanceInBaseCurrency.plus(order.amount)
        : productBalances.baseBalanceInBaseCurrency.minus(order.quantity)

      productBalances.quoteBalance = order.side === 'buy'
        ? productBalances.quoteBalance.minus(order.quantity)
        : productBalances.quoteBalance.plus(order.amount)

      // DISCUSS: what do we want this threshold to be?
      // minSize is in the quote currency
      if (
        order.side === 'sell'
        // we have to convert it to the base currency, since sell orders are in
        // base currency
        && productBalances.minSize / candlePrice >= parseFloat(order.quantity)
      ) {
        return {
          type: 'ok',
          order: 'Below min order size',
        }
      } else if (
        order.side === 'buy'
        // Buy orders use funds in quote currency
        && productBalances.minSize >= parseFloat(order.quantity)
      ) {
        return {
          type: 'ok',
          order: 'Below min order size',
        }
      }
      return gateway.submitMarketOrder(order)
    }

    // Record the outcome of the order request, when known
    submitOrder()
      .then((order) => {
        match(order.type)
          .exhaustive()
          .with('ok', () => recordSuccess())
          .with('error', () => {
            const value = new Date().toLocaleTimeString()
            document.getElementById('toast-date')!.innerHTML = value
            $('.toast').toast('show')
            return recordFailure()
          })
          .run()
      })
      // just in case
      .catch(() => {
        recordFailure()
      })
  })

  return productBalances
}

let updatedBaseBalanceInBaseCurrency = new Big(0)
let updatedQuoteBalance = new Big(0)
let updatedBaseIncrement = 0
let updatedQuoteIncrement = 0
let updatedMinSize = 0

export async function handleLimitSignal(
  tradepair: string,
  limitOrders: UserSignalLimitEvent[],
  eventlog: EventLogEvent[],
  gateway: Gateway,
  baseBalanceInBaseCurrency: Big,
  quoteBalance: Big,
  baseIncrement: number,
  quoteIncrement: number,
  minSize: number,
  candlePrice: number,
  bids: boolean,
) {
  updatedBaseBalanceInBaseCurrency = baseBalanceInBaseCurrency
  updatedQuoteBalance = quoteBalance
  updatedBaseIncrement = baseIncrement
  updatedQuoteIncrement = quoteIncrement
  updatedMinSize = minSize
  limitOrders.map(async (signal): Promise<void> => {
    // Immediately record the order in motion
    eventlog.push({ type: 'order requested', signal: signal })

    // TODO: display notification of order
    const recordSuccess = () => {
      eventlog.push({
        type: 'order placed successfully',
        signal: signal,
      })
    }

    const recordFailure = () => {
      eventlog.push({
        type: 'order submission failed',
        signal: signal,
      })
    }

    // If you use the modulo then there is a floating point error. Have to change it into a Big
    // and then back into a number. Super gross
    const modPrice = new Big(signal.price)
    const limitPrice = modPrice.minus(modPrice.mod(updatedQuoteIncrement)).toNumber()

    const submitOrder = async (): Promise<OrderResponse> => {
      const currentPorfolio = getCurrentTradepairPorfolio(
        limitPrice,
        updatedBaseBalanceInBaseCurrency,
        updatedQuoteBalance,
      )

      const needQuantity = getDesiredTradepairPortfolio(
        signal.exposure,
        currentPorfolio.totalBalanceInBase,
      )

      // Pirce must be specified in quote_increment product units
      const order = getLimitOrder(
        updatedBaseBalanceInBaseCurrency,
        needQuantity,
        tradepair,
        updatedBaseIncrement,
        limitPrice,
        signal.post_only,
      )
      // Check to see if order is too small
      if (updatedMinSize / limitPrice >= parseFloat(order.quantity)) {
        return {
          type: 'ok',
          order: 'Below min order size',
        }
      }

      // Check to see if the order would go through as a market, if so don't submit
      if (order.side === 'sell' && candlePrice > signal.price) {
        return {
          type: 'ok',
          order: 'Limit order would submit at incorrect price',
        }
      }

      if (order.side === 'buy' && candlePrice < signal.price) {
        return {
          type: 'ok',
          order: 'Limit order would submit at incorrect price',
        }
      }

      if (signal.price === candlePrice) {
        if (bids && order.side === 'sell') {
          return {
            type: 'ok',
            order: 'Handled by other side',
          }
        }
        if (!bids && order.side === 'buy') {
          return {
            type: 'ok',
            order: 'Handled by other side',
          }
        }
      }

      updatedBaseBalanceInBaseCurrency = order.side === 'buy'
        ? updatedBaseBalanceInBaseCurrency.plus(order.quantity)
        : updatedBaseBalanceInBaseCurrency.minus(order.quantity)

      updatedQuoteBalance = order.side === 'buy'
        ? updatedQuoteBalance.minus(order.amount)
        : updatedQuoteBalance.plus(order.amount)

      return gateway.submitLimitOrder(order)
    }

    // Record the outcome of the order request, when known
    return submitOrder()
      .then((order) => {
        match(order.type)
          .exhaustive()
          .with('ok', () => {
            recordSuccess()
          })
          .with('error', () => {
            const value = new Date().toLocaleTimeString()
            document.getElementById('toast-date')!.innerHTML = value
            $('.toast').toast('show')
            return recordFailure()
          })
          .run()
      })
      // just in case
      .catch(() => {
        recordFailure()
      })
  })
}
