/**
 * Possible events initiated by the user to change their trading session's workflow state
 */
export type ProgramFlowEvent =
  // The user has indicated intent to evaluate their program one time and stop
  | 'step'
  // The user has indicated intent to simulate (backtest) their Python program continuously
  | 'simulate'
  // The user has indicated intent to execute (live-run) their Python program
  | 'execute'
  // The user has indicated intent to stop the current and future Python program evaluations
  | 'stop'

/**
 * This enum represents the workflow states for a user's trading session.
 */
export type ProgramState =
  // The user's trading session is loading (downloading or instantiating WebAssembly)
  | 'loading'
  // The user is not evaluating any code
  | 'idle'
  // The user is evaluating their program a single tiem
  | 'stepping'
  // The user is backtesting their program continuously
  | 'simulating'
  // The user is running their program continuously (trades will be submitted)
  | 'executing'
  // The user is tearing down their trading session
  | 'terminating'

/**
 * This enum represents the state of the pyodide engine. We use this information like a mutex
 * to ensure there is only one currently-evaluating pyodide instance at any given moment.
 */
export type PyodideEngineState =
  // The pyodide environment is loading (downloading or instantiating the WebAssembly)
  | 'loading'
  // The pyodide engin is not actively evaluating any code (even if the user's workflow
  // is in the `evaluating` state)
  | 'idle'
  // The pyodide engin is actively evaluating code
  | 'evaluating'
  // The pyodide environment is being torn down
  | 'terminating'

export const isProgramRunning = (state: ProgramState): boolean => {
  switch (state) {
    case 'loading':
      return true
    case 'idle':
      return false
    case 'stepping':
      return true
    case 'simulating':
      return true
    case 'executing':
      return true
    case 'terminating':
      return false
  }
}

export const isProgramStoppable = (state: ProgramState): boolean => {
  switch (state) {
    case 'loading':
      return false
    case 'idle':
      return false
    case 'stepping':
      return true
    case 'simulating':
      return true
    case 'executing':
      return true
    case 'terminating':
      return false
  }
}

export const programStateReducer = (
  _state: ProgramState,
  action: ProgramFlowEvent,
): ProgramState => {
  switch (action) {
    case 'step':
      return 'stepping'
    case 'simulate':
      return 'simulating'
    case 'execute':
      return 'executing'
    case 'stop':
      // TODO: we need to abort the currently-evaluating pyodide program
      return 'idle'
  }
}
