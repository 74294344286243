import { StylesConfig } from 'react-select'

type IsMulti = false

export const customStyles: StylesConfig<SelectItem, IsMulti> = {
  control: (base) => ({
    ...base,
    width: '130px',
    backgroundColor: 'black',
    text: 'white',
    color: 'white',
    borderRadius: '0',
    minHeight: '1px',
    height: '30px',
    borderColor: 'white',
    boxShadow: 'white',
    '&:hover': {
      border: '1px solid gray',
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    color: 'white',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: 'black',
    fontSize: 15,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#03a9f4' : 'black',
    '&:hover': {
      color: !state.isSelected ? 'black' : null,
      backgroundColor: !state.isSelected ? 'white' : null,
    },
  }),
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: '130px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
    marginLeft: '5px',
    outline: 'none',
  }),

  input: (provided) => ({
    ...provided,
    minHeight: '1px',
    color: 'white',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'white',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: '1px',
    height: '20px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '1px',
    height: '40px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: (provided) => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
    color: 'white',
  }),
}

type SelectItem = {
  label: string
  value: string
}

export const selectItem = (value: string): SelectItem => ({
  label: value,
  value: value,
})
