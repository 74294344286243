import * as t from 'io-ts'

const OrderType = t.keyof(
  {
    market: null,
    limit: null,
  },
  'OrderType',
)

const Order = t.array(
  t.type({
    type: OrderType,
    exposure: t.number,
  }),
)

export const TradeParameters = t.type({
  trades: t.array(Order),
})

export type TradeParameters = t.TypeOf<typeof TradeParameters>

export const PlotParameters = t.type({
  name: t.string,
  series: t.array(t.number),
})

const PyProxy = t.unknown

export const PyProxyTradeParameters = t.type({
  trades: PyProxy,
})
export type PyProxyTradeParameters = t.TypeOf<typeof PyProxyTradeParameters>

export const PyProxyPlotParameters = t.type({
  name: t.string,
  series: PyProxy,
})
export type PyProxyPlotParameters = t.TypeOf<typeof PyProxyPlotParameters>
