import { API } from 'aws-amplify'
import * as E from 'fp-ts/Either'
import Big from 'big.js'
import type { KrakenAuth } from '..'
import type { Balances } from '../..'
import { getCurrentUser } from '../../../components/Auth/AppUser'

import { KrakenProfileAccounts } from './codecs'

export const getCurrentBalances = async ({
  auth,
}: {
  auth: KrakenAuth
}): Promise<Balances> => {
  const apiName = 'Endpoint'
  const myInit = {
    queryStringParameters: {
      email: getCurrentUser().email,
    },
  }
  return API.get(apiName, 'backend/kraken/accounts', myInit)
    .then((response: unknown): Balances => {
      const maybeAccounts = KrakenProfileAccounts.decode(response)
      if (E.isLeft(maybeAccounts)) {
        // FIXME: throw an error with more data
        throw new Error('Received unexpected data from kraken accounts endpoint')
      }
      // console.log('Balances', maybeAccounts.right.result)

      const balances = new Map()

      Object.keys(maybeAccounts.right.result).forEach((key) => {
        balances.set(key, {
          total: maybeAccounts.right.result[key],
          available: maybeAccounts.right.result[key],
          held: new Big(0)
        })
      })

      return balances
    })
  // TODO: DISCUSS: catch the error somehow for logging, but would it just be rethrown?
  // .catch((err) => (console.error(err), err))
}
