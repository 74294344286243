import create from 'zustand'
import { OpenOrder } from './gateway'
import { ProgramFlowEvent, ProgramState, programStateReducer } from './pyodide'
import { TradeParameters as BacktestTrades } from './pyodide/codecs'
import type { Exchange, ReadableTimeframe, KrakenReadableTimeframe, standardOut } from './types/index'

// DESIRE: type this nominally
const DEFAULT_TRADEPAIR = 'BTC-USD' as const
const DEFAULT_READABLE_TIMEFRAME = '1H' as const
const DEFAULT_EXCHANGE = 'coinbase' as const

type Values = {
  values: number[]
  color: string
}
// domain is plot name, codomain is values on the plot (only supports line plots for now)
type Plots = Map<string, Values>

const zeroPlots = (): Plots => new Map()

const zeroBacktestTrades = (): BacktestTrades => {
  return {
    trades: [],
  }
}

const zeroOrders = (): OpenOrder[] => []

// FIXME: type `set`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useStore = create((set: any) => ({
  showMarkers: true,
  setMarkers: (value: boolean): void =>
    set(() => ({
      showMarkers: value,
    })),

  isActiveAuth: true,
  setIsActiveAuth: (value: boolean): void =>
    set(() => ({
      isActiveAuth: value,
    })),

  isAuth: true,
  setIsAuth: (value: boolean): void =>
    set(() => ({
      isAuth: value,
    })),

  isActivePlan: true,
  setIsActivePlan: (value: boolean): void =>
    set(() => ({
      isActivePlan: value,
    })),

  initialPyodideProgram: '# loading...',
  setInitialPyodideProgram: (value: string): void =>
    set(() => ({
      initialPyodideProgram: value,
    })),

  currentTradepair: DEFAULT_TRADEPAIR,
  setCurrentTradepair: (value: string): void =>
    set(() => ({
      currentTradepair: value,
    })),

  currentExchange: DEFAULT_EXCHANGE as Exchange,
  setCurrentExchange: (value: string): void =>
    set(() => ({
      currentExchange: value,
    })),

  currentTimeframe: DEFAULT_READABLE_TIMEFRAME as ReadableTimeframe | KrakenReadableTimeframe,
  setCurrentTimeframe: (value: ReadableTimeframe | KrakenReadableTimeframe): void =>
    set(() => ({
      currentTimeframe: value,
    })),

  programState: 'loading' as ProgramState,
  dispatchProgramFlowEvent: (value: ProgramFlowEvent): void =>
    set(() => ({
      programState: programStateReducer('loading', value),
    })),

  isHidden: true,
  setHidden: (value: boolean): void =>
    set(() => ({
      isHidden: value,
    })),

  orders: zeroOrders(),
  addToOrders: (fn: (prevOrders: OpenOrder[]) => OpenOrder[]): void =>
    set((state: any) => ({
      orders: fn(state.orders),
    })),

  output: [{ type: 'status', value: 'Initializing...' }],
  addToOutput: (output: standardOut): void =>
    set((state: any) => ({
      // DISCUSS: this is to trim the output so it doesn't get too long.
      // I have a feeling Eric has an issue with this, but...
      output: state.output.length > 15
        ? [...state.output.slice(1), output]
        : [...state.output, output],
    })),
  clearOutput: (): void =>
    set(() => ({
      output: [],
    })),

  plots: zeroPlots(),
  addPlot: (name: string, values: number[], color?: string): void =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set((state: any) => ({
      plots: new Map(state.plots).set(name, { values: values, color: color }),
    })),

  /**
   * This is to trigger every time we evaluate the python code.
   * We don't want to do it on each time a candle updates. We then
   * use this as a dependency in the Chart component to update at the
   * same time so we don't get a flicker that is caused by updating twice:
   * one with eraseing and one with adding.
   */
  // We may want to change this from a boolean but is sufficient
  tick: false,
  signalEvaluation: (): void =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set((state: any) => ({
      tick: !(state.tick as boolean),
    })),

  /**
   * Erase all user-program defined plots from the Chart component.
   * This should be called before evaluating a user's program to avoid inaccuate paints.
   */
  eraseProgrammaticPlots: (): void =>
    set(() => ({
      plots: new Map<string, Values>(),
      backtestTrades: zeroBacktestTrades(),
    })),

  backtestTrades: zeroBacktestTrades(),
  setBacktestTrades: (backtestTrades: BacktestTrades): void =>
    set(() => ({ backtestTrades })),
}))
