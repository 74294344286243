import * as monaco from 'monaco-editor'
import { EmacsExtension } from 'monaco-emacs'
import React, { useEffect, useRef } from 'react'

import { useStore } from '../store'

import { getCurrentUser } from './Auth/AppUser'
import { programCodeService } from './ProgramCode'

const VimMode = require('monaco-vim')

interface PropValues {
  isDarkMode: boolean
  isMinimapEnabled: boolean
  showLineNumbers: boolean
  isVimMode: boolean
  isEmacsMode: boolean
}

let vim: any
let emacs: any

// TODO set the theme to a toggle
export const Editor: React.FC<PropValues> = (props) => {
  const divElement = useRef<HTMLDivElement>(null)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>()
  const initialPyodideProgram = useStore((state) => state.initialPyodideProgram)
  const setInitialPyodideProgram = useStore((state) => state.setInitialPyodideProgram)

  const setVimMode = () => {
    if (editorRef.current) {
      const statusNode = document.getElementById('status')
      vim = VimMode.initVimMode(editorRef.current, statusNode)
    }
  }

  const clearVimMode = () => {
    if (editorRef.current && vim !== undefined) {
      vim.dispose()
    }
  }

  const setEmacsMode = () => {
    if (editorRef.current) {
      const statusNode = document.getElementById('statusbar')
      emacs = new EmacsExtension(editorRef.current)
      emacs.onDidMarkChange((ev: boolean) => {
        if (statusNode !== null) {
          statusNode.textContent = ev
            ? 'Mark Set!'
            : 'Mark Unset'
        }
      })
      emacs.onDidChangeKey((str: string) => {
        if (statusNode !== null) { statusNode.textContent = str }
      })
      emacs.start()
    }
  }

  const clearEmacsMode = () => {
    const statusNode = document.getElementById('statusbar')
    if (editorRef.current && emacs !== undefined) {
      if (statusNode !== null) { statusNode.textContent = '' }
      emacs.dispose()
    }
  }

  useEffect(() => {
    // Check if window is defined (so if in the browser or in node.js).
    // Required for gatsby build to succeed
    const isBrowser = typeof global.localStorage !== 'undefined'
    const userProgram = isBrowser
      ? global.localStorage.getItem(`${getCurrentUser().email}-program`)
      : null

    if (initialPyodideProgram === '# loading...') {
      const initialProgram = process.env.GATSBY_STRATOS_INITIAL_PYODIDE_PROGRAM
      if (initialProgram === undefined) {
        throw new Error('Initial program is undefined')
      }
      // Load the initial pyodide program
      fetch(initialProgram)
        .then(async (response) => response.text())
        .then((pyodideProgram) => {
          if (userProgram !== null) {
            setInitialPyodideProgram(userProgram)
            programCodeService.changeCode(userProgram)
            editorRef.current?.setValue(userProgram)
          } else {
            setInitialPyodideProgram(pyodideProgram)
            programCodeService.changeCode(pyodideProgram)
            editorRef.current?.setValue(pyodideProgram)
          }
        })
        .catch(() =>
          console.error('TODO: handle this initial-pyodide-program load error'),
        )
    }

    return function cleanup() {
      // TODO: create an abort controller for the initial pyodide fetch
      void 0
    }
  }, [])

  useEffect(() => {
    if (divElement.current) {
      editorRef.current = monaco.editor.create(divElement.current, {
        language: 'python',
        value: initialPyodideProgram,
        theme: 'vs-dark',
        wordWrap: 'on',
        automaticLayout: true,
        formatOnPaste: true,
        formatOnType: true,
        showDeprecated: true,
        showUnused: true,
        glyphMargin: true,
      })
      editorRef.current.onDidChangeModelContent(() => {
        const newProgram = editorRef.current!.getValue()
        programCodeService.changeCode(newProgram)
        setInitialPyodideProgram(newProgram)
        global.localStorage.setItem(`${getCurrentUser().email}-program`, newProgram)
      })
    }

    return function cleanup() {
      if (editorRef.current) {
        editorRef.current.dispose()
      }
    }
  }, [])

  useEffect(() => {
    if (editorRef.current !== undefined) {
      editorRef.current.updateOptions({
        theme: props.isDarkMode ? 'vs-dark' : 'vs',
        minimap: {
          enabled: props.isMinimapEnabled,
        },
        lineNumbers: props.showLineNumbers ? 'on' : 'off',
      })
    }
  }, [
    props.isDarkMode,
    props.isMinimapEnabled,
    props.showLineNumbers,
  ])

  useEffect(() => {
    if (props.isVimMode) {
      setVimMode()
    } else {
      clearVimMode()
    }
  }, [props.isVimMode])

  useEffect(() => {
    if (props.isEmacsMode) {
      setEmacsMode()
    } else {
      clearEmacsMode()
    }
  }, [props.isEmacsMode])

  return (
    <div>
      <div id='monaco' className='monaco' ref={divElement}></div>
      <div id='status' className='vim'></div>
      <div id='statusbar' className='emacs'></div>
    </div>
  )
}
