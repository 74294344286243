import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import {
  BooleanFromString,
  DateFromISOString,
  nonEmptyArray,
  NonEmptyString,
  NumberFromString,
  UUID,
} from 'io-ts-types'
import { toggle } from 'shades'

import type { Candle } from '../../../types/index'
import { BigFromString } from '../../BigCodec'
import { CoinbaseOrderSide } from '../CoinbaseOrderSide'
import { CoinbaseOrderStatus } from '../CoinbaseOrderStatus'
import { CoinbaseOrderType } from '../CoinbaseOrderType'
import { CoinbasePrice } from '../CoinbasePrice'
import { CoinbaseProductID } from '../CoinbaseProductID'

export const CoinbaseCandle = new t.Type<Candle, number[], unknown>(
  'CoinbaseCandle',
  (_u): _u is Candle => {
    // TODO: implement me, check for Candle
    return false
  },
  (u, c) =>
    pipe(
      nonEmptyArray(t.number).decode(u),
      E.chain((array) =>
        array.length !== 6
          ? t.failure(u, c)
          : t.success({
            open: array[3],
            high: array[2],
            low: array[1],
            close: array[4],
            volume: array[5],
            time: new Date(array[0] * 1000),
          }),
      ),
    ),
  (candle) => [
    candle.time.getTime() / 1000,
    candle.low,
    candle.high,
    candle.open,
    candle.close,
    candle.volume,
  ],
)

/**
 * From https://docs.cloud.coinbase.com/exchange/reference/exchangerestapi_getaccounts
 */
export const CoinbaseProfileAccounts = t.array(
  t.type({
    id: UUID,
    currency: NonEmptyString,
    balance: BigFromString,
    hold: BigFromString,
    available: BigFromString,
    profile_id: UUID,
    trading_enabled: t.boolean,
  }),
  'CoinbaseProfileAccounts',
)

/**
 * From https://docs.cloud.coinbase.com/exchange/reference/exchangerestapi_getorders
 *
 * @example
 * {
 *   "id": "35baed43-f13b-4a90-bbff-1c0c5f41e29d",
 *   "price": "10.00000000",  // not required
 *   "size": "1.00000000",  // not required
 *   "product_id": "BTC-USD",
 *   "profile_id": "74f3aed8-de6f-49c7-b74d-3c62e988722b", // not required
 *   "side": "buy",
 *   "type": "limit",
 *   "time_in_force": "GTC", // not required
 *   "post_only": false,  // if true, forces order to be maker only
 *   "created_at": "2022-02-05t21:10:46.913032z",
 *   "fill_fees": "0.0000000000000000",
 *   "filled_size": "0.00000000",
 *   "executed_value": "0.0000000000000000",  // not required
 *   "status": "open",
 *   "settled": false
 * }
 */
export const CoinbaseOrder = t.intersection(
  [
    t.type(
      {
        id: UUID,
        product_id: CoinbaseProductID,
        side: CoinbaseOrderSide,
        type: CoinbaseOrderType,
        // "post_only": t.boolean,
        created_at: DateFromISOString,
        fill_fees: BigFromString,
        filled_size: BigFromString,
        status: CoinbaseOrderStatus,
        // "settled": t.boolean,
        // NOTE: `size` is not marked as a required value
        // but we depend on it, so here we decode expecting it to exist.
        // It is not clear to me when `size` will not be present.
        size: BigFromString,
        // NOTE: `price` is not marked as a required value
        // but we depend on it, so here we decode expecting it to exist.
        // It is not clear to me when `price` will not be present.
        price: NonEmptyString.pipe(CoinbasePrice),
      },
      'CoinbaseOrderRequiredProperties',
    ),
    t.partial(
      {
        // "profile_id": UUID,
        // "time_in_force": "GTC",
        executed_value: BigFromString,
      },
      'CoinbaseOrderOptionalProperties',
    ),
  ],
  'CoinbaseOrder',
)

/**
 * From https://docs.cloud.coinbase.com/exchange/reference/exchangerestapi_getorders
 *
 * @example
 * {
 *   "data": [
 *     {
 *       "id": "35baed43-f13b-4a90-bbff-1c0c5f41e29d",
 *       "price": "10.00000000",
 *       "size": "1.00000000",
 *       "product_id": "BTC-USD",
 *       "profile_id": "74f3aed8-de6f-49c7-b74d-3c62e988722b",
 *       "side": "buy",
 *       "type": "limit",
 *       "time_in_force": "GTC",
 *       "post_only": false,
 *       "created_at": "2022-02-05T21:10:46.913032Z",
 *       "fill_fees": "0.0000000000000000",
 *       "filled_size": "0.00000000",
 *       "executed_value": "0.0000000000000000",
 *       "status": "open",
 *       "settled": false
 *     }
 *   ],
 *   "pagination": {
 *     "after": "2022-02-05T21:10:46.913032Z",
 *     "before": "2022-02-05T21:10:46.913032Z"
 *   }
 * }
 */
export const CoinbaseOrders = t.type(
  {
    data: t.array(CoinbaseOrder),
    // Not concerned with pagination at the moment because the default
    // page size is 1000 and we should be querying only a single tradepair
    // at a time for this implementation.
    //
    // pagination: t.type(
    //   {
    //     after: DateFromISOString,
    //     before: DateFromISOString,
    //   },
    //   'CoinbasePaginationData'
    // )
  },
  'CoinbaseOrders',
)
export type CoinbaseOrders = t.TypeOf<typeof CoinbaseOrders>

// TODO: need to add pagination for products

/**
 * From https://docs.cloud.coinbase.com/exchange/reference/exchangerestapi_getproducts
 *
 * @example
 *
 * [
 *  {
 *   "id": "BTC-USD",
 *   "base_currency": "BTC",
 *   "quote_currency": "USD",
 *   "base_min_size": "0.00100000",
 *   "base_max_size": "280.00000000",
 *   "quote_increment": "0.01000000",
 *   "base_increment": "0.00000001",
 *   "display_name": "BTC/USD",
 *   "min_market_funds": "10",
 *   "max_market_funds": "1000000",
 *   "margin_enabled": false,
 *   "post_only": false,
 *   "limit_only": false,
 *   "cancel_only": false,
 *   "status": "online",
 *   "status_message": "",
 *   "auction_mode": true
 *  }
 * ]
 */

// DISCUSS: graying out the tradepairs that are cancel_only, post_only, limit_only, or trading_disabled
// instead of filtering them from the list entirely
export const CoinbaseTradepair = t.type({
  id: t.string,
  base_currency: t.string,
  quote_currency: t.string,
  quote_increment: NumberFromString,
  base_increment: NumberFromString,
  display_name: t.string,
  min_market_funds: NumberFromString,
  margin_enabled: t.boolean,
  fx_stablecoin: t.boolean,
  max_slippage_percentage: NumberFromString,
  post_only: t.boolean,
  limit_only: t.boolean,
  cancel_only: t.boolean,
  trading_disabled: t.boolean,
  status: t.string,
  status_message: t.string,
  auction_mode: t.boolean,
}, 'CoinbaseTradepair')

export const CoinbaseTradepairs = t.array(CoinbaseTradepair, 'CoinbaseTradepairs')
export type CoinbaseTradepairs = t.TypeOf<typeof CoinbaseTradepairs>
