import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { Helmet, HelmetTags } from 'react-helmet'
import { BehaviorSubject, Observable } from 'rxjs'
import * as $ from 'rxjs'

import type { Balances, Gateway, KrakenGateway, LimitOrderBook } from '../gateway'
import { inspectBalances, inspectLimitOrderBook } from '../gateway'
import { CoinbaseTradepairs } from '../gateway/coinbase/rest/codecs'
import {
  dispatchOrders,
  dispatchKrakenOrders,
  evaluatePython,
  EventLogEvent,
  isProgramRunning,
  isProgramStoppable,
  isUserSignalEventGroup,
  ProgramFlowEvent,
  PyodideClient,
  PyodideEngineState,
  unregisterAndUnloadJsModule,
  UserSignalEventGroup,
} from '../pyodide'
import { plot, trade } from '../pyodide/api'
import { TradeParameters } from '../pyodide/codecs'
import { useStore } from '../store'
import type { Candle, CandleEvent, Exchange, standardOut } from '../types/index'
import { DeleteOrders } from './DeleteOrders'
import { Editor } from './MonacoEditor'
import { programCodeService } from './ProgramCode'
import { KrakenTradepairs } from '../gateway/kraken/rest/codecs'

/* eslint-disable security/detect-object-injection */

function testAndSet<T>(
  subject: BehaviorSubject<T>,
  { test, set }: { test: T; set: T },
) {
  if (subject.getValue() === test) {
    subject.next(set)
    return true
  }
  return false
}

/**
 * Reduce a stream of historical and realtime candle events (like the stream from an
 * exchange gateway) into an array of historical candles.
 */
const candleHistoryReducer = (
  candles: Candle[],
  realtimeCandle: CandleEvent,
): Candle[] => {
  if (realtimeCandle.event === 'new series') {
    candles = []
  } else {
    pipe(
      NEA.fromArray(candles),
      O.matchW(
        () => candles.push(realtimeCandle.candle),
        (candles) => {
          const previous = NEA.last(candles)
          if (previous.time < realtimeCandle.candle.time) {
            candles.push(realtimeCandle.candle)
          } else {
            candles[candles.length - 1] = realtimeCandle.candle
          }
        },
      ),
    )
  }

  return candles
}

declare global {
  // eslint-disable-next-line no-var
  var pandasTaWheel: string | undefined
}

// This is required because the js module in pyodide only imports from the globalThis scope
// https://pyodide.org/en/stable/usage/type-conversions.html#type-translations-using-js-obj-from-py
globalThis.pandasTaWheel = process.env.GATSBY_STRATOS_PANDAS_TA_WHEEL

const initPyodide = async function (): Promise<PyodideClient> {
  if (globalThis.pandasTaWheel === undefined) {
    // TODO(ST-171): send error to sqs notifying use of undefined wheel
    throw new Error('Pandas-ta wheel is undefined')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pyodideClient = await (window as any).loadPyodide({
    indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.21.3/full/',
  })
  await pyodideClient.loadPackage(['micropip'])

  await pyodideClient.runPythonAsync(`
import micropip
import js
await micropip.install(js.pandasTaWheel)
  `)
  // We import `sys` so we can delete imported JavaScript modules in between program evaluations.
  // This allows us to modify how Stratos-provided functions behave in the different user workflows.
  // https://pyodide.org/en/stable/usage/api/js-api.html#pyodide.unregisterJsModule
  await pyodideClient.runPythonAsync('import sys')
  return pyodideClient
}

// REFACTOR: move this to a different file in the `pyodide` directory
const runUserProgram = ({
  addPlot,
  eraseProgrammaticPlots,
  setBacktestTrades,
  pyodideClient,
  addToOutput,
  signalEvaluation,
}: {
  addPlot: (name: string, values: number[]) => void
  eraseProgrammaticPlots: () => void
  setBacktestTrades: (backtestTrades: TradeParameters) => void
  pyodideClient: PyodideClient
  addToOutput: (output: standardOut) => void
  signalEvaluation: () => void
}) =>
  async ({
    program,
    programFlowEvent,
    candles,
    eventlog,
  }: {
    program: string
    programFlowEvent: ProgramFlowEvent
    candles: Candle[]
    eventlog: EventLogEvent[]
  }): Promise<void> => {
    eraseProgrammaticPlots()
    pyodideClient.registerJsModule('stratos', {
      plot: plot({ addPlot, pyodideClient }),
      trade: trade({ setBacktestTrades, pyodideClient, programFlowEvent, eventlog }),
    })
    try {
      await evaluatePython({
        pyodideClient,
        program: program,
        candles: candles,
        addToOutput,
      })
      // This is to update the chart component
      // once we have finished evaluating the python program
      signalEvaluation()
    } finally {
      await unregisterAndUnloadJsModule(pyodideClient, 'stratos')
    }
  }

/**
 * Here we use a BehaviorSubject because the `head` HTML tags that import the
 * pyodide loader will resolve before our components (in the `body`) begin mounting
 * (subscribe).
 */
const injectScript$ = new BehaviorSubject<HelmetTags | undefined>(undefined)
const stepRequest$ = new $.Subject<{ type: 'step'; program: string }>()
const stopRequest$ = new $.Subject<{ type: 'stop'; program: string }>()
const executeRequest$ = new $.Subject<{ type: 'execute'; program: string }>()
const simulateRequest$ = new $.Subject<{ type: 'simulate'; program: string }>()
const pyodideEngineState$ = new BehaviorSubject<PyodideEngineState>('loading')

export function PyodideEditor(props: {
  candle$: Observable<CandleEvent>
  balance$: Observable<Balances>
  orderBook$: Observable<LimitOrderBook>
  gateway: Observable<Gateway | KrakenGateway>
  stepComplete$: $.Subject<{ type: 'stop'; program: string }>
  selectedTradepair$: Observable<string>
  selectedExchange$: Observable<Exchange>
  products$: Observable<KrakenTradepairs | CoinbaseTradepairs>
}): JSX.Element {
  const addPlot = useStore((state) => state.addPlot)
  const setBacktestTrades = useStore((state) => state.setBacktestTrades)
  const eraseProgrammaticPlots = useStore((state) => state.eraseProgrammaticPlots)
  const signalEvaluation = useStore((state) => state.signalEvaluation)
  const addToOutput = useStore((state) => state.addToOutput)
  const output = useStore((state) => state.output)

  const programState = useStore((state) => state.programState)
  const dispatchProgramFlowEvent = useStore((state) => state.dispatchProgramFlowEvent)

  const isActivePlan = useStore((state) => state.isActivePlan)
  const isAuth = useStore((state) => state.isAuth)
  const isActiveAuth = useStore((state) => state.isActiveAuth)
  const showMarkers = useStore((state) => state.showMarkers)
  const setMarkers = useStore((state) => state.setMarkers)

  const [isDarkMode, setMode] = React.useState(true)
  const [isMinimapEnabled, enableMinimap] = React.useState(true)
  const [showLineNumbers, setLineNumbers] = React.useState(true)
  const [isVimMode, setVimMode] = React.useState(false)
  const [isEmacsMode, setEmacsMode] = React.useState(false)

  React.useMemo(() => {
    /**
     * We define this stream inside a useMemo so that the expensive calculations are
     * only once. Also the useEffect doesn't reload pyodide???
     * There is a caveat the useMemo does not work with useState() well so
     * we need to really push into streams and zustand
     */

    // DISCUSS: let's figure out how to make this parameterizable based on
    // timeseries-record context -- I think that will greatly influence how we
    // implement "evaluate the program on every tick"
    const buttonEvent$: Observable<{ type: ProgramFlowEvent; program: string }> = pipe(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      $.merge(stepRequest$, stopRequest$, simulateRequest$, executeRequest$).pipe(
        // DESIRE: type `program` nominally
        $.withLatestFrom(programCodeService.getCode()),
      ),
      // DEBUG:
      $.tap(([button, _program]) => console.info(`${button.type} has been clicked`)),
      $.map(([button, program]) => ({ type: button.type, program: program })),
      $.tap((button) => dispatchProgramFlowEvent(button.type)),
    )

    const pyodideClient$ = pipe(
      injectScript$,
      /**
       * Here we are filtering the injectScript to only pass through when
       * pyodide is in a loading state, otherwise we will get an error
       * that pyodide has already loaded.
       */
      $.withLatestFrom(pyodideEngineState$),
      $.filter(([_script, state]) => {
        return state === 'loading'
      }),
      $.map(([script, _state]) => script),
      $.filter(
        (value: HelmetTags | undefined): value is HelmetTags => value !== undefined,
      ),
      $.map((helmet) => helmet.scriptTags ?? []),
      $.filter((scriptTags): scriptTags is NEA.NonEmptyArray<HTMLScriptElement> =>
        scriptTags.length > 0,
      ),
      $.mergeMap(async (scriptTags) => {
        const pyodideScriptTag = NEA.head(scriptTags)
        return new Promise<PyodideClient>((resolve) => {
          pyodideScriptTag.addEventListener(
            'load',
            async () =>
              initPyodide().then((pyodideClient) => {
                // Force the user workflow into the `idle` state
                addToOutput({ type: 'status', value: 'Ready!' })
                dispatchProgramFlowEvent('stop')
                pyodideEngineState$.next('idle')
                resolve(pyodideClient)
              }),
          )
        })
      }),
    )

    const candle$ = pipe(
      props.candle$,
      $.scan(candleHistoryReducer, []),
      // DISCUSS: using take-one instead with step
      // $.throttleTime(500),
    )
    /**
     * Define the reactivity of the trading terminal web page
     */

    const stepStateMachineEvent$ = pipe(
      props.stepComplete$,
      $.tap(() => dispatchProgramFlowEvent('stop')),
    )

    const userEvent$: Observable<{ type: ProgramFlowEvent; program: string }> = $.merge(
      buttonEvent$,
      stepStateMachineEvent$,
    )

    const repl$ = pipe(
      $.combineLatest({
        pyodideClient: pyodideClient$,
        candles: candle$,
        event: userEvent$,
      }),
      $.filter(({ event }) => {
        // permit only a single evaluation at a time
        return event.type !== 'stop'
          // acquire the pyodide engine mutex
          && testAndSet<PyodideEngineState>(pyodideEngineState$, {
            test: 'idle',
            set: 'evaluating',
          })
      }),
      // The `mergeScan`, `map`, and `filter` is a kludgy pattern to emulate `loop` from
      // most, where `scan` feeds-back one value to itself and emits a different value
      // downstream.
      $.mergeScan(
        async (seed, { pyodideClient, candles, event }) => {
          const eventlog: EventLogEvent[] = seed?.eventlog ?? []
          // utilize the pyodide engine
          await runUserProgram({
            addPlot,
            eraseProgrammaticPlots,
            setBacktestTrades,
            pyodideClient,
            addToOutput,
            signalEvaluation,
          })({
            program: event.program,
            programFlowEvent: event.type,
            candles,
            eventlog,
          })
          return {
            type: event.type,
            program: event.program,
            eventlog: eventlog,
          }
        },
        undefined as {
          type: ProgramFlowEvent
          program: string
          eventlog: EventLogEvent[]
        } | undefined,
      ),
      $.filter((
        event,
      ): event is {
        type: ProgramFlowEvent
        program: string
        eventlog: EventLogEvent[]
      } => event !== undefined),
      // Combine all the most-recent, un-handled UserSignalEventGroup
      // events in the eventlog. If the user called our `trade` API
      // multiple times, we'll have multiple signal groups, but it's
      // easiest to handle them if they are combined into one.

      // From Amchelle: I don't think we want this anymore...
      /* $.map((event) => {
        const eventlog = event.eventlog
        // eslint-disable-next-line for-direction
        for (let i = eventlog.length - 1; i <= 1; --i) {
          // combine i into i-1, if both are UserSignalEventGroups
          const last = eventlog[i]
          const previous = eventlog[i - 1]
          if (!(isUserSignalEventGroup(last) && isUserSignalEventGroup(previous))) {
            break
          }
          previous.signals.push(...last.signals)
          eventlog.pop()
        }
        return event
      }), */
      // Dispatch any orders from the `evaluate` workflow
      // This `mergeMap` is used as an asynchronous `tap`
      $.withLatestFrom(
        pipe(
          props.balance$,
          $.tap((balances) => {
            // DEBUG:
            // console.log('Balances message:', inspectBalances(balances))
          }),
        ),
        pipe(
          props.candle$,
        ),
        pipe(
          props.orderBook$,
          $.tap((orderBook) => {
            // DEBUG:
            // console.log('Order book message:', inspectLimitOrderBook(orderBook))
          }),
        ),
        pipe(
          props.selectedTradepair$,
          $.tap((tradepair) => {
            // console.log('selected tradepair', tradepair)
          }),
        ),
        pipe(
          props.selectedExchange$,
          $.tap((exchange) => {
            // console.log('selected exchange', exchange)
          }),
        ),
        props.gateway,
        pipe(
          props.products$,
        )
      ),
      // DISCUSS: REFACTOR: is switchScan the right combinator here?
      // (probably not)
      $.switchScan<
        [
          {
            type: ProgramFlowEvent
            program: string
            eventlog: EventLogEvent[]
          },
          Balances,
          CandleEvent,
          LimitOrderBook,
          string,
          Exchange,
          Gateway | KrakenGateway,
          KrakenTradepairs | CoinbaseTradepairs
        ],
        {
          latestSignal: UserSignalEventGroup | undefined
          event: {
            type: ProgramFlowEvent
            program: string
            eventlog: EventLogEvent[]
          } | undefined
        },
        Promise<{
          latestSignal: UserSignalEventGroup | undefined
          event: {
            type: ProgramFlowEvent
            program: string
            eventlog: EventLogEvent[]
          } | undefined
        }>
      >(
        async (state, [event, balances, candles, orderBook, tradepair, exchange, gateway, products]) => {
          if (event.type !== 'execute') {
            return { latestSignal: undefined, event: event }
          }
          const eventlog = event.eventlog
          const lastEvent = eventlog[eventlog.length - 1]
          // FIXME: if the signals are equivalent, we shouldn't act, right? or we'll
          // mess up the `slice` in the dispatch-orders file
          const latestSignal =
            lastEvent !== undefined && isUserSignalEventGroup(lastEvent)
              ? lastEvent
              : state.latestSignal

          switch (exchange) {
            case 'coinbase':
              dispatchOrders({
                tradepair: tradepair,
                balances: balances,
                candles: candles,
                orderBook: orderBook,
                eventlog: event.eventlog,
                latestSignal: latestSignal,
                gateway: gateway as Gateway,
                products: products as CoinbaseTradepairs,
              })
              break;
            case 'kraken':
              dispatchKrakenOrders({
                tradepair: tradepair,
                balances: balances,
                candles: candles,
                orderBook: orderBook,
                eventlog: event.eventlog,
                latestSignal: latestSignal,
                gateway: gateway as KrakenGateway,
                //TODO: dispatch Kraken orders
                products: products as KrakenTradepairs,
              })
              break;
          }

          return {
            latestSignal: latestSignal,
            event: event,
          }
        },
        {
          latestSignal: undefined,
          event: undefined,
        },
      ),
      $.map(({ event }) => event),
      $.filter((
        event,
      ): event is {
        type: ProgramFlowEvent
        program: string
        eventlog: EventLogEvent[]
      } => event !== undefined),
      $.tap((event) => {
        // TODO: trim the size of the eventlog
      }),
      // Note: we must update the event type before releasing the pyodide engine mutex
      // to avoid stuttering/subsequent runs
      $.tap((event) => {
        // When the user has selected `step` mode, evaluate the program once and stop
        if (event.type === 'step') {
          props.stepComplete$.next({ type: 'stop', program: event.program })
        }
        if (event.type === 'simulate') {
          /**
           * If someone changes pages we need to stop these from executing
           * The websocket will keep going even on page changes.
           * This is kinda hacky but the best way for now.
           */
          if (window.location.pathname !== '/home') {
            props.stepComplete$.next({ type: 'stop', program: event.program })
          }
        }
        if (event.type === 'execute') {
          /**
           * If someone changes pages we need to stop these from executing
           * The websocket will keep going even on page changes.
           * This is kinda hacky but the best way for now.
           */
          if (window.location.pathname !== '/home') {
            props.stepComplete$.next({ type: 'stop', program: event.program })
          }
        }
      }),
      // release the pyodide engine mutex
      $.tap(() => {
        pyodideEngineState$.next('idle')
      }),
    )

    const replSubscription = repl$.subscribe({
      next: (pythonReturnValue) => {
        // console.log('Evaluating the python code yielded', pythonReturnValue)
      },
      error: (err) => {
        console.error('REPL stream error', err)
      },
      complete: () => {
        console.info('REPL stream completed -- but why?')
      },
    })

    return function cleanup() {
      replSubscription.unsubscribe()
      injectScript$.complete()
    }
  }, [])

  return (
    <div>
      <Helmet
        script={[
          {
            src: 'https://cdn.jsdelivr.net/pyodide/v0.21.3/full/pyodide.js',
          },
        ]}
        onChangeClientState={(_newState, addedTags): void => {
          injectScript$.next(addedTags)
        }}
      >
      </Helmet>
      <Editor
        isDarkMode={isDarkMode}
        isMinimapEnabled={isMinimapEnabled}
        showLineNumbers={showLineNumbers}
        isVimMode={isVimMode}
        isEmacsMode={isEmacsMode}
      />
      <div className='editorButtons'>
        <div style={{ display: 'inline' }} className='dropdown'>
          <button
            className='btn btn-black btn-sm dropdown'
            id='toggle-button'
          >
            <i className='bi bi-gear-fill mr-1 text-white'></i>
          </button>
          <div
            className='dropdown-menu dropdown-menu-right text-white'
            aria-labelledby='dropdownMenuButton'
            style={{ backgroundColor: 'black' }}
          >
            <h6 className='dropdown-header text-white'>Color Theme</h6>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='radio'
                name='exampleRadios1'
                id='radios1'
                checked={!isDarkMode}
                onClick={() => setMode(false)}
              />
              <label className='ml-2'>
                Light
              </label>
            </div>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='radio'
                name='exampleRadios2'
                id='radios2'
                checked={isDarkMode}
                onClick={() => setMode(true)}
              />
              <label className='ml-2'>
                Dark
              </label>
            </div>
            <div className='dropdown-divider'></div>
            <h6 className='dropdown-header text-white'>Display</h6>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='checkbox'
                name='exampleCheckboxes3'
                id='checkboxes3'
                checked={isMinimapEnabled}
                onClick={() => enableMinimap(!isMinimapEnabled)}
              />
              <label className='ml-2'>
                Minimap
              </label>
            </div>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='checkbox'
                name='exampleCheckboxes4'
                id='checkboxes4'
                checked={showLineNumbers}
                onClick={() => setLineNumbers(!showLineNumbers)}
              />
              <label className='ml-2'>
                Line Numbers
              </label>
            </div>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='checkbox'
                name='exampleCheckboxes5'
                id='checkboxes5'
                checked={showMarkers}
                onClick={() => setMarkers(!showMarkers)}
              />
              <label className='ml-2'>
                Trade Markers
              </label>
            </div>
            <div className='dropdown-divider'></div>
            <h6 className='dropdown-header text-white'>Key Bindings</h6>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='radio'
                name='exampleRadios5'
                id='radios5'
                checked={isVimMode}
                onClick={() => {
                  setVimMode(true)
                  setEmacsMode(false)
                }}
              />
              <label className='ml-2'>
                Vim
              </label>
            </div>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='radio'
                name='exampleRadios6'
                id='radios6'
                checked={isEmacsMode}
                onClick={() => {
                  setEmacsMode(true)
                  setVimMode(false)
                }}
              />
              <label className='ml-2'>
                Emacs
              </label>
            </div>
            <div className='form-check'>
              <input
                className='ml-1 cursor'
                type='radio'
                name='exampleRadios7'
                id='radios7'
                checked={!isEmacsMode && !isVimMode}
                onClick={() => {
                  setEmacsMode(false)
                  setVimMode(false)
                }}
              />
              <label className='ml-2'>
                Normal
              </label>
            </div>
          </div>
        </div>
        <button
          className='btn btn-primary btn-sm'
          id='step-button'
          disabled={isProgramRunning(programState)}
          onClick={() => stepRequest$.next({ type: 'step', program: '' })}
        >
          <i className='bi bi-layers-half mr-1'></i>
          Step
        </button>
        <button
          className='btn btn-buttonBlue btn-sm'
          id='simulate-button'
          disabled={isProgramRunning(programState)}
          onClick={() => simulateRequest$.next({ type: 'simulate', program: '' })}
        >
          <i className='bi bi-eye mr-1'></i>
          Simulate
        </button>
        <button
          className='btn btn-success text-white btn-sm'
          id='execute-button'
          disabled={true}
          onClick={() => executeRequest$.next({ type: 'execute', program: '' })}
        >
          <i className='bi bi-play-btn mr-1'></i>
          Execute
        </button>
        <button
          className='btn btn-danger btn-sm'
          id='stop-button'
          disabled={!isProgramStoppable(programState)}
          onClick={() => {
            stopRequest$.next({ type: 'stop', program: '' })
          }}
        >
          <i className='bi bi-stop-btn mr-1'></i>
          Stop
        </button>
        <DeleteOrders
          orderBook$={props.orderBook$}
          selectedTradepair$={props.selectedTradepair$}
          selectedExchange$={props.selectedExchange$}
          gateway={props.gateway}
          stopRequest$={stopRequest$}
        />
      </div>
    </div>
  )
}
