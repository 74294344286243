import {
  BarData,
  HistogramData,
  ISeriesApi,
  MouseEventParams,
  UTCTimestamp,
} from 'lightweight-charts'

import type { Candle } from '../types/index'

export * from 'lightweight-charts'

// TYPE: as IndicatorName
export const CandleSeriesName = 'Candles' as const
export const VolumeSeriesName = 'Volume' as const

export type SupportedSeries = 'Candlestick' | 'Histogram' | 'Line'
export type Plot<T extends SupportedSeries> = {
  type: T
  series: ISeriesApi<T>
}

export const barData = (candle: Candle): BarData => ({
  time: (candle.time.getTime() / 1000) as UTCTimestamp,
  open: candle.open,
  high: candle.high,
  low: candle.low,
  close: candle.close,
})

export const volumeBar = (candle: Candle): HistogramData => ({
  time: (candle.time.getTime() / 1000) as UTCTimestamp,
  value: candle.volume,
  color: candle.open > candle.close ? 'red' : 'green',
})

export const isCrosshairVisible = (mouseEvent: MouseEventParams): boolean =>
  !(
    mouseEvent.time === undefined
    || mouseEvent.point === undefined
    || mouseEvent.point.x < 0
    || mouseEvent.point.y < 0
  )

export const getCrosshairedCandleValue = (
  mouseEvent: MouseEventParams,
  // This is an undesireably-wide type but helps ease the client code with our current
  // implementation
  // candleSeries: ISeriesApi<'Candlestick'>,
  candleSeries: ISeriesApi<SupportedSeries>,
  property: 'open' | 'high' | 'low' | 'close',
): number => {
  const bar = mouseEvent.seriesPrices.get(candleSeries)!
  return typeof bar === 'number' ? bar : bar[property]
}
