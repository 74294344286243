import Big from 'big.js'

import { CoinbaseOrderStatus } from './coinbase/CoinbaseOrderStatus'

export type FilledOrder = {
  side: 'buy' | 'sell'
  size: number
  price: number
  status: CoinbaseOrderStatus
  productID: string
}

export type OpenOrder = {
  side: 'buy' | 'sell'
  size: number
  filledQuantity: number
  price: number
  status: CoinbaseOrderStatus
  ID: string
  productID: string
}

export type LimitOrder = {
  // side: 'ask' | 'bid'
  price: Big
  filledQuantity: Big
  remainingQuantity: Big
  totalQuantity: Big
  ID: string
  productID: string
}

export type LimitOrderBook = {
  asks: Map<number, LimitOrder[]>
  bids: Map<number, LimitOrder[]>
}

export const zeroLimitOrderBook = (): LimitOrderBook => {
  return {
    asks: new Map(),
    bids: new Map(),
  }
}

export type DisplayLimitOrder = {
  [K in keyof LimitOrder]: LimitOrder[K] extends Big ? string : LimitOrder[K]
}

export const display = (side: Map<number, LimitOrder[]>) =>
  Array.from(side.entries()).reduce<Record<number, DisplayLimitOrder[]>>(
    (acc, [price, orders]) => {
      acc[price] = orders.map((order): DisplayLimitOrder => ({
        price: order.price.valueOf(),
        filledQuantity: order.filledQuantity.valueOf(),
        remainingQuantity: order.remainingQuantity.valueOf(),
        totalQuantity: order.totalQuantity.valueOf(),
        ID: order.ID,
        productID: order.productID,
      }))
      return acc
    },
    {},
  )

export const inspectLimitOrderBook = (lob: LimitOrderBook): void => {
  console.log('asks:', display(lob.asks))
  console.log('bids:', display(lob.bids))
}
