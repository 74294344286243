import { API } from 'aws-amplify'
import { Order, OrderStatus } from 'coinbase-pro-node'

import type { OrderResponse } from '../..'
import { getCurrentUser } from '../../../components/Auth/AppUser'
import { LimitOrderType } from '../../../types/index'

const apiName = 'Endpoint'

export const submitLimitOrder = ({ isTestnet }: { isTestnet: boolean }) =>
  async ({
    side,
    quantity,
    price,
    tradepair,
    post_only,
  }: LimitOrderType): Promise<OrderResponse> => {
    return await API.post(apiName, 'backend/coinbase/submit', {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        type: 'limit',
        product_id: tradepair,
        side: side,
        size: quantity.toString(),
        price: price.toString(),
        post_only: post_only,
        email: getCurrentUser().email,
        useSandbox: isTestnet,
      },
    })
      .then((order: Order): OrderResponse => {
        return order.status === OrderStatus.DONE || order.status === OrderStatus.PENDING
          ? { type: 'ok', order: 'submitted' }
          : { type: 'error', message: 'not submitted' }
      })
      .catch((err: Error): OrderResponse => ({ type: 'error', message: String(err) }))
  }
