import { API } from 'aws-amplify'
import * as E from 'fp-ts/Either'

import type { CoinbaseAuth } from '..'
import { getCurrentUser } from '../../../components/Auth/AppUser'

import { CoinbaseOrders } from './codecs'

export const getOpenOrders = (
  { isTestnet, auth }: { isTestnet: boolean; auth: CoinbaseAuth | undefined },
) =>
  async (): Promise<CoinbaseOrders> => {
    // This occurs when the user either doesn't have auth set up or isn't active
    if (auth === undefined) {
      return {
        data: [],
      }
    }

    const apiName = 'Endpoint'
    const myInit = {
      queryStringParameters: {
        email: getCurrentUser().email,
        useSandbox: isTestnet,
      },
    }
    return API.get(apiName, 'backend/coinbase/orders', myInit)
      .then((response: unknown) => {
        const maybeOrders = CoinbaseOrders.decode(response)
        if (E.isLeft(maybeOrders)) {
          // FIXME: throw an error with more data
          throw new Error('Received unexpected data from Coinbase orders endpoint')
        }
        return maybeOrders.right
      })
    // TODO: DISCUSS: catch the error somehow for logging, but would it just be rethrown?
    // .catch((err) => (console.error(err), err))
  }
