import Big from 'big.js'
import { match } from 'ts-pattern'
import { LimitOrderType } from '../types/index'

/**
 * @deprecated This is no longer going to be used. It just creates errors for now.
 * We already make sure the orders don't go through as market orders if they are specified as limit.
 */
const getPostOnly = (post_only: 'true' | 'false' | undefined): boolean => {
  return match(post_only)
    .with('true', () => true)
    .with('false', () => false)
    .with(undefined, () => false)
    .otherwise(() => false)
}

const createLimitSellOrder = (
  have: Big,
  need: Big,
  tradepair: string,
  limitPrice: number,
): LimitOrderType => {
  // Kraken doesn't include fes, they just have them as zero
  const total = have.minus(need)
  const quantity = total.valueOf()
  const amount = total.mul(limitPrice)

  return {
    side: 'sell',
    quantity,
    tradepair,
    price: limitPrice,
    amount,
    post_only: false
  }
}

const createLimitBuyOrder = (
  have: Big,
  need: Big,
  tradepair: string,
  limitPrice: number,
): LimitOrderType => {
  // Kraken doesn't include fees, they just have them as zero
  const total = need.minus(have)
  const quantity = total.valueOf()
  const amount = total.mul(limitPrice)

  return {
    side: 'buy',
    quantity,
    tradepair,
    price: limitPrice,
    amount,
    post_only: false
  }
}

export default function getKrakenLimitOrder(
  baseBalanceInBaseCurrency: Big,
  needQuanity: Big,
  tradepair: string,
  limitPrice: number,
): LimitOrderType {
  // Both of these limit orders have to be submitted in the base currency
  const order = baseBalanceInBaseCurrency.gt(needQuanity)
    ? createLimitSellOrder(
      baseBalanceInBaseCurrency,
      needQuanity,
      tradepair,
      limitPrice,
    )
    : createLimitBuyOrder(
      baseBalanceInBaseCurrency,
      needQuanity,
      tradepair,
      limitPrice,
    )

  return order
}
