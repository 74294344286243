 import Big from 'big.js'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { Observable } from 'rxjs'
import * as $ from 'rxjs'
import { Balances } from '../gateway'
import { CoinbaseTradepairs } from '../gateway/coinbase/rest/codecs'
import { KrakenTradepairDataGroup, KrakenTradepairs } from '../gateway/kraken/rest/codecs'
import { match } from 'ts-pattern'

type TradepairBalance = {
  asset: string
  amount: string
}

function getBalance(currency: string, balance: Balances) {
  const balanceTotal = balance.get(currency)?.available
  if (balanceTotal !== undefined) {
    return balanceTotal
  }
  return new Big(0)
}

export function AvailableBalance(props: {
  balance$: Observable<Balances>
  currentTradepair: string
  products: Observable<KrakenTradepairs | CoinbaseTradepairs>
  tradepairData: $.Observable<KrakenTradepairDataGroup>
}) {
  const [balance, setBalance] = React.useState<TradepairBalance[]>([])

  React.useEffect(() => {
    const incrementedBalance = pipe(
      props.products,
      $.mergeMap((allProducts) => {
        return match(allProducts)
          .exhaustive()
          .when(CoinbaseTradepairs.is, (coinbaseProduct) => {
            return pipe(
              props.balance$,
              $.map((balance) => {
                const product = coinbaseProduct.find((p) => p.id === props.currentTradepair)
                if (product !== undefined) {
                  const baseBalance = getBalance(product.base_currency, balance)
                  const quoteBalance = getBalance(product.quote_currency, balance)
                  return [
                    {
                      asset: product.base_currency,
                      amount: (baseBalance.minus(baseBalance.mod(product.base_increment)))
                        .valueOf(),
                    },
                    {
                      asset: product.quote_currency,
                      amount:
                        (quoteBalance.minus(quoteBalance.mod(product.quote_increment)))
                          .valueOf(),
                    },
                  ]
                }
                return []
              }),
            )
          })
          .when(KrakenTradepairs.is, (krakenProduct) => {
            return pipe(
              $.combineLatest([props.balance$, props.tradepairData]),
              $.map(([balance, tradepairData]) => {
                const product = krakenProduct.find((p) => p.wsname === props.currentTradepair)

                if (product !== undefined) {
                  const baseBalance = getBalance(product.base, balance)
                  const quoteBalance = getBalance(product.quote, balance)

                  const [readableBase, readableQuote] = product.wsname.split('/')
                  const baseData = tradepairData.find((d) => {
                    return d.altname === readableBase
                })

                const quoteData = tradepairData.find((d) => {
                    return d.altname === readableQuote
                })

                  return [
                    {
                      asset: readableBase,
                      amount: baseBalance.round(baseData?.display_decimals, Big.roundDown).valueOf(),
                    },
                    {
                      asset: readableQuote,
                      amount: quoteBalance.round(quoteData?.display_decimals, Big.roundDown).valueOf(),
                    },
                  ]
                }
                return []
              }),
            )
          })
          .run()
      }),
      $.map((balance) => {
        setBalance(balance)
      })
    )

    const balanceSubscription = incrementedBalance.subscribe({
      next: () => {
      },
      error: (err) => {
        // TODO: do something drastic here
        console.error('Balance observable errored:', err)
      },
      complete: () => {
        // TODO: do something drastic here, this should never happen
        console.warn('Balance observable has closed')
      },
    })

    return function cleanup() {
      balanceSubscription.unsubscribe()
    }
  }, [props.currentTradepair])

  return (
    <div>
      <div className='monaco'>
        <div
          className='chart behind orders'
          id='balance'
        >
          <table>
            <tr>
              <th>Asset</th>
              <th style={{ textAlign: 'right' }}>Available Balance</th>
            </tr>
            {balance.map((value, index) => {
              return (
                <tr key={index}>
                  <td>{value.asset}</td>
                  <td style={{ textAlign: 'right' }}>
                    {value.amount}
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    </div>
  )
}
