import { Router } from '@reach/router'
import React from 'react'
import 'react-notifications/lib/notifications.css'

import { ResponsiveLocalStorageLayout as Home } from '../components/Home'
import PrivateTerminalRoute from '../components/Routes/PrivateTerminalRoute'
// React Notification

const App = () => {
  return (
    <Router>
      <PrivateTerminalRoute path='/home' component={Home} />
    </Router>
  )
}

export default App
