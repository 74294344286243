import { LimitOrder } from '../gateway'

export type UserSignalMarketEvent = {
  type: 'market'
  exposure: number
}

export type UserSignalLimitEvent = {
  type: 'limit'
  exposure: number
  price: number
  post_only?: 'true' | 'false'
}

export type UserSignalEvent =
  | UserSignalMarketEvent
  | UserSignalLimitEvent

export type UserSignalEventGroup = {
  type: 'signal'
  signals: UserSignalEvent[]
}

export const isUserSignalEventGroup = (
  value: unknown,
): value is UserSignalEventGroup => {
  return value !== null && value !== undefined
    && (value as UserSignalEventGroup).type === 'signal'
    && Array.isArray((value as UserSignalEventGroup).signals)
}

export type OrdersRequestedEvent = {
  type: 'order requested'
  signal: UserSignalEvent
}

export type OrderPlacedEvent = {
  type: 'order placed successfully'
  signal: UserSignalEvent
}

export type OrderFailureEvent = {
  type: 'order submission failed'
  signal: UserSignalEvent
}

export type OrderRequestDeleteEvent = {
  type: 'order delete requested'
  order: LimitOrder | string[]
}

export type OrderSuccessDeleteEvent = {
  type: 'order deleted successfully'
  order: LimitOrder | string[]
}

export type OrderDeleteFailedEvent = {
  type: 'order deletion failed'
  order: LimitOrder | string[]
}

export type EventLogEvent =
  | UserSignalEventGroup
  | OrdersRequestedEvent
  | OrderPlacedEvent
  | OrderFailureEvent
  | OrderRequestDeleteEvent
  | OrderSuccessDeleteEvent
  | OrderDeleteFailedEvent
