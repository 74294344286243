import React from 'react'
import * as $ from 'rxjs'
import { Observable } from 'rxjs'
import { match } from 'ts-pattern'

import { Gateway, KrakenGateway, LimitOrderBook } from '../gateway'
import { stopAndDeleteOrders, stopAndDeleteKrakenOrders } from '../pyodide/delete-orders'
import { Exchange } from '../types/index'

export function DeleteOrders(props: {
  orderBook$: Observable<LimitOrderBook>
  selectedTradepair$: Observable<string>
  selectedExchange$: Observable<Exchange>
  gateway: Observable<Gateway | KrakenGateway>
  stopRequest$: $.Subject<{
    type: 'stop'
    program: string
  }>
}) {
  React.useEffect(() => {
    const myButton = document.getElementById('delete-button') as HTMLElement
    if (myButton !== null) {
      const buttonClick = $.fromEvent(myButton, 'click')

      buttonClick.pipe(
        $.withLatestFrom(
          props.orderBook$,
          props.selectedTradepair$,
          props.gateway,
          props.selectedExchange$
        ),
      ).subscribe(([_values, orderBook, tradepair, gateway, exchange]) => {
        props.stopRequest$.next({ type: 'stop', program: '' })

        return match(exchange)
          .exhaustive()
          .with('coinbase', () => {
            return stopAndDeleteOrders(
              orderBook,
              tradepair,
              gateway as Gateway,
            )
          })
          .with('kraken', () => {
            return stopAndDeleteKrakenOrders(
              orderBook,
              tradepair,
              gateway as KrakenGateway
            )
          })
          .run()
      })
    }
  }, [])

  return (
    <div style={{ display: 'inline', position: 'relative' }}>
      <button
        className='btn btn-dark btn-sm'
        id='delete-button'
        disabled={true}
      >
        <i className='bi bi-trash2 mr-1'></i>
        Delete Open Orders
      </button>
    </div>
  )
}
