import Big from 'big.js'

export function getDesiredTradepairPortfolio(
  exposure: number,
  // For market orders the totalBalance will be in quote
  // For limit orders it will be in base
  totalBalance: Big,
): Big {

  const needQuantity = totalBalance.times(exposure)
  return needQuantity
}
