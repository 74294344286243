import * as t from 'io-ts'
import { DateFromISOString, NumberFromString, UUID } from 'io-ts-types'

import { BigFromString } from '../../BigCodec'
import { CoinbaseOrderSide } from '../CoinbaseOrderSide'
import { CoinbaseProductID } from '../CoinbaseProductID'

/**
 * A subscription message is sent to acknowledge a successfully-opened websocket.
 *
 * @example
 * {
 *    "type": "subscriptions",
 *    "channels": [
 *      {
 *        "name": "user",
 *        "product_ids": ["BTC-USD"]
 *      },
 *      {
 *        "name": "heartbeat",
 *        "product_ids": ["BTC-USD"]
 *      }
 *    ]
 * }
 */
export const CoinbaseSubscriptionMessage = t.type(
  {
    type: t.literal('subscriptions'),
  },
  'CoinbaseSubscriptionMessage',
)
export type CoinbaseSubscriptionMessage = t.TypeOf<typeof CoinbaseSubscriptionMessage>

/**
 * Heartbeats include sequence numbers and last trade ids that can be used to
 * verify no messages were missed.
 *
 * @example
 * {
 *    "type": "heartbeat",
 *    "time": "2022-01-30T16:04:04.220103Z",
 *    "sequence": 490850075,
 *    "product_id": "BTC-USD",
 *    "last_trade_id": 37902445
 * }
 */
export const CoinbaseHeartbeatMessage = t.type(
  {
    type: t.literal('heartbeat'),
    last_trade_id: t.Int,
    product_id: CoinbaseProductID,
    sequence: t.Int,
    time: DateFromISOString,
  },
  'CoinbaseHeartbeatMessage',
)
export type CoinbaseHeartbeatMessage = t.TypeOf<typeof CoinbaseHeartbeatMessage>

/** *****************************************************************************
 * Messages on the Ticker websocket
 * **************************************************************************** */

/**
 * @example
 * {
 *    "type":"ticker",
 *    "sequence":31729965940,
 *    "product_id":"BTC-USD",
 *    "price":"55026.17",
 *    "open_24h":"56613.75",
 *    "volume_24h":"12839.04303355",
 *    "low_24h":"54615",
 *    "high_24h":"57670.68",
 *    "volume_30d":"403076.42712391",
 *    "best_bid":"55026.16",
 *    "best_ask":"55026.17",
 *    "side":"buy",
 *    "time":"2021-12-03T17:51:10.047406Z",
 *    "trade_id":245231746,
 *    "last_size":"0.0013562"
 * }
 */
export const CoinbaseTickerMessage = t.type(
  {
    type: t.literal('ticker'),
    // sequence: t.Int,
    // NOTE: we could promote this to a `CoinbaseProductID` if there is a need
    product_id: t.string,
    price: NumberFromString,
    // open_24h: t.string,
    // volume_24h: t.string,
    // low_24h: t.string,
    // high_24h: t.string,
    // volume_30d: t.string,
    // best_bid: t.string,
    // best_ask: t.string,
    side: t.union([t.literal('buy'), t.literal('sell')]),
    time: DateFromISOString,
    // trade_id: t.Int,
    last_size: NumberFromString,
  },
  'CoinbaseTickerMessage',
)
export type CoinbaseTickerMessage = t.TypeOf<typeof CoinbaseTickerMessage>

export const CoinbaseTickerWebsocketMessage = t.union(
  [
    CoinbaseTickerMessage,
    CoinbaseHeartbeatMessage,
    CoinbaseSubscriptionMessage,
  ],
  'CoinbaseTickerWebsocketMessage',
)
export type CoinbaseTickerWebsocketMessage = t.TypeOf<
  typeof CoinbaseTickerWebsocketMessage
>

/** *****************************************************************************
 * Messages on the User websocket
 * **************************************************************************** */

export const CoinbaseOrderReceiveMessage = t.type(
  {
    type: t.literal('received'),
  },
  'CoinbaseOrderReceiveMessage',
)
export type CoinbaseOrderReceiveMessage = t.TypeOf<typeof CoinbaseOrderReceiveMessage>

/**
 * The order is now open on the order book.
 * This message will only be sent for orders which are not fully filled immediately.
 * remaining_size will indicate how much of the order is unfilled and going on the book.
 *
 * There will be no open message for orders which will be filled immediately.
 * There will be no open message for market orders since they are filled immediately.
 *
 * @example
 * {
 *    "type": "open",
 *    "time": "2014-11-07T08:19:27.028459Z",
 *    "product_id": "BTC-USD",
 *    "sequence": 10,
 *    "order_id": "d50ec984-77a8-460a-b958-66f114b0de9b",
 *    "price": "200.2",
 *    "remaining_size": "1.00",
 *    "side": "sell"
 * }
 */
export const CoinbaseOrderOpenMessage = t.type(
  {
    type: t.literal('open'),
    time: DateFromISOString,
    product_id: CoinbaseProductID,
    sequence: t.Int,
    order_id: UUID,
    price: BigFromString,
    remaining_size: BigFromString,
    side: CoinbaseOrderSide,
  },
  'CoinbaseOrderOpenMessage',
)
export type CoinbaseOrderOpenMessage = t.TypeOf<typeof CoinbaseOrderOpenMessage>

/**
 * @example
 * {
 *    "type": "done",
 *    "time": "2014-11-07T08:19:27.028459Z",
 *    "product_id": "BTC-USD",
 *    "sequence": 10,
 *    "price": "200.2",
 *    "order_id": "d50ec984-77a8-460a-b958-66f114b0de9b",
 *    "reason": "filled", // or "canceled"
 *    "side": "sell",
 *    "remaining_size": "0"
 * }
 */
export const CoinbaseMarketOrderDoneMessage = t.type(
  {
    type: t.literal('done'),
    time: DateFromISOString,
    product_id: CoinbaseProductID,
    sequence: t.Int,
    order_id: UUID,
    reason: t.keyof({ filled: null, canceled: null }),
    side: CoinbaseOrderSide,
  },
  'CoinbaseMarketOrderDoneMessage',
)
export type CoinbaseMarketOrderDoneMessage = t.TypeOf<
  typeof CoinbaseMarketOrderDoneMessage
>

/**
 * The order is no longer on the order book.
 * Sent for all orders for which there was a received message.
 * This message can result from an order being canceled or filled.
 * There will be no more messages for this order_id after a done message.
 * remaining_size indicates how much of the order went unfilled; this will be 0 for filled orders.
 * market orders will not have a remaining_size or price field as they are never on the open order book at a given price.
 *
 * Note:
 * - A done message will be sent for received orders which are fully filled or canceled due to self-trade prevention.
 * - There will be no open message for such orders.
 * - done messages for orders which are not on the book should be ignored when maintaining a real-time order book.
 *
 * @example
 * {
 *    "type": "done",
 *    "time": "2014-11-07T08:19:27.028459Z",
 *    "product_id": "BTC-USD",
 *    "sequence": 10,
 *    "price": "200.2",
 *    "order_id": "d50ec984-77a8-460a-b958-66f114b0de9b",
 *    "reason": "filled", // or "canceled"
 *    "side": "sell",
 *    "remaining_size": "0"
 * }
 */
export const CoinbaseOrderDoneMessage = t.intersection(
  [
    CoinbaseMarketOrderDoneMessage,
    t.partial(
      {
        price: BigFromString,
        remaining_size: BigFromString,
      },
      'CoinbaseOrderDoneMessageOptionalProperties',
    ),
  ],
  'CoinbaseOrderDoneMessage',
)
export type CoinbaseOrderDoneMessage = t.TypeOf<typeof CoinbaseOrderDoneMessage>

/**
 * A trade occurred between two orders.
 * The aggressor or taker order is the one executing immediately after being received and the maker order is a resting order on the book.
 * The side field indicates the maker order side.
 * If the side is sell this indicates the maker was a sell order and the match is considered an up-tick.
 * A buy side match is a down-tick.
 * Size is always in units of the base currency.
 *
 * @example
 * {
 *    "type": "match",
 *    "trade_id": 10,
 *    "sequence": 50,
 *    "maker_order_id": "ac928c66-ca53-498f-9c13-a110027a60e8",
 *    "taker_order_id": "132fb6ae-456b-4654-b4e0-d681ac05cea1",
 *    "time": "2014-11-07T08:19:27.028459Z",
 *    "product_id": "BTC-USD",
 *    "size": "5.23512",
 *    "price": "400.23",
 *    "side": "sell"
 * }
 *
 * If authenticated, and you were the taker, the message would also have the following fields:
 *
 * {
 *   "taker_user_id": "5844eceecf7e803e259d0365",
 *   "user_id": "5844eceecf7e803e259d0365",
 *   "taker_profile_id": "765d1549-9660-4be2-97d4-fa2d65fa3352",
 *   "profile_id": "765d1549-9660-4be2-97d4-fa2d65fa3352",
 *   "taker_fee_rate": "0.005"
 * }
 *
 * Similarly, if you were the maker, the message would have the following:
 *
 * {
 *   "maker_user_id": "5f8a07f17b7a102330be40a3",
 *   "user_id": "5f8a07f17b7a102330be40a3",
 *   "maker_profile_id": "7aa6b75c-0ff1-11eb-adc1-0242ac120002",
 *   "profile_id": "7aa6b75c-0ff1-11eb-adc1-0242ac120002",
 *   "maker_fee_rate": "0.001"
 * }
 */
export const CoinbaseOrderMatchMessage = t.intersection([
  t.type(
    {
      type: t.literal('match'),
      trade_id: t.Int,
      sequence: t.Int,
      maker_order_id: UUID,
      taker_order_id: UUID,
      time: DateFromISOString,
      product_id: CoinbaseProductID,
      size: BigFromString,
      price: BigFromString,
      side: CoinbaseOrderSide,
      user_id: t.string, // only present when authenticated
      profile_id: UUID, // only present when authenticated
    },
    'CoinbaseOrderMatchMessage',
  ),
  t.union(
    [
      t.type(
        {
          taker_user_id: t.string,
          taker_profile_id: UUID,
          taker_fee_rate: BigFromString,
          maker_user_id: t.undefined,
          maker_profile_id: t.undefined,
          maker_fee_rate: t.undefined,
        },
        'CoinbaseOrderMatchMakerOrderMessage',
      ),
      t.type(
        {
          taker_user_id: t.undefined,
          taker_profile_id: t.undefined,
          taker_fee_rate: t.undefined,
          maker_user_id: t.string,
          maker_profile_id: UUID,
          maker_fee_rate: BigFromString,
        },
        'CoinbaseOrderMatchLimitOrderMessage',
      ),
    ],
    'CoinbaseOrderMatchMessageOptionalProperties',
  ),
])
export type CoinbaseOrderMatchMessage = t.TypeOf<typeof CoinbaseOrderMatchMessage>

/**
 * An order has changed.
 * This is the result of self-trade prevention adjusting the order size or available funds.
 * Orders can only decrease in size or funds.
 * change messages are sent anytime an order changes in size; this includes resting orders (open) as well as received but not yet open.
 * change messages are also sent when a new market order goes through self trade prevention and the funds for the market order have changed.
 *
 * Any change message where the price is null indicates that the change message is for a market order.
 * Change messages for limit orders will always have a price specified.
 *
 * @example
 * {
 *    "type": "change",
 *    "time": "2014-11-07T08:19:27.028459Z",
 *    "sequence": 80,
 *    "order_id": "ac928c66-ca53-498f-9c13-a110027a60e8",
 *    "product_id": "BTC-USD",
 *    "new_size": "5.23512",
 *    "old_size": "12.234412",
 *    "price": "400.23", // or `null` for market orders
 *    "side": "sell"
 * }
 */
export const CoinbaseOrderChangeMessage = t.type(
  {
    type: t.literal('change'),
  },
  'CoinbaseOrderChangeMessage',
)
export type CoinbaseOrderChangeMessage = t.TypeOf<typeof CoinbaseOrderChangeMessage>

export const CoinbaseStopOrderActivateMessage = t.type(
  {
    type: t.literal('activate'),
  },
  'CoinbaseStopOrderActivateMessage',
)
export type CoinbaseStopOrderActivateMessage = t.TypeOf<
  typeof CoinbaseStopOrderActivateMessage
>

export const CoinbaseAuctionMessage = t.type(
  {
    type: t.literal('auction'),
  },
  'CoinbaseAuctionMessage',
)
export type CoinbaseAuctionMessage = t.TypeOf<typeof CoinbaseAuctionMessage>

export const CoinbaseUserWebsocketMessage = t.union(
  [
    CoinbaseOrderReceiveMessage,
    CoinbaseOrderOpenMessage,
    CoinbaseOrderDoneMessage,
    CoinbaseOrderMatchMessage,
    CoinbaseOrderChangeMessage,
    CoinbaseStopOrderActivateMessage,
    CoinbaseAuctionMessage,
    CoinbaseHeartbeatMessage,
    CoinbaseSubscriptionMessage,
  ],
  'CoinbaseUserWebsocketMessage',
)
export type CoinbaseUserWebsocketMessage = t.TypeOf<typeof CoinbaseUserWebsocketMessage>
