import { API } from 'aws-amplify'
import * as E from 'fp-ts/Either'

import type { CoinbaseAuth } from '..'
import type { Balances } from '../..'
import { getCurrentUser } from '../../../components/Auth/AppUser'

import { CoinbaseProfileAccounts } from './codecs'

export const getCurrentBalances = async ({
  isTestnet,
  auth,
}: {
  isTestnet: boolean
  auth: CoinbaseAuth
}): Promise<Balances> => {
  const apiName = 'Endpoint'
  const myInit = {
    queryStringParameters: {
      useSandbox: isTestnet,
      email: getCurrentUser().email,
    },
  }
  return API.get(apiName, 'backend/coinbase/accounts', myInit)
    .then((response: unknown): Balances => {
      const maybeAccounts = CoinbaseProfileAccounts.decode(response)
      if (E.isLeft(maybeAccounts)) {
        // FIXME: throw an error with more data
        throw new Error('Received unexpected data from coinbase accounts endpoint')
      }
      return maybeAccounts.right.reduce<Balances>(
        (balances, account) => {
          balances.set(account.currency, {
            total: account.balance,
            available: account.available,
            held: account.hold,
          })
          return balances
        },
        new Map(),
      )
    })
  // TODO: DISCUSS: catch the error somehow for logging, but would it just be rethrown?
  // .catch((err) => (console.error(err), err))
}
