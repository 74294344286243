import { API } from 'aws-amplify'
import { Order, OrderStatus } from 'coinbase-pro-node'

import type { OrderResponse } from '../..'
import { getCurrentUser } from '../../../components/Auth/AppUser'
import { MarketOrderType } from '../../../types/index'

const apiName = 'Endpoint'

export const submitMarketOrder = ({ isTestnet }: { isTestnet: boolean }) =>
  async ({
    side,
    quantity,
    tradepair,
  }: MarketOrderType): Promise<OrderResponse> => {
    const body = side === 'buy'
      ? {
        type: 'market',
        product_id: tradepair,
        side: side,
        funds: quantity,
        email: getCurrentUser().email,
        useSandbox: isTestnet,
      }
      : {
        type: 'market',
        product_id: tradepair,
        side: side,
        size: quantity,
        email: getCurrentUser().email,
        useSandbox: isTestnet,
      }

    return await API.post(apiName, 'backend/coinbase/submit', {
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then((order: Order): OrderResponse => {
        return order.status === OrderStatus.DONE || order.status === OrderStatus.PENDING
          ? { type: 'ok', order: 'submitted' }
          : { type: 'error', message: 'not submitted' }
      })
      .catch((err: Error): OrderResponse => ({ type: 'error', message: String(err) }))
  }
