import { pipe } from "fp-ts/lib/function";
import { WebSocketSubject } from "rxjs/webSocket";
import { KrakenAuth } from "..";
import { OrderResponse } from "../..";
import { LimitOrderType, MarketOrderType } from "../../../types/index";
import * as $ from 'rxjs'
import { KrakenAddOrderErrorMessage, KrakenAddOrderSuccessMessage, KrakenAddOrderResponseWebsocketMessage } from "./codecs";
import * as E from 'fp-ts/Either'
import * as PathReporter from 'io-ts/lib/PathReporter'
import { match } from 'ts-pattern'

const listenToResponseMessages = ({
    addLimitOrderWebsocket,
}: {
    addLimitOrderWebsocket: $.Observable<unknown>,
}): $.Observable<OrderResponse> => {
    return pipe(
        addLimitOrderWebsocket,
        $.map((websocketMessage): OrderResponse => {
            return pipe(
                KrakenAddOrderResponseWebsocketMessage.decode(websocketMessage),
                E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
                // Decode the websocket message and filter out messages we do
                // not require to track the user's current balances
                E.map((message) =>
                    match<
                        KrakenAddOrderResponseWebsocketMessage,
                        OrderResponse
                    >(message)
                        .when(KrakenAddOrderSuccessMessage.is, () => {
                            return { type: 'ok', order: 'order added' }
                        })
                        .when(KrakenAddOrderErrorMessage.is, (error) => { return { type: 'error', message: `error adding order: ${error}` } })
                        .otherwise(() => {
                            console.warn('unmatched add order response message:', message)
                            return { type: 'error', message: 'unmatched add order response message' }
                        }),
                ),
                E.getOrElseW((error) => {
                    throw new Error('Private websocket feed error: ' + error)
                }),
            )
        }),
    )
}

export const submitLimitOrder = ({ privateWebsocket, auth }: { privateWebsocket: WebSocketSubject<unknown>, auth: KrakenAuth | undefined }) => ({
    side,
    tradepair,
    quantity,
    price
}: LimitOrderType): $.Observable<OrderResponse> => {
    if (auth === undefined) {
        return $.of({ type: 'error', message: `error adding limit order: auth not defined` })
    }

    const addLimitOrder = {
        event: "addOrder",
        token: auth.token,
        ordertype: "limit",
        type: side,
        pair: tradepair,
        price: price.toString(),
        volume: quantity,
        oflags: side === 'buy' ? "fcib" : "fciq"
    } as const

    const addLimitOrderWebsocket = pipe(
        privateWebsocket.multiplex(
            () => (addLimitOrder),
            //DISCUSS: do we need to put anything here?
            () => ({}),
            (message: any): boolean => message.event === 'addOrderStatus'
        ),
    )

    return listenToResponseMessages({
        addLimitOrderWebsocket
    })
}
