import { webSocket } from 'rxjs/webSocket'
import { KrakenGatewayConstructor } from '../'
import { getTradepairs } from './rest/get-tradepairs'
import { getTradepairData } from './rest/get-tradepair-data'
import { websocketEndpoint, privateWebsocketEndpoint } from './endpoints'
import { subscribeToCandles } from './stream/candles'
import { subscribeToUserFeedWebsocket } from './stream/user-feed'
import { subscribeToBalances } from './stream/balances'
import { subscribeToOpenOrderWebsocket } from './stream/orders'
import { submitLimitOrder } from './websocket/submit-limit-order'
import { submitMarketOrder } from './websocket/submit-market-order'
import { deleteOrder } from './websocket/delete-order'
import { subscribeToUserOrderBook } from './websocket/user-order-book'

export interface KrakenAuth {
  apiKey: string
  apiSecret: string
  token: string
}

export const KrakenGatewayRoutes: KrakenGatewayConstructor<KrakenAuth | undefined> = (
  { auth },
) => {
  const mainWebsocket = webSocket(websocketEndpoint)
  const privateWebsocket = webSocket(privateWebsocketEndpoint)

  return {
    subscribeToCandles: subscribeToCandles({ mainWebsocket }),
    subscribeToUserFeed: subscribeToUserFeedWebsocket({
      auth,
      privateWebsocket,
    }),
    subscribeToBalances: subscribeToBalances({ auth }),
    getTradepairs: getTradepairs(),
    getTradepairData: getTradepairData(),
    subscribeToOpenOrderFeed: subscribeToOpenOrderWebsocket({ auth, privateWebsocket }),
    subscribeToOrderBook: subscribeToUserOrderBook,
    submitMarketOrder: submitMarketOrder({ auth, privateWebsocket }),
    submitLimitOrder: submitLimitOrder({ auth, privateWebsocket }),
    deleteOrder: deleteOrder({ auth, privateWebsocket }),
  }
}
