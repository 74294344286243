// TODO: reconnect websocket on disconnect (it happens)
import * as T from 'fp-ts/Task'
import * as TE from 'fp-ts/TaskEither'
import { pipe } from 'fp-ts/function'
import { Link } from 'gatsby'
import * as t from 'io-ts'
import { Lens } from 'monocle-ts'
import React, { useEffect, useState } from 'react'
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout'
import Select from 'react-select'
import * as $ from 'rxjs'
import { match, when } from 'ts-pattern'
import { StratosTableUserSubscriptionActive } from '@stratos-trade/io-ts'

import { Balances, Gateway, LimitOrderBook, KrakenGateway } from '../../gateway'
import { CoinbaseGateway } from '../../gateway/coinbase'
import { CoinbaseOrders, CoinbaseTradepairs } from '../../gateway/coinbase/rest/codecs'
import {
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
} from '../../gateway/coinbase/websocket/codecs'
import { useStore } from '../../store'
import {
  CandleEvent,
  CandleUpdateEvent,
  ReadableTimeframe,
  Timeframe,
  Exchange,
  exchanges
} from '../../types/index'
import { AvailableBalance } from '../Balances'
import { Chart } from '../Chart'
import { OrderNotification } from '../OrderNotification'
import { Orders } from '../Orders'
import { PyodideEditor } from '../PyodideEditor'
import { PyodideOutput } from '../PyodideOutput'
import Seo from '../Seo'
import {
  exchangeAuth,
  ExchangeAuthError,
  getKrakenToken,
  krakenExchangeAuth,
  paddleSubscription,
  PaddleSubscriptionError,
  QueryableStratosTableCoinbaseAuth,
  QueryableStratosTableKrakenAuth,
  QueryableStratosTableUserSubscriptionStates,
} from '../queryApi'

import { GridItemKey } from './codecs'
import { customStyles, selectItem } from './dropdown-styles'
import {
  ChartKey,
  ConsoleKey,
  DEFAULT_GRID_LAYOUT,
  EditorKey,
  GridLayout,
  GridLayoutKey,
  OrdersKey,
} from './terminal-grid-layout'
import { KrakenTradepairDataGroup, KrakenTradepairs } from '../../gateway/kraken/rest/codecs'
import { KrakenGatewayRoutes } from '../../gateway/kraken'
import { KrakenClosedOrderMessage, KrakenFilledOrderMessage, KrakenOpenOrderMessage, KrakenUserWebsocketMessage } from '../../gateway/kraken/websocket/codecs'
const Coinbase = require('../../assets/vectors/coinbase.svg')

const ResponsiveReactGridLayout = WidthProvider(Responsive)

// Check if window is defined (so if in the browser or in node.js).
// Required for gatsby build to succeed
const isBrowser = typeof global.localStorage !== 'undefined'

// DESIRE: type this nominally
const DEFAULT_TRADEPAIR = 'BTC-USD' as const
const DEFAULT_KRAKEN_TRADEPAIR = 'XBT/USD' as const

const DEFAULT_TIMEFRAME = 3600 as const
const DEFAULT_KRAKEN_TIMEFRAME = 60 as const
const DEFAULT_READABLE_TIMEFRAME = '1H' as const

const DEFAULT_EXCHANGE = 'coinbase' as const

// :see-no-evil:
function toggleRemove(i: string): void {
  const x = document.getElementById(i)
  if (x !== null) {
    if (x.style.display === 'none') {
      x.style.display = 'block'
    } else {
      x.style.display = 'none'
    }
  }
}

// TODO: consider using zustand's persistent middleware here
/**
 * Load the user's GridLayout from local storage.
 */
function loadGridLayout(): GridLayout {
  const localValue = global.localStorage.getItem(GridLayoutKey)
  if (typeof localValue === 'string') {
    try {
      return JSON.parse(localValue)
      // DISCUSS: we need to be sure this is an object
    } catch (e) {
      /*Ignore*/
    }
  }
  return DEFAULT_GRID_LAYOUT
}

// TODO: consider using zustand's persistent middleware here
/**
 * Write the user's GridLayout to local storage.
 */
function saveGridLayout(gridLayout: GridLayout): void {
  // FIXME: `JSON.stringify` can throw
  global.localStorage.setItem(GridLayoutKey, JSON.stringify(gridLayout))
}

// REFACTOR: local state should be managed transparently
// so there's no way to misconfigure or get out of sync. I'm not sure the best way
// to do that offhand, but maybe zustand provides a way

const hiddenGridItems = (breakpoint: string) =>
  Lens.fromPath<GridLayout>()(['hidden', breakpoint])
const visibleGridItems = (breakpoint: string) =>
  Lens.fromPath<GridLayout>()(['visible', breakpoint])
// const gridItemsTraversal = fromTraversable(A.Traversable)<Layout>()

/**
 * Move a grid item from the hidden layout to the visible layout.
 */
function showGridItem(
  gridItem: Layout,
  gridLayout: GridLayout,
  currentBreakpoint: string,
): GridLayout {
  // functional challenge: refactor this to use lenses idiomatically
  // WIP:
  // const visibleGridItemsTraversal = visibleGridItems(currentBreakpoint).composeTraversal(gridItemsTraversal)

  // 1. remove gridItem from the hidden layout
  let newGridLayout = hiddenGridItems(currentBreakpoint).modify((items) =>
    items.filter((item) => item.i !== gridItem.i),
  )(gridLayout)

  // 2. add gridItem to the visible layout
  newGridLayout = visibleGridItems(currentBreakpoint).modify((items) =>
    items
      // DO NOT REMOVE FILTER: this is required because of the grid layout automatic
      // function that will add a dummy gridItem (see onLayoutChange())
      .filter((item) => item.i !== gridItem.i)
      .concat(gridItem),
  )(newGridLayout)

  // 3. something about toggleRemove
  toggleRemove(gridItem.i)

  // 4. save the new layout to local storage
  saveGridLayout(newGridLayout)

  return newGridLayout
}

function hideGridItem(
  gridItem: Layout,
  gridLayout: GridLayout,
  currentBreakpoint: string,
): GridLayout {
  // functional challenge: refactor this to use lenses idiomatically

  // 1. remove gridItem from the visible layout
  let newGridLayout = visibleGridItems(currentBreakpoint).modify((items) =>
    items.filter((item) => item.i !== gridItem.i),
  )(gridLayout)

  // 2. add gridItem to the hidden layout
  newGridLayout = hiddenGridItems(currentBreakpoint).modify((items) =>
    items.concat(gridItem),
  )(newGridLayout)

  // 3. something about toggleRemove
  toggleRemove(gridItem.i)

  // 4. save the new layout to local storage
  saveGridLayout(newGridLayout)

  return newGridLayout
}

// REFACTOR: make local storage transparent
// we shouldn't think about it at this level of abstraction, it should be an implementation detail
const initialGridLayout = isBrowser ? loadGridLayout() : DEFAULT_GRID_LAYOUT
const initialBreakpoint = Object.keys(initialGridLayout.hidden)[0]

function isItemVisible(key: GridItemKey): boolean {
  return initialGridLayout.hidden[initialBreakpoint].some((item) => item.i === key)
}

const initialIsConsoleHidden = isItemVisible(ConsoleKey)
const initialIsChartHidden = isItemVisible(ChartKey)
const initialIsEditorHidden = isItemVisible(EditorKey)
const initialIsOrderHidden = isItemVisible(OrdersKey)

// I feel like this is a little bit hacky, because we're manually threading
// the original `program` value through, but it works for now. If we find a
// way to refactor such that the original step/simulate/execute requests
// capture the user's program at the time the step/simulate/execute button
// was clicked, and that doesn't require us to manually thread `program`
// Provide a signal that emits automatically when `step` is done evaluating
// once.
const stepComplete$ = new $.Subject<{ type: 'stop'; program: string }>()

const selectedTradepair$ = new $.Subject<string>()
const selectedTimeframe$ = new $.Subject<Timeframe>()
const selectedExchange$ = new $.BehaviorSubject<Exchange>(DEFAULT_EXCHANGE)

let products$: $.Observable<KrakenTradepairs | CoinbaseTradepairs>
let krakenTradepairData$: $.Observable<KrakenTradepairDataGroup>
let balance$: $.Observable<Balances>
let gateway$: $.Observable<Gateway | KrakenGateway>
let orderBook$: $.Observable<LimitOrderBook>
let candle$: $.Observable<CandleEvent>
let userFeedMessage$: $.Observable<
  CoinbaseOrderDoneMessage | CoinbaseOrderOpenMessage | CoinbaseOrderMatchMessage | KrakenUserWebsocketMessage
>
let openOrderFeedMessage$: $.Observable<KrakenOpenOrderMessage | KrakenClosedOrderMessage | KrakenFilledOrderMessage>
let openOrders: Promise<CoinbaseOrders>
let createCoinbaseGateway$: $.Observable<Gateway>
let createKrakenGateway$: $.Observable<KrakenGateway>

export function ResponsiveLocalStorageLayout() {
  // Layouts contains the visible panels in the react grid
  // FIXME: this JSON poop, too slow too untyped
  const [gridLayout, setGridLayout] = useState<GridLayout>(
    JSON.parse(JSON.stringify(initialGridLayout)),
  )
  const [tradepairs, setTradepairs] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)
  // TODO: nominally type tradepairs, so there is no confusing them with other strings
  // Change this to a type RawCoinbaserProductID
  const currentTradepair = useStore((state) => state.currentTradepair)
  const setCurrentTradepair = useStore((state) => state.setCurrentTradepair)

  const currentExchange = useStore((state) => state.currentExchange)
  const setCurrentExchange = useStore((state) => state.setCurrentExchange)

  const currentTimeframe = useStore((state) => state.currentTimeframe)
  const setCurrentTimeframe = useStore((state) => state.setCurrentTimeframe)

  const [isConsoleHidden, setConsoleHidden] = useState(initialIsConsoleHidden)
  const [isChartHidden, setChartHidden] = useState(initialIsChartHidden)
  const [isEditorHidden, setEditorHidden] = useState(initialIsEditorHidden)
  const [isOrderHidden, setOrderHidden] = useState(initialIsOrderHidden)
  // TODO: nominally type breakpoints so there is no confusing them with other strings
  const [currentBreakpoint, setCurrentBreakpoint] = useState(initialBreakpoint)
  const eraseProgrammaticPlots = useStore((state) => state.eraseProgrammaticPlots)
  const signalEvaluation = useStore((state) => state.signalEvaluation)

  const isActivePlan = useStore((state) => state.isActivePlan)
  const setIsActivePlan = useStore((state) => state.setIsActivePlan)

  const isAuth = useStore((state) => state.isAuth)
  const setIsAuth = useStore((state) => state.setIsAuth)

  const isActiveAuth = useStore((state) => state.isActiveAuth)
  const setIsActiveAuth = useStore((state) => state.setIsActiveAuth)

  global.localStorage.clear()

  /*
  * WARNING: DO NOT MOVE THIS OUT OF THE COMPONENT
  * It has to be inside because the coinbaseGateway uses websockets
  * that can't be server-side, you have to make sure that the client
  * has loaded so that you don't get the following error on build:
  * 'webpackError: ReferenceError: WebSocket is not defined'
  */
  const defaultCoinbaseGateway = CoinbaseGateway({
    isTestnet: process.env.GATSBY_STRATOS_TESTNET === 'true',
    auth: undefined,
  })

  const defaultKrakenGateway = KrakenGatewayRoutes({
    auth: undefined,
  })

  const subscriptionActive = (
    auth: QueryableStratosTableCoinbaseAuth,
  ) =>
    TE.fromIO<Gateway, PaddleSubscriptionError | ExchangeAuthError>(() => {
      return defaultCoinbaseGateway


      /* if (auth === undefined) {
        setIsAuth(false)
        return defaultCoinbaseGateway
      } else {
        setIsActivePlan(true)
        setIsAuth(true)
        return CoinbaseGateway({
          isTestnet: process.env.GATSBY_STRATOS_TESTNET === 'true',
          auth: {
            apiPassphrase: auth.passphrase,
            apiKey: auth.apiKey,
            apiSecret: auth.apiSecret,
          },
        })
      } */
    })

  const specificSubscriptionState = (
    a: QueryableStratosTableUserSubscriptionStates,
    b: QueryableStratosTableCoinbaseAuth,
  ) => {
    return match(a)
      .with(
        when(StratosTableUserSubscriptionActive.is),
        () => subscriptionActive(b),
      )
      .otherwise(() =>
        TE.fromIO<Gateway, PaddleSubscriptionError | ExchangeAuthError>(() => {
          setIsActivePlan(false)
          return defaultCoinbaseGateway
        }),
      )
  }

  const subscriptionActiveKraken = (
    auth: QueryableStratosTableKrakenAuth,
    token: string
  ) =>
    TE.fromIO<KrakenGateway, PaddleSubscriptionError | ExchangeAuthError>(() => {
      if (auth === undefined) {
        setIsAuth(false)
        return defaultKrakenGateway
      } else {
        setIsActivePlan(true)
        setIsAuth(true)
        return KrakenGatewayRoutes({
          auth: {
            apiKey: auth.apiKey,
            apiSecret: auth.apiSecret,
            token: token,
          },
        })
      }
    })

  const specificSubscriptionStateKraken = (
    a: QueryableStratosTableUserSubscriptionStates,
    b: QueryableStratosTableKrakenAuth,
    c: string
  ) => {
    return match(a)
      .with(
        when(StratosTableUserSubscriptionActive.is),
        () => subscriptionActiveKraken(b, c),
      )
      .otherwise(() =>
        TE.fromIO<KrakenGateway, PaddleSubscriptionError | ExchangeAuthError>(() => {
          setIsActivePlan(false)
          return defaultKrakenGateway
        }),
      )
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!createCoinbaseGateway$) {
    createCoinbaseGateway$ = pipe(
      $.from(
        pipe(
         /*  TE.Do,
          TE.bind('paddleSubscription', () => paddleSubscription()),
          TE.bindW('exchangeAuth', () => exchangeAuth()),
          TE.chainW(({ paddleSubscription, exchangeAuth }) =>
            specificSubscriptionState(paddleSubscription, exchangeAuth),
          ),
          // TODO(639): handle errors so we are aware, not the user first
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          TE.getOrElse(() => T.fromIO(() => defaultCoinbaseGateway)), */
          T.fromIO(() => defaultCoinbaseGateway),
          async (invokeTask) => invokeTask(),
        ),
      ),
      $.shareReplay(),
    )
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!createKrakenGateway$) {
    //TODO: change this to only load when the exchange changes to kraken
    createKrakenGateway$ = pipe(
      $.from(
        pipe(
        /*   TE.Do,
          TE.bind('paddleSubscription', () => paddleSubscription()),
          TE.bindW('exchangeAuth', () => krakenExchangeAuth()),
          TE.bindW('token', ({exchangeAuth}): TE.TaskEither<ExchangeAuthError, string> => {
            // TODO: this is not ideal and is a code smell, need to fix
            if (exchangeAuth === undefined) {
              return TE.left('this value does not matter' as any)
            }
            return getKrakenToken();
          }),
          TE.chainW(({ paddleSubscription, exchangeAuth, token }) =>
            specificSubscriptionStateKraken(paddleSubscription, exchangeAuth, token),
          ),
          // TODO(639): handle errors so we are aware, not the user first
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          TE.getOrElse(() => T.fromIO(() => defaultKrakenGateway)), */
          T.fromIO(() => defaultKrakenGateway),
          async (invokeTask) => invokeTask(),
        ),
      ),
      $.shareReplay(),
    )
  }

  const getUserFeedFromExchange = (exchange: Exchange, allProducts: CoinbaseTradepairs | KrakenTradepairs) => {
    switch (exchange) {
      case 'coinbase':
        return pipe(
          createCoinbaseGateway$,
          $.mergeMap((coinbaseGateway) =>
            coinbaseGateway.subscribeToUserFeed({ allProducts })
          )
        )

      case 'kraken':
        return pipe(
          createKrakenGateway$,
          $.mergeMap((krakenGateway) =>
            krakenGateway.subscribeToUserFeed()
          )
        )
    }
  }

  const getOpenOrderFromExchange = (exchange: Exchange) => {
    switch (exchange) {
      case 'coinbase':
        return $.EMPTY

      case 'kraken':
        return pipe(
          createKrakenGateway$,
          $.mergeMap((krakenGateway) =>
            krakenGateway.subscribeToOpenOrderFeed()
          )
        )
    }
  }

  const getBalanceFromExchange = (exchange: Exchange, allProducts: CoinbaseTradepairs | KrakenTradepairs) => {
    switch (exchange) {
      case 'coinbase':
        return pipe(
          createCoinbaseGateway$,
          $.mergeMap((coinbaseGateway) =>
            coinbaseGateway.subscribeToBalances({ message: userFeedMessage$ })
          )
        )

      case 'kraken':
        return pipe(
          createKrakenGateway$,
          $.mergeMap((krakenGateway) => {
            const group = $.merge(openOrderFeedMessage$, userFeedMessage$)
            return krakenGateway.subscribeToBalances({ message: group, products: allProducts, tradepairData: krakenTradepairData$ })
          }
          ),
        )
    }
  }

  const getGatewayFromExchange = (exchange: Exchange) => {
    switch (exchange) {
      case 'coinbase':
        return createCoinbaseGateway$

      case 'kraken':
        return createKrakenGateway$
    }
  }

  const getOrderBookFromExchange = (exchange: Exchange) => {
    switch (exchange) {
      case 'coinbase':
        return pipe(
          createCoinbaseGateway$,
          $.mergeMap((coinbaseGateway) =>
            coinbaseGateway.subscribeToOrderBook({
              message: userFeedMessage$,
              openOrders,
            }),
          ),
        )

      case 'kraken':
        return pipe(
          createKrakenGateway$,
          $.mergeMap((krakenGateway) =>
            krakenGateway.subscribeToOrderBook({
              message: openOrderFeedMessage$,
            })
          )
        )
    }
  }

  const getGateway = (exchange: Exchange): $.Observable<CoinbaseTradepairs | KrakenTradepairs> => {
    switch (exchange) {
      case 'coinbase':
        return pipe(
          createCoinbaseGateway$,
          $.mergeMap(async (gateway) => {
            selectedTradepair$.next(DEFAULT_TRADEPAIR)
            setCurrentTradepair(DEFAULT_TRADEPAIR)
            selectedTimeframe$.next(DEFAULT_TIMEFRAME)
            setCurrentTimeframe(DEFAULT_READABLE_TIMEFRAME)
            return gateway.getTradepairs()
              .then((products) => {
                return products.filter((product) =>
                  product.limit_only !== true
                  && product.post_only !== true
                  && product.cancel_only !== true
                  && product.trading_disabled !== true,
                )
              }
              )
          })
        )
      case 'kraken':
        return pipe(
          createKrakenGateway$,
          $.mergeMap(async (gateway) => {
            selectedTradepair$.next(DEFAULT_KRAKEN_TRADEPAIR)
            setCurrentTradepair(DEFAULT_KRAKEN_TRADEPAIR)
            selectedTimeframe$.next(DEFAULT_KRAKEN_TIMEFRAME)
            setCurrentTimeframe(DEFAULT_READABLE_TIMEFRAME)
            return gateway.getTradepairs()
              .then((products) =>
                products.filter((product) =>
                  product.status === "online"
                )
              )
          })
        )
    }
  }

  const getCandleGatway = (exchange: Exchange, timeframe: Timeframe, tradepair: string): $.Observable<CandleEvent> => {
    switch (exchange) {
      case 'coinbase':
        return pipe(
          createCoinbaseGateway$,
          $.mergeMap((coinbaseGateway) =>
            coinbaseGateway.subscribeToCandles({
              timeframe: timeframe,
              tradepair: tradepair,
            }),
          ),
          $.map((candle): CandleUpdateEvent => ({
            event: 'candle update',
            candle: candle,
          })),
        )
      case 'kraken':
        return pipe(
          createKrakenGateway$,
          $.mergeMap((krakenGateway) =>
            krakenGateway.subscribeToCandles({
              timeframe: timeframe,
              tradepair: tradepair,
            }),
          ),
          $.map((candle): CandleUpdateEvent => ({
            event: 'candle update',
            candle: candle,
          })),
        )
    }
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!products$) {
    products$ = pipe(
      selectedExchange$,
      $.switchMap((exchange): $.Observable<KrakenTradepairs | CoinbaseTradepairs> => {
        return getGateway(exchange)
      }),
      //DO NOT CHANGE
      $.shareReplay({ bufferSize: 0, refCount: true })
    )
  }

  if (!krakenTradepairData$) {
    krakenTradepairData$ = pipe(
      createKrakenGateway$,
      $.mergeMap(async (gateway) => {
        return gateway.getTradepairData()
      }),
      $.shareReplay()
    )
  }

  if (!openOrderFeedMessage$) {
    openOrderFeedMessage$ = pipe(
      selectedExchange$,
      $.withLatestFrom(products$),
      $.switchMap(([exchange, products]) => {
        return getOpenOrderFromExchange(exchange)
      }),
      //FOR THE LOVE OF BORSC DO NOT CHANGE THIS
      //It will double emit this stream
      $.shareReplay({ bufferSize: 0, refCount: true })
    )
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!userFeedMessage$) {

    openOrders = $.lastValueFrom(
      pipe(
        createCoinbaseGateway$,
        $.mergeMap(async (coinbaseGateway) => coinbaseGateway.getOpenOrders()),
      ),
    )

    openOrders.catch(() => setIsActiveAuth(false))

    userFeedMessage$ = pipe(
      selectedExchange$,
      $.withLatestFrom(products$),
      $.switchMap(([exchange, products]) => {
        return getUserFeedFromExchange(exchange, products)
      }),
      $.share()
    )

    balance$ = pipe(
      $.combineLatest([selectedExchange$, products$]),
      $.switchMap(([exchange, products]) => {
        return getBalanceFromExchange(exchange, products)
      }),
      // DO NOT CHANGE
      $.shareReplay({ bufferSize: 0, refCount: true })
    )

    gateway$ = pipe(
      selectedExchange$,
      $.switchMap((exchange) => {
        return getGatewayFromExchange(exchange)
      }),
      $.shareReplay()
    )

    orderBook$ = pipe(
      selectedExchange$,
      $.switchMap((exchange) => {
        return getOrderBookFromExchange(exchange)
      }),
      $.shareReplay(),
    )
  }

  /*
  * This is called twice because of the subscribeToCandles function
  * that calld getHistoricalCandles twice. Once to get the historical
  * and then second to get the last one to feed into the WebSocket stream.
  * This is not ideal, and will need to be updated in the future to avoid the
  * risk of being rate limited and slow load times for user.
  */
  function requestCandleStream() {
    return pipe(
      $.combineLatest([selectedTradepair$, selectedTimeframe$, selectedExchange$]),
      $.debounceTime(0),
      $.switchMap(([tradepair, timeframe, exchange]): $.Observable<CandleEvent> => {
        return pipe(
          $.of<CandleEvent>({ event: 'new series' }),
          $.concatWith(
            getCandleGatway(exchange, timeframe, tradepair)
          ),
        )
      }),
    )
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!candle$) {
    candle$ = pipe(
      requestCandleStream(),
      $.shareReplay(),
    )
  }

  // DISCUSS: currently filtering by trading_disabled, cancel_only, post_only, limit_only.
  // Should we still show these tradepairs but have the UI gray them out?
  useEffect(() => {
    global.localStorage.clear()

    const exchangeDataSubscription = pipe(
      $.combineLatest([products$, balance$]),
      $.map(([allProducts, _balance$]) => {
        match(allProducts)
          .exhaustive()
          .when(CoinbaseTradepairs.is, (product) => {
            setTradepairs(
              product
                .map((product) => product.id)
                .sort((a, b) => a.localeCompare(b)),
            )
            setIsLoading(false)
          })
          .when(KrakenTradepairs.is, (product) => {
            setTradepairs(
              product
                .map((product) => product.wsname)
                .sort((a, b) => a.localeCompare(b)),
            )
            setIsLoading(false)
          })
          .run()
      })
    ).subscribe({
      next: () => {
      },
      error: (err) => {
        console.log('Exchange data stream error', err)
      },
      complete: () => {
        console.info('Exchange data stream completed')
      }
    })


    return function cleanup() {
      exchangeDataSubscription.unsubscribe()
    }
  }, [])

  /**
   * The user has modified the size of of the webpage, so select the appropriate layout key.
   */
  function onBreakpointChange(breakpoint: string): void {
    setCurrentBreakpoint(breakpoint)
  }

  /**
   * Make a grid item visible.
   * Consider renaming this function when we're out of a refactor.
   */
  function onTakeItem(gridItem: Layout): void {
    setGridLayout(showGridItem(gridItem, gridLayout, currentBreakpoint))
  }

  /**
   * Hide a grid item
   * Consider renaming this function when we're out of a refactor.
   */
  function onPutItem(gridItem: Layout): void {
    setGridLayout(hideGridItem(gridItem, gridLayout, currentBreakpoint))
  }

  /**
   * Reset the grid layout to factory defaults.
   */
  function resetLayout(): void {
    setGridLayout(DEFAULT_GRID_LAYOUT)
    saveGridLayout(DEFAULT_GRID_LAYOUT)

    setConsoleHidden(false)
    setChartHidden(false)
    setEditorHidden(false)
    setOrderHidden(false)
  }

  /**
   * Because this handler is _only_ called when grid items in a layout are shifted
   * around, and not when grid items are hidden or made visible, we can overwrite
   * one-half of our GridLayout data structure without fear of violating our
   * invariants.
   */
  function onLayoutChange(newGridLayout: Layouts): void {
    // functional-fixup: use a lens
    setGridLayout({
      hidden: gridLayout.hidden,
      visible: newGridLayout,
    })
    saveGridLayout({
      hidden: gridLayout.hidden,
      visible: newGridLayout,
    })
  }

  return (
    <div>
      <Seo
        title={'Stratos | Terminal'}
        description={'Stratos trading terminal'}
        url={'https://www.stratos.trade'}
        keywords={['terminal', 'trading', 'augmented']}
      />
      <div
        className='alert alert-info alert-dismissible'
        role='alert'
        hidden={isActivePlan}
      >
        You do not have an active plan, please go to{' '}
        <Link className='alert-link' to='/settings'>Settings</Link>{' '}
        and activate a plan to begin trading.
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div
        className='alert alert-warning alert-dismissible'
        role='alert'
        hidden={isAuth}
      >
        Please go to <Link className='alert-link' to='/settings'>Settings</Link>{' '}
        and connect to an exchange.
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div
        className='alert alert-danger alert-dismissible'
        role='alert'
        hidden={isActiveAuth}
      >
        Your API Keys are incorrect, please go to{' '}
        <Link className='alert-link' to='/settings'>Settings</Link>{' '}
        and input new API Keys.
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <ResponsiveReactGridLayout
        className='layout react-grid-layout'
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={30}
        draggableHandle='header'
        layouts={gridLayout.visible}
        onLayoutChange={(_layout, layouts) => onLayoutChange(layouts)}
        onBreakpointChange={onBreakpointChange}
        compactType={'vertical'}
      >
        <div
          id={ChartKey}
          key={ChartKey}
          style={isChartHidden
            ? { display: 'none' }
            : { display: 'block' }}
        >
          <header className='grabbable gridHeaderPosition'>
            <span className='text-white'>
              <button
                className='btn btn-black btn-sm'
                onClick={(e) => {
                  setChartHidden(true)
                  onPutItem(
                    gridLayout.visible[currentBreakpoint].filter((item) =>
                      item.i === ChartKey,
                    )[0],
                  )
                }}
              >
                <i className='bi bi-x text-white'></i>
              </button>
              Chart
              <Select
                styles={customStyles}
                id='tradepairs'
                className='basic-single'
                classNamePrefix='select'
                isLoading={isLoading}
                isSearchable={true}
                name='tradepairs'
                options={tradepairs.map(selectItem)}
                onChange={(value) => {
                  if (
                    value !== null
                    && value !== undefined
                    && typeof value.value === 'string'
                    && value.value !== currentTradepair
                  ) {
                    stepComplete$.next({ type: 'stop', program: '' })
                    eraseProgrammaticPlots()
                    signalEvaluation()
                    selectedTradepair$.next(value.value)
                    setCurrentTradepair(value.value)
                  }
                }}
                value={selectItem(currentTradepair)}
              />
              {currentExchange === 'coinbase'
                ?
                <>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '1') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(60)
                        setCurrentTimeframe('1')
                      }
                    }}
                  >
                    1
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '5') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(300)
                        setCurrentTimeframe('5')
                      }
                    }}
                  >
                    5
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '15') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(900)
                        setCurrentTimeframe('15')
                      }
                    }}
                  >
                    15
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '1H') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(3600)
                        setCurrentTimeframe('1H')
                      }
                    }}
                  >
                    1H
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '6H') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(21600)
                        setCurrentTimeframe('6H')
                      }
                    }}
                  >
                    6H
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '1D') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(86400)
                        setCurrentTimeframe('1D')
                      }
                    }}
                  >
                    1D
                  </button>
                </>
                : <>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '1') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(1)
                        setCurrentTimeframe('1')
                      }
                    }}
                  >
                    1
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '5') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(5)
                        setCurrentTimeframe('5')
                      }
                    }}
                  >
                    5
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '15') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(15)
                        setCurrentTimeframe('15')
                      }
                    }}
                  >
                    15
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '30') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(30)
                        setCurrentTimeframe('30')
                      }
                    }}
                  >
                    30
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '1H') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(60)
                        setCurrentTimeframe('1H')
                      }
                    }}
                  >
                    1H
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '4H') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(240)
                        setCurrentTimeframe('4H')
                      }
                    }}
                  >
                    4H
                  </button>
                  <button
                    className='btn btn-black btn-sm text-white'
                    onClick={() => {
                      if (currentTimeframe !== '1D') {
                        stepComplete$.next({ type: 'stop', program: '' })
                        eraseProgrammaticPlots()
                        signalEvaluation()
                        selectedTimeframe$.next(1440)
                        setCurrentTimeframe('1D')
                      }
                    }}
                  >
                    1D
                  </button>
                </>
              }
            </span>
          </header>
          <Chart
            candles={candle$}
            tradepair={currentTradepair}
            timeframe={currentTimeframe}
          />
        </div>
        <div
          id={ConsoleKey}
          key={ConsoleKey}
          style={isConsoleHidden
            ? { display: 'none' }
            : { display: 'block' }}
        >
          <header className='grabbable gridHeaderPosition'>
            <span className='text-white'>
              <button
                className='btn btn-black btn-sm'
                onClick={(e) => {
                  setConsoleHidden(true)
                  onPutItem(
                    gridLayout.visible[currentBreakpoint].filter((item) =>
                      item.i === ConsoleKey,
                    )[0],
                  )
                }}
              >
                <i className='bi bi-x text-white'></i>
              </button>
              Console
              <i className='bi bi-terminal ml-2'></i>
            </span>
          </header>
          <PyodideOutput />
        </div>
        <div
          id={OrdersKey}
          key={OrdersKey}
          style={isOrderHidden
            ? { display: 'none' }
            : { display: 'block' }}
        >
          <header className='grabbable gridHeaderPosition'>
            <span className='text-white'>
              <button
                className='btn btn-black btn-sm'
                onClick={(e) => {
                  setOrderHidden(true)
                  onPutItem(
                    gridLayout.visible[currentBreakpoint].filter((item) =>
                      item.i === OrdersKey,
                    )[0],
                  )
                }}
              >
                <i className='bi bi-x text-white'></i>
              </button>
              Orders
            </span>
          </header>
          <Orders
            coinbaseUserFeedMessage$={userFeedMessage$}
            krakenOpenOrderFeedMessage$={$.merge(openOrderFeedMessage$, userFeedMessage$)}
            openOrders={openOrders}
            currentTradepair={currentTradepair}
            selectedExchange$={selectedExchange$}
          />
        </div>
        <div
          id={EditorKey}
          key={EditorKey}
          style={isEditorHidden
            ? { display: 'none' }
            : { display: 'block' }}
        >
          <header className='grabbable gridHeaderPosition'>
            <span className='text-white'>
              <button
                className='btn btn-black btn-sm'
                onClick={(e) => {
                  setEditorHidden(true)
                  onPutItem(
                    gridLayout.visible[currentBreakpoint].filter((item) =>
                      item.i === EditorKey,
                    )[0],
                  )
                }}
              >
                <i className='bi bi-x text-white'></i>
              </button>
              Editor
            </span>
          </header>
          <PyodideEditor
            candle$={candle$}
            orderBook$={orderBook$}
            balance$={balance$}
            gateway={gateway$}
            stepComplete$={stepComplete$}
            selectedTradepair$={selectedTradepair$}
            selectedExchange$={selectedExchange$}
            products$={products$}
          />
        </div>
        <div id='1' key='1'>
          <header className='grabbable gridHeaderPosition'>
            <Select
              styles={customStyles}
              id='exchanges'
              className='basic-single mr1'
              classNamePrefix='select'
              isLoading={isLoading}
              isDisabled={isLoading}
              isOptionDisabled={(option) => option.value === 'kraken'}
              isSearchable={true}
              name='exchanges'
              options={exchanges.map(selectItem)}
              onChange={(value) => {
                if (
                  value !== null
                  && value !== undefined
                  && typeof value.value === 'string'
                  && value.value !== currentExchange
                ) {
                  stepComplete$.next({ type: 'stop', program: '' })
                  eraseProgrammaticPlots()
                  signalEvaluation()
                  selectedExchange$.next(value.value as Exchange)
                  setCurrentExchange(value.value)
                }
              }}
              value={selectItem(currentExchange)}
            />
          </header>
          <AvailableBalance
            balance$={balance$}
            currentTradepair={currentTradepair}
            products={products$}
            tradepairData={krakenTradepairData$}
          />
          <div className='editorButtons'>
            <div style={{ display: 'inline' }} className='dropdown'>
              <button
                className='btn btn-black btn-sm dropdown'
                id='toggle-button'
              >
                <i className='bi bi-grid-fill mr-1 text-white'></i>
              </button>
              <div
                className='dropdown-menu dropdown-menu-right text-white'
                aria-labelledby='dropdownMenuButton'
                style={{ backgroundColor: 'black' }}
              >
                <h6 className='dropdown-header text-white'>Grid Display</h6>
                <div className='form-check'>
                  <input
                    className='ml-1 cursor'
                    type='checkbox'
                    name='chart'
                    id='chart'
                    checked={!isChartHidden}
                    onClick={() => {
                      if (isChartHidden) {
                        setChartHidden(false)
                        onTakeItem(
                          gridLayout.hidden[currentBreakpoint].filter(
                            (item) => item.i === ChartKey,
                          )[0],
                        )
                      } else {
                        setChartHidden(true)
                        onPutItem(
                          gridLayout.visible[currentBreakpoint].filter(
                            (item) => item.i === ChartKey,
                          )[0],
                        )
                      }
                    }}
                  />
                  <label className='ml-2'>
                    Chart
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    className='ml-1 cursor'
                    type='checkbox'
                    name='editor'
                    id='editor'
                    checked={!isEditorHidden}
                    onClick={() => {
                      if (isEditorHidden) {
                        setEditorHidden(false)
                        onTakeItem(
                          gridLayout.hidden[currentBreakpoint].filter(
                            (item) => item.i === EditorKey,
                          )[0],
                        )
                      } else {
                        setEditorHidden(true)
                        onPutItem(
                          gridLayout.visible[currentBreakpoint].filter(
                            (item) => item.i === EditorKey,
                          )[0],
                        )
                      }
                    }}
                  />
                  <label className='ml-2'>
                    Editor
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    className='ml-1 cursor'
                    type='checkbox'
                    name='console'
                    id='console'
                    checked={!isConsoleHidden}
                    onClick={() => {
                      if (isConsoleHidden) {
                        setConsoleHidden(false)
                        onTakeItem(
                          gridLayout.hidden[currentBreakpoint].filter(
                            (item) => item.i === ConsoleKey,
                          )[0],
                        )
                      } else {
                        setConsoleHidden(true)
                        onPutItem(
                          gridLayout.visible[currentBreakpoint].filter(
                            (item) => item.i === ConsoleKey,
                          )[0],
                        )
                      }
                    }}
                  />
                  <label className='ml-2'>Console</label>
                </div>
                <div className='form-check'>
                  <input
                    className='ml-1 cursor'
                    type='checkbox'
                    name='orders'
                    id='orders'
                    checked={!isOrderHidden}
                    onClick={() => {
                      if (isOrderHidden) {
                        setOrderHidden(false)
                        onTakeItem(
                          gridLayout.hidden[currentBreakpoint].filter(
                            (item) => item.i === OrdersKey,
                          )[0],
                        )
                      } else {
                        setOrderHidden(true)
                        onPutItem(
                          gridLayout.visible[currentBreakpoint].filter(
                            (item) => item.i === OrdersKey,
                          )[0],
                        )
                      }
                    }}
                  />
                  <label className='ml-2'>
                    Orders
                  </label>
                </div>
                <div className='form-check'>
                  <button
                    className='btn btn-outline-secondary text-white'
                    onClick={() => resetLayout()}
                  >
                    Reset Layout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ResponsiveReactGridLayout>
      <OrderNotification />
      <div className="header-card">
        <h1>This is a demo website and not a product for purchase or use</h1>
      </div>
    </div>
  )
}
