import * as t from 'io-ts'
import { DateFromUnixTime, NumberFromString } from 'io-ts-types'
import { BigFromString } from '../../BigCodec'


/**
 * A subscription message is sent to acknowledge a successfully-opened websocket.
 *
 * @example
 * {
 * "connectionID":13653225047018299389,
 * "event":"systemStatus",
 * "status":"online",
 * "version":"1.9.0"
 * }
 */
export const KrakenConnectionMessage = t.type(
    {
        event: t.literal('systemStatus'),
    },
    'KrakenConnectionMessage',
)
export type KrakenConnectionMessage = t.TypeOf<typeof KrakenConnectionMessage>


/**
 * A subscription message is sent to acknowledge a successfully-opened websocket.
 *
 * @example
 * {
 * "channelID":344,
 * "channelName":"ohlc-5",
 * "event":"subscriptionStatus",
 * "pair":"XBT/USD",
 * "status":"subscribed",
 * "subscription":{"interval":5,"name":"ohlc"}
 * }
 */
export const KrakenSubscriptionMessage = t.type(
    {
        event: t.literal('subscriptionStatus'),
    },
    'KrakenSubscriptionMessage',
)
export type KrakenSubscriptionMessage = t.TypeOf<typeof KrakenSubscriptionMessage>

/**
 *
 * @example
 * {
 * "event":"heartbeat"
 * }
 */
export const KrakenHeartbeatMessage = t.type(
    {
        event: t.literal('heartbeat'),
    },
    'KrakenHeartbeatMessage',
)
export type KrakenHeartbeatMessage = t.TypeOf<typeof KrakenHeartbeatMessage>

/** *****************************************************************************
 * Messages on the Ticker websocket
 * **************************************************************************** */

/**
 * @example
[
  42,
  [
    "1542057314.748456",
    "1542057360.435743",
    "3586.70000",
    "3586.70000",
    "3586.60000",
    "3586.60000",
    "3586.68894",
    "0.03373000",
    2
  ],
  "ohlc-5",
  "XBT/USD"
]
 */

export const KrakenTickerMessage = t.array(
    t.union([
        t.number,
        t.string,
        t.array(t.union([t.number, t.string]))
    ]),
    'KrakenTickerMessage',
)

export type KrakenTickerMessage = t.TypeOf<typeof KrakenTickerMessage>

export const KrakenTickerWebsocketMessage = t.union(
    [
        KrakenTickerMessage,
        KrakenHeartbeatMessage,
        KrakenSubscriptionMessage,
        KrakenConnectionMessage
    ],
    'KrakenTickerWebsocketMessage',
)
export type KrakenTickerWebsocketMessage = t.TypeOf<
    typeof KrakenTickerWebsocketMessage
>

export const TickerMessage = t.type({
    time: DateFromUnixTime,
    etime: DateFromUnixTime,
    open: NumberFromString,
    high: NumberFromString,
    low: NumberFromString,
    close: NumberFromString,
    vwap: NumberFromString,
    volume: NumberFromString,
    count: t.number
},
    'TickerMessage',
)

export type TickerMessage = t.TypeOf<typeof TickerMessage>

// Example KrakenOpenOrderMessage

/**
 * @example
 * [
    [
        {
            "OSFBL7-2FBMF-XUHSRE":
            {
                "avg_price": "0.00000",
                "cost": "0.00000",
                "descr":
                {
                    "close": null,
                    "leverage": null,
                    "order": "buy 0.00100000 XBT/USDT @ limit 19600.00000",
                    "ordertype": "limit",
                    "pair": "XBT/USDT",
                    "price": "19600.00000",
                    "price2": "0.00000",
                    "type": "buy"
                },
                "expiretm": null,
                "fee": "0.00000",
                "limitprice": "0.00000",
                "misc": "",
                "oflags": "fciq,post",
                "opentm": "1674677290.408245",
                "refid": null,
                "starttm": null,
                "status": "open",
                "stopprice": "0.00000",
                "timeinforce": "GTC",
                "userref": 0,
                "vol": "0.00100000",
                "vol_exec": "0.00000000"
            }
        }], "openOrders", { "sequence": 1 }
]
 */

export const KrakenOpenOrderMessage = t.tuple([
    t.array(t.record(
        t.string,
        t.type({
            cost: BigFromString,
            fee: BigFromString,
            limitprice: BigFromString,
            status: t.union([t.literal('open'), t.literal('pending')]),
            vol: BigFromString,
            vol_exec: BigFromString,
            descr: t.type({
                ordertype: t.literal('limit'),
                pair: t.string,
                type: t.union([t.literal('buy'), t.literal('sell')]),
                price: BigFromString
            })
        })
    )),
    t.literal('openOrders'),
    t.type({
        sequence: t.number
    })
],
    'KrakenOpenOrderMessage',
)

export type KrakenOpenOrderMessage = t.TypeOf<typeof KrakenOpenOrderMessage>

/**
 * @example market open order
 * [[{"OCU6LZ-ZHTAO-DF7WVK":{"avg_price":"0.00000","cost":"0.00000","descr":{"close":null,"leverage":null,"order":"buy 0.00010177 XBT/USDT @ market 0.00000","ordertype":"market","pair":"XBT/USDT","price":"0.00000","price2":"0.00000","type":"buy"},"expiretm":null,"fee":"0.00000","limitprice":"0.00000","misc":"","oflags":"fciq","opentm":"1675607794.606399","refid":null,"starttm":null,"status":"pending","stopprice":"0.00000","timeinforce":"GTC","userref":0,"vol":"0.00010177","vol_exec":"0.00000000"}}],"openOrders",{"sequence":2}]
 */
export const KrakenMarketOpenOrderMessage = t.tuple([
    t.array(t.record(
        t.string,
        t.type({
            cost: BigFromString,
            fee: BigFromString,
            limitprice: BigFromString,
            status: t.union([t.literal('open'), t.literal('pending')]),
            vol: BigFromString,
            vol_exec: BigFromString,
            descr: t.type({
                ordertype: t.literal('market'),
                pair: t.string,
                type: t.union([t.literal('buy'), t.literal('sell')]),
                price: BigFromString
            })
        })
    )),
    t.literal('openOrders'),
    t.type({
        sequence: t.number
    })
],
    'KrakenMarketOpenOrderMessage',
)

export type KrakenMarketOpenOrderMessage = t.TypeOf<typeof KrakenMarketOpenOrderMessage>


/**
 * @example
 * [[{"OQWNIS-XSLUH-KK532W":{"status":"open","userref":0}}],"openOrders",{"sequence":4}]
 */

export const KrakenConfirmOpenMessage = t.tuple([
    t.array(t.record(
        t.string,
        t.type({
            status: t.literal('open'),
        })
    )),
    t.literal('openOrders'),
    t.type({
        sequence: t.number
    })
],
    'KrakenConfirmOpenMessage',
)

export type KrakenConfirmOpenMessage = t.TypeOf<typeof KrakenConfirmOpenMessage>

/**
 * @example market order filled
 * [[{"OCU6LZ-ZHTAO-DF7WVK":{"vol_exec":"0.00010177","cost":"2.36106","fee":"0.00614","avg_price":"23199.96070","userref":0}}],"openOrders",{"sequence":4}]
 */
export const KrakenFilledOrderMessage = t.tuple([
    t.array(t.record(
        t.string,
        t.type({
            vol_exec: BigFromString,
            cost: BigFromString,
            fee: BigFromString,
            avg_price: BigFromString,
        })
    )),
    t.literal('openOrders'),
    t.type({
        sequence: t.number
    })
],
    'KrakenFilledOrderMessage',
)

export type KrakenFilledOrderMessage = t.TypeOf<typeof KrakenFilledOrderMessage>


/**
 * @example market order closed and filled (not manually canceled)
 * [[{"OCU6LZ-ZHTAO-DF7WVK":{"lastupdated":"1675607794.607105","status":"closed","vol_exec":"0.00010177","cost":"2.36106","fee":"0.00614","avg_price":"23199.96070","userref":0}}],"openOrders",{"sequence":5}]
 */

export const KrakenClosedOrderMessage = t.tuple([
    t.array(t.record(
        t.string,
        t.type({
            status: t.union([t.literal('closed'), t.literal('canceled')]),
        })
    )),
    t.literal('openOrders'),
    t.type({
        sequence: t.number
    })
],
    'KrakenClosedOrderMessage',
)

export type KrakenClosedOrderMessage = t.TypeOf<typeof KrakenClosedOrderMessage>

/**
 * @example this is a market order
 * [[{"TEJ7XF-JXN3K-EJW2RA":{"cost":"2.36106","fee":"0.00614","margin":"0.00000","ordertxid":"OCU6LZ-ZHTAO-DF7WVK","ordertype":"market","pair":"XBT/USDT","postxid":"TKH2SE-M7IF5-CFI7LT","price":"23200.00000","time":"1675607794.607094","type":"buy","vol":"0.00010177"}}],"ownTrades",{"sequence":2}]
 */

export const KrakenTradeMessage = t.tuple([
    t.array(t.record(
        t.string,
        t.type({
            cost: BigFromString,
            fee: BigFromString,
            ordertxid: t.string,
            ordertype: t.string,
            pair: t.string,
            postxid: t.string,
            price: BigFromString,
            type: t.union([t.literal('buy'), t.literal('sell')]),
            vol: BigFromString
        })
    )),
    t.literal('ownTrades'),
    t.type({
        sequence: t.number
    })
],
    'KrakenTradeMessage',
)

export type KrakenTradeMessage = t.TypeOf<typeof KrakenTradeMessage>

export const KrakenFirstTradeMessage = t.tuple([
    t.array(t.record(
        t.string,
        t.type({
            cost: BigFromString,
            fee: BigFromString,
            ordertxid: t.string,
            ordertype: t.string,
            pair: t.string,
            postxid: t.string,
            price: BigFromString,
            type: t.union([t.literal('buy'), t.literal('sell')]),
            vol: BigFromString
        })
    )),
    t.literal('ownTrades'),
    t.type({
        sequence: t.literal(1)
    })
],
    'KrakenFirstTradeMessage',
)

export type KrakenFirstTradeMessage = t.TypeOf<typeof KrakenFirstTradeMessage>

export const KrakenUserWebsocketMessage = t.union(
    [
        KrakenHeartbeatMessage,
        KrakenSubscriptionMessage,
        KrakenConnectionMessage,
        KrakenFirstTradeMessage,
        KrakenTradeMessage,
    ],
    'KrakenUserWebsocketMessage',
)
export type KrakenUserWebsocketMessage = t.TypeOf<
    typeof KrakenUserWebsocketMessage
>

export const KrakenOpenOrderWebsocketMessage = t.union(
    [
        KrakenHeartbeatMessage,
        KrakenClosedOrderMessage,
        KrakenConnectionMessage,
        KrakenSubscriptionMessage,
        KrakenOpenOrderMessage,
        KrakenMarketOpenOrderMessage,
        KrakenFilledOrderMessage,
        KrakenClosedOrderMessage,
        KrakenConfirmOpenMessage
    ],
    'KrakenOpenOrderWebsocketMessage',
)
export type KrakenOpenOrderWebsocketMessage = t.TypeOf<
    typeof KrakenOpenOrderWebsocketMessage
>

/** *****************************************************************************
 * Messages on the private websocket for delete order response
 * **************************************************************************** */

/**
 *
 * @example
 * {
 * "event":"cancelOrderStatus"
 * "errorMessage": "EOrder:Unknown order",
 * "status": "error"
 * }
 */
export const KrakenDeleteOrderErrorMessage = t.type(
    {
        event: t.literal('cancelOrderStatus'),
        status: t.literal("error")
    },
    'KrakenDeleteOrderErrorMessage',
)
export type KrakenDeleteOrderErrorMessage = t.TypeOf<typeof KrakenDeleteOrderErrorMessage>

/**
 *
 * @example
 * {
 * "event":"cancelOrderStatus"
 * "status": "ok"
 * }
 */
export const KrakenDeleteOrderSuccessMessage = t.type(
    {
        event: t.literal('cancelOrderStatus'),
        status: t.literal("ok")
    },
    'KrakenDeleteOrderSuccessMessage',
)
export type KrakenDeleteOrderSuccessMessage = t.TypeOf<typeof KrakenDeleteOrderSuccessMessage>


export const KrakenDeleteOrderResponseWebsocketMessage = t.union(
    [
        KrakenDeleteOrderErrorMessage,
        KrakenDeleteOrderSuccessMessage
    ],
    'KrakenDeleteOrderResponseWebsocketMessage',
)
export type KrakenDeleteOrderResponseWebsocketMessage = t.TypeOf<
    typeof KrakenDeleteOrderResponseWebsocketMessage
>

/** *****************************************************************************
 * Messages on the private websocket for add order response
 * **************************************************************************** */

/**
 *
 * @example
 * {
  "errorMessage": "EOrder:Order minimum not met",
  "event": "addOrderStatus",
  "status": "error"
}
 */
export const KrakenAddOrderErrorMessage = t.type(
    {
        event: t.literal('addOrderStatus'),
        status: t.literal("error")
    },
    'KrakenAddOrderErrorMessage',
)
export type KrakenAddOrderErrorMessage = t.TypeOf<typeof KrakenAddOrderErrorMessage>

/**
 *
 * @example
 * {
  "descr": "buy 0.01770000 XBTUSD @ limit 4000",
  "event": "addOrderStatus",
  "status": "ok",
  "txid": "ONPNXH-KMKMU-F4MR5V"
}
 */

/**
 * @example market
 * {"descr":"buy 0.00010177 XBTUSDT @ market","event":"addOrderStatus","status":"ok","txid":"OCU6LZ-ZHTAO-DF7WVK"}
 */

export const KrakenAddOrderSuccessMessage = t.type(
    {
        event: t.literal('addOrderStatus'),
        status: t.literal("ok")
    },
    'KrakenDeleteOrderSuccessMessage',
)
export type KrakenAddOrderSuccessMessage = t.TypeOf<typeof KrakenAddOrderSuccessMessage>


export const KrakenAddOrderResponseWebsocketMessage = t.union(
    [
        KrakenAddOrderErrorMessage,
        KrakenAddOrderSuccessMessage
    ],
    'KrakenAddOrderResponseWebsocketMessage',
)
export type KrakenAddOrderResponseWebsocketMessage = t.TypeOf<
    typeof KrakenAddOrderResponseWebsocketMessage
>

/**
 * @example order of market messages
//  * const test1d = {"descr":"buy 0.00010177 XBTUSDT @ market","event":"addOrderStatus","status":"ok","txid":"OCU6LZ-ZHTAO-DF7WVK"}
const test2d = [[{"OCU6LZ-ZHTAO-DF7WVK":{"avg_price":"0.00000","cost":"0.00000",
"descr":{"close":null,"leverage":null,"order":"buy 0.00010177 XBT/USDT @ market 0.00000","ordertype":"market","pair":"XBT/USDT","price":"0.00000","price2":"0.00000","type":"buy"},"expiretm":null,"fee":"0.00000","limitprice":"0.00000","misc":"","oflags":"fciq","opentm":"1675607794.606399","refid":null,"starttm":null,"status":"pending","stopprice":"0.00000","timeinforce":"GTC","userref":0,"vol":"0.00010177","vol_exec":"0.00000000"}}],"openOrders",{"sequence":2}]
const test3d = [[{"OCU6LZ-ZHTAO-DF7WVK":{"avg_price":"0.00000","cost":"0.00000","descr":{"close":null,"leverage":null,"order":"buy 0.00010177 XBT/USDT @ market 0.00000","ordertype":"market","pair":"XBT/USDT","price":"0.00000","price2":"0.00000","type":"buy"},"expiretm":null,"fee":"0.00000","limitprice":"0.00000","misc":"","oflags":"fciq","opentm":"1675607794.606399","refid":null,"starttm":null,"status":"pending","stopprice":"0.00000","timeinforce":"GTC","userref":0,"vol":"0.00010177","vol_exec":"0.00000000"}}],"openOrders",{"sequence":2}]
const test4d = [[{"OCU6LZ-ZHTAO-DF7WVK":{"status":"open","userref":0}}],"openOrders",{"sequence":3}]
const test5 = [[{"OCU6LZ-ZHTAO-DF7WVK":{"vol_exec":"0.00010177","cost":"2.36106","fee":"0.00614","avg_price":"23199.96070","userref":0}}],"openOrders",{"sequence":4}]
const test6d = [[{"OCU6LZ-ZHTAO-DF7WVK":{"lastupdated":"1675607794.607105","status":"closed","vol_exec":"0.00010177","cost":"2.36106","fee":"0.00614","avg_price":"23199.96070","userref":0}}],"openOrders",{"sequence":5}]
const test7d = [[{"TEJ7XF-JXN3K-EJW2RA":{"cost":"2.36106","fee":"0.00614","margin":"0.00000","ordertxid":"OCU6LZ-ZHTAO-DF7WVK","ordertype":"market","pair":"XBT/USDT","postxid":"TKH2SE-M7IF5-CFI7LT","price":"23200.00000","time":"1675607794.607094","type":"buy","vol":"0.00010177"}}],"ownTrades",{"sequence":2}]

 */