import React from 'react'
import { Observable } from 'rxjs'
import { match } from 'ts-pattern'
import * as $ from 'rxjs'
import { FilledOrder, OpenOrder } from '../gateway'
import { CoinbaseOrders } from '../gateway/coinbase/rest/codecs'
import {
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
} from '../gateway/coinbase/websocket/codecs'
import { KrakenClosedOrderMessage, KrakenConfirmOpenMessage, KrakenFilledOrderMessage, KrakenOpenOrderMessage, KrakenTradeMessage, KrakenUserWebsocketMessage } from '../gateway/kraken/websocket/codecs'
import { useStore } from '../store'
import { Exchange } from '../types/index'
import { pipe } from 'fp-ts/function'

function showHide(divId: string) {
  const div = document.getElementById(divId)
  if (divId === 'open') {
    div?.classList.replace('btn-outline-secondary', 'btn-outline-default')
    document.getElementById('fills')?.classList.replace(
      'btn-outline-default',
      'btn-outline-secondary',
    )
  } else {
    div?.classList.replace('btn-outline-secondary', 'btn-outline-default')
    document.getElementById('open')?.classList.replace(
      'btn-outline-default',
      'btn-outline-secondary',
    )
  }
}

export function Orders(props: {
  coinbaseUserFeedMessage$: Observable<
    CoinbaseOrderDoneMessage | CoinbaseOrderMatchMessage | CoinbaseOrderOpenMessage | KrakenUserWebsocketMessage
  >
  krakenOpenOrderFeedMessage$: Observable<
    KrakenOpenOrderMessage | KrakenClosedOrderMessage | KrakenUserWebsocketMessage | KrakenFilledOrderMessage |CoinbaseOrderDoneMessage | CoinbaseOrderMatchMessage | CoinbaseOrderOpenMessage
  >
  openOrders: Promise<CoinbaseOrders>
  currentTradepair: string
  selectedExchange$: $.BehaviorSubject<Exchange>
}) {
  const openOrders = useStore((state) => state.orders)
  const setOpenOrders = useStore((state) => state.addToOrders)
  const [filledOrders, setFilledOrders] = React.useState<FilledOrder[]>([])
  const [isOrderOpenDisplay, setOrderOpenDisplay] = React.useState(true)

  function matchKrakenOrder(
    order:
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
      | CoinbaseOrderOpenMessage
      | KrakenOpenOrderMessage
      | KrakenClosedOrderMessage
      | KrakenConfirmOpenMessage
      | KrakenUserWebsocketMessage
      | KrakenTradeMessage
      | KrakenFilledOrderMessage
  ) {
    match(order)
      .exhaustive()
      .when(KrakenOpenOrderMessage.is, (openKrakenOrders) => {
        openKrakenOrders[0].map(openOrder => {
          for (const [id, open] of Object.entries(openOrder)) {
            setOpenOrders((prevOrders) => {
              return [...prevOrders, {
                side: open.descr.type,
                size: open.vol.toNumber(),
                filledQuantity: open.vol_exec.toNumber(),
                price: open.descr.price.toNumber(),
                status: 'open',
                ID: id,
                productID: open.descr.pair,
              }]
            })
          }
        })
      })
      .when(KrakenTradeMessage.is, (trades) => {
        trades[0].map(trade => {
          Object.values(trade).map((incomingTrade) => {
            if (incomingTrade.ordertype === "market") {

              setFilledOrders((prevFilled) => [...prevFilled, {
                side: incomingTrade.type,
                size: incomingTrade.vol.toNumber(),
                price: incomingTrade.price.toNumber(),
                status: 'done',
                productID: incomingTrade.pair
              }])

            }
          })
        })
      })
      .when(KrakenFilledOrderMessage.is, (filledOrders) => {
        // Just limit orders, market orders will be handles with KrakenTradeMessage
        filledOrders[0].map(filled => {
          for (const [id, match] of Object.entries(filled)) {

            setOpenOrders((prevOrder) =>
              prevOrder.map((order) => {
                if (order.ID === id) {
                  //I think this is vol_exec
                  order.filledQuantity = order.filledQuantity + match.vol_exec.toNumber()

                  setFilledOrders((prevFilled) => [...prevFilled, {
                    side: order.side,
                    size: match.vol_exec.toNumber(),
                    price: match.avg_price.toNumber(),
                    status: 'done',
                    productID: order.productID,
                  }])

                }
                return order
              }),
            )
            return
          }
        })

      })
      .when(KrakenClosedOrderMessage.is, (doneOrders) => {
        doneOrders[0].map(doneOrder => {
          for (const [id, doneStatus] of Object.entries(doneOrder)) {
            setOpenOrders((prevOrders) =>
              prevOrders.filter((item) => item.ID !== id),
            )
          }
        })
      })
      .when(KrakenUserWebsocketMessage.is, (message) => {
        return
      })
      .when(KrakenConfirmOpenMessage.is, (message) => {
        return
      })
      .when(CoinbaseOrderOpenMessage.is, (message) => {
        return
      })
      .when(CoinbaseOrderMatchMessage.is, (message) => {
        return
      })
      .when(CoinbaseOrderDoneMessage.is, (message) => {
        return
      })
      .run()
  }

  function matchCoinbaseOrder(
    order:
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
      | CoinbaseOrderOpenMessage
      | KrakenOpenOrderMessage
      | KrakenClosedOrderMessage
      | KrakenUserWebsocketMessage
  ) {
    match(order)
      .exhaustive()
      .when(CoinbaseOrderOpenMessage.is, (open) => {
        setOpenOrders((prevOrders) => [...prevOrders, {
          side: open.side,
          size: open.remaining_size.toNumber(),
          filledQuantity: 0,
          price: open.price.toNumber(),
          status: open.type,
          ID: open.order_id,
          productID: open.product_id.product,
        }])
      })
      .when(CoinbaseOrderMatchMessage.is, (match) => {
        // This message describes the user's market order to show in filled
        // The match side is the opposite of posted
        if (match.taker_user_id === match.user_id) {
          setFilledOrders((prevFilled) => [...prevFilled, {
            /*
             * The side field indicates the maker order side.
             * If the side is sell this indicates the maker
             * was a sell order and the match is considered an up-tick. A buy side match is a down-tick.
            */
            side: match.side === 'buy' ? 'sell' : 'buy',
            size: match.size.toNumber(),
            price: match.price.toNumber(),
            status: 'done',
            productID: match.product_id.product,
          }])
          return
        }

        // Limit orders
        if (match.maker_user_id === match.user_id) {
          setFilledOrders((prevFilled) => [...prevFilled, {
            /*
             * The side field indicates the maker order side.
             * If the side is sell this indicates the maker
             * was a sell order and the match is considered an up-tick. A buy side match is a down-tick.
            */
            side: match.side,
            size: match.size.toNumber(),
            price: match.price.toNumber(),
            status: 'done',
            productID: match.product_id.product,
          }])
        }

        // Only operate on the user's limit orders
        const matchedOrderID = match.maker_order_id
        // TODO: check to make sure this updating of limit orders is workig properly
        // could be different for base and quote orders (bid and ask)
        // I believe all standing orders express size in units of
        // base currency, so we can do simple addition and subtraction
        setOpenOrders((prevOrder) =>
          prevOrder.map((order) => {
            if (order.ID === matchedOrderID) {
              order.filledQuantity = order.filledQuantity + match.size.toNumber()
            }
            return order
          }),
        )
        return
      })
      .when(CoinbaseOrderDoneMessage.is, (done) => {
        const price = done.price?.toNumber()
        if (price === undefined) {
          // we are looking at a market order that was never
          // in the limit order book
          return
        }
        setOpenOrders((prevOrders) =>
          prevOrders.filter((item) => item.ID !== done.order_id),
        )
      })
      .when(KrakenClosedOrderMessage.is, (message) => {
        return
      })
      .when(KrakenOpenOrderMessage.is, (message) => {
        return
      })
      .when(KrakenUserWebsocketMessage.is, (message) => {
        return
      })
      .run()
  }

  React.useEffect(() => {
    //Get the correct order feed
    const orderFeed$ = pipe(
      props.selectedExchange$,
      $.switchMap((exchange) => {
        switch (exchange) {
          case 'coinbase': {
            props.openOrders.then((orders) => {
              const currentOrders = orders.data
                .map((data) => {
                  const order: OpenOrder = {
                    side: data.side,
                    size: data.size.toNumber(),
                    filledQuantity: data.filled_size.toNumber(),
                    price: data.price.price.toNumber(),
                    status: data.status,
                    ID: data.id,
                    productID: data.product_id.product,
                  }
                  return order
                })
              setOpenOrders(() => currentOrders)
            })
            return pipe(
              props.coinbaseUserFeedMessage$,
              $.map((message) => {
                return matchCoinbaseOrder(message)
              })
            )
          }
          case 'kraken': {
            setOpenOrders((): OpenOrder[] => [])
            return pipe(
              props.krakenOpenOrderFeedMessage$,
              $.map((message) => {
                return matchKrakenOrder(message)
              })
            )
          }
        }
      })
    )

    //Match the orders
    const orderBookSubscription = orderFeed$.subscribe({
      next: () => {
      },
      error: (err) => {
        console.log('Order book data stream error', err)
      },
      complete: () => {
        console.info('Order book data stream completed')
      }
    })

    return function cleanup() {
      orderBookSubscription.unsubscribe()
    }
  }, [])

  return (
    <div>
      <div className='editorButtons'>
        <button
          id='open'
          className='btn btn-outline-default btn-sm'
          onClick={() => {
            showHide('open')
            setOrderOpenDisplay(true)
          }}
        >
          Open
        </button>
        <button
          id='fills'
          className='btn btn-outline-secondary btn-sm'
          onClick={() => {
            showHide('fills')
            setOrderOpenDisplay(false)
          }}
        >
          Fills
        </button>
      </div>
      <div className='monaco'>
        <div
          className='chart behind orders'
          id='output'
        >
          {isOrderOpenDisplay
            ? (
              <table>
                <tr>
                  <th>Side</th>
                  <th>Size</th>
                  <th>Filled</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
                {openOrders?.filter((value) =>
                  value.productID === props.currentTradepair,
                )
                  .reverse()
                  .map((value, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ color: value.side === 'buy' ? 'green' : 'red' }}>
                          {value.side.toUpperCase()}
                        </td>
                        <td>{value.size}</td>
                        <td>{value.filledQuantity}</td>
                        <td>{value.price}</td>
                        <td>{value.status}</td>
                      </tr>
                    )
                  })}
              </table>
            )
            : (
              <table>
                <tr>
                  <th>Side</th>
                  <th>Size</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
                {filledOrders?.filter((value) =>
                  value.productID === props.currentTradepair,
                )
                  .reverse()
                  .map((value, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ color: value.side === 'buy' ? 'green' : 'red' }}>
                          {value.side.toUpperCase()}
                        </td>
                        <td>{value.size}</td>
                        <td>{value.price}</td>
                        <td>{value.status}</td>
                      </tr>
                    )
                  })}
              </table>
            )}
        </div>
      </div>
    </div>
  )
}
