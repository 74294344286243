import { match } from 'ts-pattern'

import {
  EventLogEvent,
  isUserSignalEventGroup,
  OrdersRequestedEvent,
  UserSignalEventGroup,
} from './event-log'

export function last(array: readonly EventLogEvent[]) {
  return array[array.length - 1]
}

/**
 * Remove the first element strictly matching the provided `reference` from `array`.
 */
const removeGroupedElements = <T>(
  array: T[],
  eventRequest: T,
  eventResponse: T,
): void => {
  for (let i = 0; i < array.length; ++i) {
    // You can't compary objects so you have to stringify them, is this gross Eric?
    if (
      JSON.stringify(array[i]) === JSON.stringify(eventRequest)
      || array[i] === eventResponse
    ) {
      array.splice(i, 1)
      return
    }
  }
}

/**
 * Remove the first element and every element strictly matching the provided `reference` from `array`.
 */
const removeElement = <T>(array: T[], reference: T): void => {
  for (let i = 0; i < array.length; ++i) {
    if (array[i] === reference) {
      array.splice(i, 1)
      return
    }
  }
}

export function getCurrentState(
  eventlog: EventLogEvent[],
) {
  for (let i = eventlog.length - 1; i >= 0; --i) {
    const event = eventlog[i]
    match(event)
      .exhaustive()
      .with({ type: 'order placed successfully' }, (event) => {
        removeGroupedElements(eventlog, {
          type: 'order requested',
          signal: event.signal,
        }, event)
      })
      .with({ type: 'order requested' }, (event) => {
        // Do nothing
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-expressions
        (() => {})
      })
      // retry failures
      .with({ type: 'order submission failed' }, (event) => {
        removeGroupedElements(eventlog, {
          type: 'order requested',
          signal: event.signal,
        }, event)
      })
      .with({ type: 'order delete requested' }, (event) => {
        // Do nothing
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-expressions
        (() => {})
      })
      .with({ type: 'order deleted successfully' }, (event) => {
        removeGroupedElements(eventlog, {
          type: 'order delete requested',
          order: event.order,
        }, event)
      })
      .with({ type: 'order deletion failed' }, (event) => {
        removeGroupedElements(eventlog, {
          type: 'order delete requested',
          order: event.order,
        }, event)
      })
      .with({ type: 'signal' }, (event) => {
        if (event.signals === []) {
          removeElement(eventlog, event)
        }
      })
      .run()
  }

  // We need to remove all signals except the last signal
  const signalIndexes = eventlog.reduce((a: number[], c, i) => {
    if (c.type === 'signal') { a.push(i) }
    return a
  }, []).slice(0, -1)

  // Remove previous signals that no longer apply
  signalIndexes.map((index) => {
    eventlog.splice(index, 1)
  })

  if (eventlog.length <= 1) {
    // If the eventlog just have one thing in it (the latest signal)
    // Or nothing because it was an empty signal that wasn't pushed to the log
    // then we are able to see if we should submit a signal
    return true
  } else {
    // If the eventlog is longer than one, then we are waiting for a response
    // back and need to wait for everything to succeed
    return false
  }
}
