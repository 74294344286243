import Big from 'big.js'
import { pipe } from 'fp-ts/function'
import * as $ from 'rxjs'
import { match, not } from 'ts-pattern'

import type { CoinbaseAuth } from '..'
import type { Balances } from '../../'
import { defaultMakerFeeRate, defaultTakerFeeRate } from '../fees'

import {
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
  CoinbaseOrderReceiveMessage,
} from './codecs'

/*
 * This is required to be at least 9 to display the user balances
 * in written out instead of numerial form. This will match that
 * of Coinbase.
 */
Big.NE = -9

// TODO: handle dropped messages
// From the documentation: https://docs.cloud.coinbase.com/exchange/docs/channels#the-full-channel
//
// Please note that messages can be dropped from this channel.
// By using the heartbeat channel you can track the last trade id and fetch trades that you missed
// from the REST API.

export const subscribeToUserBalances = ({
  isTestnet,
  auth,
  initialBalances,
  message,
}: {
  isTestnet: boolean
  auth: CoinbaseAuth
  initialBalances: Balances
  message: $.Observable<
    | CoinbaseOrderOpenMessage
    | CoinbaseOrderDoneMessage
    | CoinbaseOrderMatchMessage
  >
}): $.Observable<Balances> => {
  return pipe(
    message,
    // With only relevant messages, track updates to the users balances
    $.scan<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage,
      { balances: Balances; currentMakerFeeRate: Big; currentTakerFeeRate: Big }
    >(
      ({ balances, currentMakerFeeRate, currentTakerFeeRate }, message) => {
        match(message)
          .exhaustive()
          .when(CoinbaseOrderOpenMessage.is, (open) => {
            // DEBUG:
            // console.log('price', open.price.valueOf())
            // console.log('remainingSize', open.remaining_size.valueOf())

            // the order is now on the book, so `remaining_size` is the held amount.
            // we combine with `side` to see if we are holding base or quote currency
            match(open.side)
              .exhaustive()
              .with('buy', () => {
                // hold amount for limit buy orders: price x size x (1 + fee-percent)
                //
                // `remaining_size` is expressed in base currency, but we need to determine
                // units in quote currency, so we multiply by price
                //
                // Note that the `open` message implies a limit order, so we use the maker
                // fee percent.
                const heldAmount = open.remaining_size.mul(open.price).mul(
                  // The limit order holds are place with the higher Taker fee rate and then reduced after.
                  // I think this is due to it possibly going through as a market order.
                  defaultMakerFeeRate.div(100).plus(1),
                  // currentMakerFeeRate.plus(1),
                )
                const quoteCurrency = open.product_id.quoteCurrency
                const quoteBalance = balances.get(quoteCurrency)
                if (quoteBalance !== undefined) {
                  quoteBalance.available = quoteBalance.available.minus(heldAmount)
                  quoteBalance.held = quoteBalance.held.plus(heldAmount)
                }
              })
              .with('sell', () => {
                // hold amount for limit sell orders: the number of Bitcoin you wish to sell,
                // actual fees are assessed at the time of trade.
                //
                // Note: with available BTC and USD balances, selling some BTC paid fees in USD.
                const baseCurrency = open.product_id.baseCurrency
                const baseBalance = balances.get(baseCurrency)
                if (baseBalance !== undefined) {
                  const heldAmount = open.remaining_size
                  baseBalance.available = baseBalance.available.minus(heldAmount)
                  baseBalance.held = baseBalance.held.plus(heldAmount)
                }
                // The docs won't tell you this, but fees are held in the quote currency
                // if (quoteBalance !== undefined) {
                // const quoteCurrency = open.product_id.quoteCurrency
                // const quoteBalance = balances.get(quoteCurrency)
                //   const feeAmount = open.price.mul(open.remaining_size).mul(currentMakerFeeRate)
                //   quoteBalance.held = quoteBalance.held.plus(feeAmount)
                //   quoteBalance.available = quoteBalance.available.minus(feeAmount)
                // }
              })
              .run()
          })
          .when(CoinbaseOrderDoneMessage.is, (done) => {
            // DEBUG:
            // console.log('price', done.price.valueOf())
            // console.log('remainingSize', done.remaining_size.valueOf())

            // DISCUSS: do we need to un-hold or account for fees anywhere in this block?

            // For filled orders, we expect the `remaining_size` to be zero, so
            // the `done` message won't contain any helpful information for us.
            // In this case, we need to use the `match` message to update our
            // balances.
            if (done.reason === 'filled') {
              return
            }

            // This means we are only examining `canceled` orders.

            // From the docs:
            //
            // A `done` message will be sent for received orders which are fully
            // filled or canceled due to self-trade prevention. There will be
            // no open message for such orders. `done` messages for orders which
            // are not on the book should be ignored when maintaining a real-time
            // order book.
            //
            // As `done` messages for market orders do not have remaining_size or
            // price, so we do not need to process these messages. In this case,
            // we rely on the `match` message to update our balances.
            // if (isCoinbaseMarketOrderDoneMessage(done)) {
            //   return
            // }
            if (done.price === undefined || done.remaining_size === undefined) {
              return
            }

            // NOTE: we are not handling self-trade preservation in this MVP
            // so we will have to handle that case on another pass.

            // The current fee does not necessarily equal the fee when the order was placed

            // `remaining_size` indicates how much of the order went unfilled.
            // We combine with the `side` to see if we are removing a hold on
            // the base or quote currency.
            match(done)
              .with({
                side: 'buy',
                price: not(undefined),
                remaining_size: not(undefined),
              }, (done) => {
                // hold amount for limit buy orders: price x size x (1 + fee-percent)
                //
                // `remaining_size` is expressed in base currency, but we need to determine
                // units in quote currency, so we multiply by price
                //
                // Note that the `open` message implies a limit order, so we use the maker
                // fee percent.

                // The fee on order hold is the default Taker (market) rate
                const heldMakerFeeRate = defaultMakerFeeRate.div(100)
                const unheldAmount = done.remaining_size.mul(done.price).mul(
                  heldMakerFeeRate.plus(1),
                )
                const quoteCurrency = done.product_id.quoteCurrency
                const quoteBalance = balances.get(quoteCurrency)
                if (quoteBalance !== undefined) {
                  quoteBalance.available = quoteBalance.available.plus(unheldAmount)
                  quoteBalance.held = quoteBalance.held.minus(unheldAmount)
                }
              })
              .with({
                side: 'sell',
                price: not(undefined),
                remaining_size: not(undefined),
              }, (done) => {
                // hold amount for limit sell orders: the number of Bitcoin you wish to sell,
                // actual fees are assessed at the time of trade.
                //
                // Note: with available BTC and USD balances, selling some BTC paid fees in USD.
                const baseCurrency = done.product_id.baseCurrency
                const baseBalance = balances.get(baseCurrency)
                if (baseBalance !== undefined) {
                  const unheldAmount = done.remaining_size
                  baseBalance.available = baseBalance.available.plus(unheldAmount)
                  baseBalance.held = baseBalance.held.minus(unheldAmount)
                }
                // The docs won't tell you this, but fees are held in the quote currency,
                // so here we unhold what we previously held
                // const quoteCurrency = done.product_id.quoteCurrency
                // const quoteBalance = balances.get(quoteCurrency)
                // if (quoteBalance !== undefined) {
                //   const feeAmount = done.price.mul(done.remaining_size).mul(currentMakerFeeRate)
                //   quoteBalance.held = quoteBalance.held.minus(feeAmount)
                //   quoteBalance.available = quoteBalance.available.plus(feeAmount)
                // }
              })
              .run()
          })
          .when(CoinbaseOrderMatchMessage.is, (matchMessage) => {
            const baseCurrency = matchMessage.product_id.baseCurrency
            const quoteCurrency = matchMessage.product_id.quoteCurrency

            const baseBalance = balances.get(baseCurrency)
            const quoteBalance = balances.get(quoteCurrency)

            // For market orders, the `match` message is the first received message,
            // so we don't have any previously-held balances to unhold.
            if (matchMessage.taker_user_id !== undefined) {
              // Update the taker fee rate with up-to-date information
              // From the match message, the taker fee rate is not in percent, so we don't need to divide by 100
              currentTakerFeeRate = matchMessage.taker_fee_rate

              // My market buy matches with a sell, so the message comes through with
              // `side` as `sell`
              match(matchMessage.side)
                .exhaustive()
                .with('buy', () => {
                  // I just made a market sell, so remove the size from our
                  // available base balance and add the product of size and
                  // price to our available and total quote balance
                  if (baseBalance !== undefined) {
                    baseBalance.available = baseBalance.available.minus(
                      matchMessage.size,
                    )
                    baseBalance.total = baseBalance.total.minus(matchMessage.size)
                  }
                  if (quoteBalance !== undefined) {
                    // NOTE: the message ignores fees, but our available balance has shrunk
                    // by the amount we paid in fees
                    // NOTE: this assumes fees are always paid in the quote currency
                    const sizeInQuoteCurrencyLessFees = matchMessage.size
                      .times(matchMessage.price)
                      .times(Big(1).minus(currentTakerFeeRate))
                    quoteBalance.available = quoteBalance.available.add(
                      sizeInQuoteCurrencyLessFees,
                    )
                    quoteBalance.total = quoteBalance.total.add(
                      sizeInQuoteCurrencyLessFees,
                    )
                  }
                })
                .with('sell', () => {
                  // I just made a market buy, so add the size to our avalable
                  // base balance and remove the product of size and price from
                  // our available and total quote balance
                  if (baseBalance !== undefined) {
                    baseBalance.available = baseBalance.available.plus(
                      matchMessage.size,
                    )
                    baseBalance.total = baseBalance.total.plus(matchMessage.size)
                  }
                  if (quoteBalance !== undefined) {
                    // NOTE: the message ignores fees, but our available balance has shrunk
                    // by the amount we paid in fees
                    // NOTE: this assumes fees are always paid in the quote currency
                    const sizeInQuoteCurrencyIncludingFees = matchMessage.size
                      .times(matchMessage.price)
                      .times(currentTakerFeeRate.plus(1))
                    quoteBalance.available = quoteBalance.available.minus(
                      sizeInQuoteCurrencyIncludingFees,
                    )
                    quoteBalance.total = quoteBalance.total.minus(
                      sizeInQuoteCurrencyIncludingFees,
                    )
                  }
                })
                .run()
            }

            // For limit orders, we previously held an amount responding to the
            // `open` message, so we have to unhold the previously-held balance
            if (matchMessage.maker_user_id !== undefined) {
              const oldMakerFeeRate = defaultMakerFeeRate.div(100)
              // Update the maker fee rate with up-to-date information
              currentMakerFeeRate = matchMessage.maker_fee_rate

              match(matchMessage.side)
                .exhaustive()
                .with('buy', () => {
                  // My limit buy is matched, so the message comes through with `side` `buy`
                  const unheldAmountIncludingFees = matchMessage.size
                    .times(matchMessage.price)
                    .times(oldMakerFeeRate.plus(1))

                  const unheldAmountIncludingFeesWithNewRate = matchMessage.size
                    .times(matchMessage.price)
                    .times(currentMakerFeeRate.plus(1))

                  if (baseBalance !== undefined) {
                    baseBalance.available = baseBalance.available.plus(
                      matchMessage.size,
                    )
                    baseBalance.total = baseBalance.total.plus(matchMessage.size)
                  }
                  if (quoteBalance !== undefined) {
                    // The held balance was the old fee amount i.e. market order
                    quoteBalance.held = quoteBalance.held.minus(
                      unheldAmountIncludingFees,
                    )
                    // The total balance was not changed on order open so it needs the new rate
                    quoteBalance.total = quoteBalance.total.minus(
                      unheldAmountIncludingFeesWithNewRate,
                    )
                    // We have to update available too!
                    // This is due to the fact that the fees might have been off from open to close of limit order
                    quoteBalance.available = quoteBalance.available.plus(
                      unheldAmountIncludingFees.minus(
                        unheldAmountIncludingFeesWithNewRate,
                      ),
                    )
                  }
                })
                .with('sell', () => {
                  // My limit sell is matched, so the message comes through with `side` `sell`
                  if (baseBalance !== undefined) {
                    baseBalance.held = baseBalance.held.minus(matchMessage.size)
                    baseBalance.total = baseBalance.total.minus(matchMessage.size)
                  }
                  if (quoteBalance !== undefined) {
                    const sizeInQuoteCurrencyLessFees = matchMessage.size
                      .times(matchMessage.price)
                      .times(Big(1).minus(currentMakerFeeRate))
                    quoteBalance.available = quoteBalance.available.plus(
                      sizeInQuoteCurrencyLessFees,
                    )
                    quoteBalance.total = quoteBalance.total.plus(
                      sizeInQuoteCurrencyLessFees,
                    )
                  }
                })
                .run()
            }
          })
          .run()
        return {
          balances: balances,
          currentMakerFeeRate: currentMakerFeeRate,
          currentTakerFeeRate: currentTakerFeeRate,
        }
      },
      {
        balances: initialBalances,
        currentMakerFeeRate: defaultMakerFeeRate.div(100),
        currentTakerFeeRate: defaultTakerFeeRate.div(100),
      },
    ),
    // Promote `scan` into a poor-man's `loop` -- emit the same value as the
    // loop-back and then separately extract the desired value
    $.map((_) => _.balances),
    // DISCUSS: do we want to emit identical/unchanged values?
  )
}
