import Big from 'big.js'
import { pipe } from 'fp-ts/function'
import * as $ from 'rxjs'
import { match } from 'ts-pattern'

import { CoinbaseAuth } from '..'
import type { LimitOrderBook } from '../../'
import { LimitOrder } from '../../LimitOrderBook'

import {
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
} from './codecs'

export const subscribeToUserOrderBook = ({
  isTestnet,
  auth,
  initialLimitOrderBook,
  message,
}: {
  isTestnet: boolean
  auth: CoinbaseAuth
  initialLimitOrderBook: LimitOrderBook
  message: $.Observable<
    | CoinbaseOrderOpenMessage
    | CoinbaseOrderDoneMessage
    | CoinbaseOrderMatchMessage
  >
}): $.Observable<LimitOrderBook> => {
  return pipe(
    message,
    $.scan<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage,
      LimitOrderBook
    >(
      (lob, message) => {
        match(message)
          .exhaustive()
          // Add the limit order to our limit order book
          .when(CoinbaseOrderOpenMessage.is, (open) => {
            const limitOrder: LimitOrder = {
              price: open.price,
              filledQuantity: Big(0),
              remainingQuantity: open.remaining_size,
              totalQuantity: open.remaining_size,
              ID: open.order_id,
              productID: open.product_id.product,
            }
            const price = open.price.toNumber()
            match(open.side)
              .exhaustive()
              .with('buy', () => {
                let bids = lob.bids.get(price)
                if (bids === undefined) {
                  // no array of orders at this level so create the array
                  bids = []
                  lob.bids.set(price, bids)
                }
                bids.push(limitOrder)
              })
              .with('sell', () => {
                let asks = lob.asks.get(price)
                if (asks === undefined) {
                  // no array of orders at this level so create the array
                  asks = []
                  lob.asks.set(price, asks)
                }
                asks.push(limitOrder)
              })
              .run()
          })
          // Remove the limit order from our limit order book
          .when(CoinbaseOrderDoneMessage.is, (done) => {
            const price = done.price?.toNumber()
            if (price === undefined) {
              // we are looking at a market order that was never
              // in the limit order book
              return
            }

            const ordersAtLevel = match(done.side)
              .exhaustive()
              .with('buy', () => lob.bids.get(price))
              .with('sell', () => lob.asks.get(price))
              .run()

            if (ordersAtLevel === undefined) {
              console.warn(`Expected to find orders to complete at price ${price}`)
              return
            }

            // assume the order exists only once in the ordersAtLevel array
            const index = ordersAtLevel.findIndex((order) => order.ID === done.order_id)
            if (index === -1) {
              console.warn(
                `Expected to find ${done.order_id} at price ${price} to mark as done`,
              )
              return
            }
            // delete one element starting at `index`
            ordersAtLevel.splice(index, 1)

            // if that was the last order at this level, drop all references
            // to the orders array in our limit order book so it is garbage
            // collected
            if (ordersAtLevel.length === 0) {
              match(done.side)
                .exhaustive()
                .with('buy', () => lob.bids.delete(price))
                .with('sell', () => lob.asks.delete(price))
                .run()
            }
          })
          // Update our limit order in the limit order book
          // NOTE: we assume messages can be received out of order
          // for example, `done` can be received before the last `match`.
          // In this case, there will be no order in our limit order book
          // to update and do not treat that as an error
          .when(CoinbaseOrderMatchMessage.is, (matchMessage) => {
            // If this message describs the user's market order, there
            // will be nothing in the limit order book to update
            if (matchMessage.taker_user_id !== undefined) {
              return
            }

            // Only operate on the user's limit orders
            const price = matchMessage.price.toNumber()
            const matchedOrderID = matchMessage.maker_order_id

            const ordersAtLevel = match(matchMessage.side)
              .exhaustive()
              .with('buy', () => lob.bids.get(price))
              .with('sell', () => lob.asks.get(price))
              .run()

            if (ordersAtLevel === undefined) {
              console.warn(
                `Expected to find orders to update at price ${price} to update`,
              )
              return
            }

            const order = ordersAtLevel.find((order) => order.ID === matchedOrderID)
            if (order === undefined) {
              console.warn(
                `Expected to find ${matchedOrderID} at price ${price} to update`,
              )
              return
            }

            // could be different for base and quote orders (bid and ask)
            // I believe all standing orders express size in units of
            // base currency, so we can do simple addition and subtraction
            order.filledQuantity = order.filledQuantity.plus(matchMessage.size)
            order.remainingQuantity = order.remainingQuantity.minus(matchMessage.size)
          })
          .run()

        return lob
      },
      initialLimitOrderBook,
    ),
  )
}
