import { Link } from 'gatsby'
import React from 'react'

const TV = require('../assets/images/tradingviewLogo.png')

export function TradingviewLogo() {
  return (
    <div className='runButton' style={{ right: 0, zIndex: 101 }}>
      <a
        href='https://www.tradingview.com/'
        target='_blank'
        data-toggle='tooltip'
        title='TradingView Lightweight Charts Copyright (с) 2022 TradingView, Inc.' rel="noreferrer"
      >
        <img src={TV} height='30' />
      </a>
    </div>
  )
}
