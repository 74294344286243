import Big from 'big.js'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { NonEmptyString } from 'io-ts-types'

import { BigFromString } from '../BigCodec'

export interface CoinbasePrice {
  rawPrice: NonEmptyString
  price: Big
}

const _CoinbasePrice = t.type(
  {
    rawPrice: NonEmptyString,
    price: BigFromString,
  },
  'DummyCoinbasePrice',
)

export const CoinbasePrice = new t.Type<CoinbasePrice, NonEmptyString, NonEmptyString>(
  'CoinbasePrice',
  _CoinbasePrice.is.bind(null),
  (string, context) => {
    return pipe(
      BigFromString.decode(string),
      E.map((big): CoinbasePrice => ({
        rawPrice: string,
        price: big,
      })),
      E.fold(() => t.failure(string, context), t.success),
    )
  },
  (coinbasePrice) => coinbasePrice.rawPrice,
)
