import { Observable } from 'rxjs'

import type { Candle, LimitOrderType, MarketOrderType, Timeframe } from '../types/index'

import type { Balances } from './Balance'
import type { LimitOrderBook } from './LimitOrderBook'
import { CoinbaseOrders, CoinbaseTradepairs } from './coinbase/rest/codecs'
import {
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
} from './coinbase/websocket/codecs'
import { KrakenTradepairDataGroup, KrakenTradepairs } from './kraken/rest/codecs'
import { KrakenClosedOrderMessage, KrakenConfirmOpenMessage, KrakenConnectionMessage, KrakenFilledOrderMessage, KrakenHeartbeatMessage, KrakenMarketOpenOrderMessage, KrakenOpenOrderMessage, KrakenOpenOrderWebsocketMessage, KrakenSubscriptionMessage, KrakenTradeMessage, KrakenUserWebsocketMessage } from './kraken/websocket/codecs'
export * from './Balance'
export * from './LimitOrderBook'

export const zeroCandle = (): Candle => ({
  open: 0,
  high: 0,
  low: 0,
  close: 0,
  volume: 0,
  time: new Date(0),
})

export type OrderResponse =
  | { type: 'ok'; order: string }
  | { type: 'error'; message: string }

export interface Gateway {
  /**
   * Returns some number of historical `Candle`s (the exact number varies depending on
   * the exchange providing the data) followed by per-tick `Candle` updates. These
   * updates will represent the still-forming `Candle`s, meaning you will see numerous
   * `Candle`s with the same (opening) `time`. When a tick is detected that comprises a
   * new candle, a candle with a more-recent `time` will be sent next.
   */
  subscribeToCandles(parameters: {
    timeframe: Timeframe
    // Can't use a Tradepair here because Coinbase uses hyphens to separate the base and
    // quote currencies, and because Coinbase does not limit currency names to 3 letters
    tradepair: string
  }): Observable<Candle>

  /**
   * Gets all available tradepairs
   */
  getTradepairs(): Promise<CoinbaseTradepairs>

  /**
   * Gets user's open orders
   */
  getOpenOrders(): Promise<CoinbaseOrders>

  /**
   * Subscribes to user feed
   */
  subscribeToUserFeed(parameters: {
    allProducts: CoinbaseTradepairs | KrakenTradepairs
  }): Observable<
    | CoinbaseOrderDoneMessage
    | CoinbaseOrderOpenMessage
    | CoinbaseOrderMatchMessage
  >

  /**
   * Creates a stream of the user's balances.
   */
  subscribeToBalances(parameters: {
    message: Observable<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
      | KrakenUserWebsocketMessage
    >
  }): Observable<Balances>

  /**
   * Creates a stream of the user's open orders in a limit order book.
   */
  subscribeToOrderBook(parameters: {
    message: Observable<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
      | KrakenUserWebsocketMessage
    >
    openOrders: Promise<CoinbaseOrders>
  }): Observable<LimitOrderBook>

  /**
   * Submits a market order with calculated exposure.
   */
  submitMarketOrder(parameters: MarketOrderType): Promise<OrderResponse>

  /**
   * Submits a limit order.
   */
  submitLimitOrder(parameters: LimitOrderType): Promise<OrderResponse>

  /**
   * Delete an order.
   */
  deleteOrder(parameters: {
    productId: string
    orderId: string
  }): Promise<OrderResponse>
}

export type GatewayConstructor<Auth> = (
  parameters: { isTestnet: boolean; auth: Auth },
) => Gateway

export interface KrakenGateway {
  /**
   * Gets all available tradepairs
   */
  getTradepairs(): Promise<KrakenTradepairs>

  /**
 * Gets all available tradepairs
 */
  getTradepairData(): Promise<KrakenTradepairDataGroup>

  /**
   * Suscribes to ohlc candle websocket feed
   */
  subscribeToCandles(parameters: {
    timeframe: Timeframe
    tradepair: string
  }): Observable<Candle>

  /**
   * Subscribes to user feed, this is the user's own trades
   */
  subscribeToUserFeed(): Observable<
    KrakenTradeMessage
  >

  /**
  * Subscribes to open order feed. These are opened limit orders, closed, cancelled, and updated.
  */
  subscribeToOpenOrderFeed(): Observable<
    | KrakenOpenOrderMessage
    | KrakenClosedOrderMessage
    | KrakenFilledOrderMessage
  >

  /**
  * Creates a stream of the user's balances.
  */
  subscribeToBalances(parameters: {
    message: Observable<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
      | KrakenUserWebsocketMessage
      | KrakenOpenOrderMessage
      | KrakenClosedOrderMessage
      | KrakenFilledOrderMessage
    >
    products: CoinbaseTradepairs | KrakenTradepairs,
    tradepairData: Observable<KrakenTradepairDataGroup>
  }): Observable<Balances>

  /**
   * Creates a stream of the user's open orders in a limit order book.
   */
  subscribeToOrderBook(parameters: {
    message: Observable<
      | KrakenOpenOrderMessage
      | KrakenClosedOrderMessage
      | KrakenFilledOrderMessage
    >
  }): Observable<LimitOrderBook>

  /**
   * Submits a market order with calculated exposure.
   */
  submitMarketOrder(parameters: MarketOrderType): Observable<OrderResponse>

  /**
   * Submits a limit order.
   */
  submitLimitOrder(parameters: LimitOrderType): Observable<OrderResponse>

  /**
   * Delete an order or group of orders
   */
  deleteOrder(parameters: {
    txidOrders: string[]
  }): Observable<OrderResponse>

}

export type KrakenGatewayConstructor<Auth> = (
  parameters: { auth: Auth },
) => KrakenGateway
