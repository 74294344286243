import Big from 'big.js'
import * as O from 'fp-ts/Option'
import { percentageChange } from 'percentage-change'

import type { Balances, LimitOrder, LimitOrderBook } from '../gateway'
import { takerThresholdPercent } from '../gateway/coinbase/fees'
import { KrakenTradepairs } from '../gateway/kraken/rest/codecs'

import { UserSignalLimitEvent, UserSignalMarketEvent } from './event-log'
import { getKrakenBalances, getCurrentTradepairPorfolio } from './get-current-portfolio'
import { getDesiredTradepairPortfolio } from './get-desired-portfolio'
import getKrakenLimitOrder from './get-kraken-limit-order'

let updatedBaseBalanceInBaseCurrency = new Big(0)
let updatedQuoteBalance = new Big(0)
let updatedMinSize = 0
let nonSubmitedOrders = 0

export async function compareKrakenLimitOrders(
  tradepair: string,
  balances: Balances,
  products: KrakenTradepairs,
  limitOrders: UserSignalLimitEvent[],
  bids: boolean,
  orderBook: LimitOrderBook,
  candlePrice: number,
  totalLimitsToSubmit: number,
  limitOrdersOnChart: LimitOrder[][],
): Promise<boolean> {
  const newBalances = getKrakenBalances(
    tradepair,
    balances,
    products,
  )

  updatedBaseBalanceInBaseCurrency = newBalances.baseBalanceInBaseCurrency
  updatedQuoteBalance = newBalances.quoteBalance
  updatedMinSize = newBalances.minSize

  nonSubmitedOrders = bids
    ? nonSubmitedOrders
    : 0

  return limitOrders.some((signal) => {
    
    const limitPrice = signal.price

    const currentPorfolio = getCurrentTradepairPorfolio(
      limitPrice,
      updatedBaseBalanceInBaseCurrency,
      updatedQuoteBalance,
    )

    const needQuantity = getDesiredTradepairPortfolio(
      signal.exposure,
      currentPorfolio.totalBalanceInBase,
    )

    const order = getKrakenLimitOrder(
      updatedBaseBalanceInBaseCurrency,
      needQuantity,
      tradepair,
      limitPrice,
    )

    // Check to see if order is too small
    if (updatedMinSize / limitPrice >= parseFloat(order.quantity)) {
      nonSubmitedOrders = nonSubmitedOrders + 1
      if (limitOrdersOnChart.length > totalLimitsToSubmit - nonSubmitedOrders) {
        return true
      }

      return false
    }

    // Check to see if the order would go through as a market, if so don't submit
    if (order.side === 'sell' && candlePrice > signal.price) {
      nonSubmitedOrders = nonSubmitedOrders + 1
      if (limitOrdersOnChart.length > totalLimitsToSubmit - nonSubmitedOrders) {
        return true
      }

      return false
    }

    if (order.side === 'buy' && candlePrice < signal.price) {
      nonSubmitedOrders = nonSubmitedOrders + 1
      if (limitOrdersOnChart.length > totalLimitsToSubmit - nonSubmitedOrders) {
        return true
      }

      return false
    }

    // This is the case that the signal is for the candle close.
    // We only want to apply the change if the signal is on the correct side.
    if (signal.price === candlePrice) {
      if (bids && order.side === 'sell') {
        return false
      }
      if (!bids && order.side === 'buy') {
        return false
      }
    }

    updatedBaseBalanceInBaseCurrency = order.side === 'buy'
      ? updatedBaseBalanceInBaseCurrency.plus(order.quantity)
      : updatedBaseBalanceInBaseCurrency.minus(order.quantity)

    updatedQuoteBalance = order.side === 'buy'
      ? updatedQuoteBalance.minus(order.amount)
      : updatedQuoteBalance.plus(order.amount)

    const currentLimitsAtPrice = bids
      ? orderBook.bids.get(order.price)
      : orderBook.asks.get(order.price)

    if (currentLimitsAtPrice !== undefined) {
      return currentLimitsAtPrice.some((limit) => {
        const percentChange = Math.abs(
          O.getOrElse(() => 0)(
            percentageChange(
              parseFloat(order.quantity),
              limit.remainingQuantity.toNumber(),
            ),
          ),
        )

        if (percentChange === Infinity) {
          return true
        }
        return takerThresholdPercent.lte(percentChange)
      })
    } else {
      return true
    }
  })
}
