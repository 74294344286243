import Big from 'big.js'
import { MarketOrderType } from '../types/index'

/*
 * This is required to be at least 9 to display the user balances
 * in written out instead of numerial form. 
 * 
 */
Big.NE = -9

const createSellOrder = (
  need: Big,
  baseBalance: Big,
  tradepair: string,
  candlePrice: number,
): MarketOrderType => {
  const total = baseBalance.minus(need)//exposureNeeded.times(baseBalance)
  const quantity = total.valueOf()
  const amount = total.mul(candlePrice)

  return {
    side: 'sell',
    quantity,
    tradepair,
    amount,
  }
}

const createBuyOrder = (
  have: Big,
  need: Big,
  tradepair: string,
  candlePrice: number,
): MarketOrderType => {
  const total = need.minus(have)
  const quantity = total.valueOf()
  const amount = total.mul(candlePrice)

  return {
    side: 'buy',
    quantity,
    tradepair,
    amount,
  }
}

export default function getKrakenMarketOrder(
  baseBalanceInBaseCurrency: Big,
  tradepair: string,
  needQuanity: Big,
  candlePrice: number,
) {
  const order = baseBalanceInBaseCurrency.gt(needQuanity)
    ? createSellOrder(
      needQuanity,
      baseBalanceInBaseCurrency,
      tradepair,
      candlePrice,
    )
    : createBuyOrder(
      baseBalanceInBaseCurrency,
      needQuanity,
      tradepair,
      candlePrice,
    )

  return order
}
