import { pipe } from 'fp-ts/function'
import * as $ from 'rxjs'
import type { KrakenAuth } from '..'
import type { Balances } from '../..'
import { CoinbaseTradepairs } from '../../coinbase/rest/codecs'
import { CoinbaseOrderDoneMessage, CoinbaseOrderMatchMessage, CoinbaseOrderOpenMessage } from '../../coinbase/websocket/codecs'
import { getCurrentBalances } from '../rest/balances'
import { KrakenTradepairDataGroup, KrakenTradepairs } from '../rest/codecs'
import {
  KrakenClosedOrderMessage,
  KrakenOpenOrderMessage,
  KrakenOpenOrderWebsocketMessage,
  KrakenUserWebsocketMessage,
} from '../websocket/codecs'
import { subscribeToUserBalances } from '../websocket/user-balances'

export const subscribeToBalances = ({
  auth,
}: {
  auth: KrakenAuth | undefined
}) =>
  (parameters: {
    message: $.Observable<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
      | KrakenUserWebsocketMessage
      | KrakenOpenOrderMessage
      | KrakenClosedOrderMessage
    >,
    products: KrakenTradepairs | CoinbaseTradepairs,
    tradepairData: $.Observable<KrakenTradepairDataGroup>
  }): $.Observable<Balances> => {
    /*
    * Get the user's balance (total for all products)
    */

    // This occurs when the user either doesn't have auth set up or isn't active
    if (auth === undefined) {
      return $.of(new Map())
    }

    return pipe(
      $.from(getCurrentBalances({ auth })),
      $.mergeMap((initialBalances) =>
        $.concat(
          $.of(initialBalances),
          subscribeToUserBalances({
            auth,
            initialBalances,
            message: parameters.message,
            products: parameters.products as KrakenTradepairs,
            tradepairData: parameters.tradepairData
          }),
        ),
      ),
    )
  }
