import * as O from 'fp-ts/Option'
import { percentageChange } from 'percentage-change'
import React from 'react'

import type { Candle } from '../types/index'
import {
  CandleSeriesName,
  getCrosshairedCandleValue,
  isCrosshairVisible,
  MouseEventParams,
  Plot,
  SupportedSeries,
  VolumeSeriesName,
} from '../vendor/lightweight-charts'

/* eslint-disable @typescript-eslint/no-non-null-assertion */

export function TradingviewChartLegend(props: {
  candle: Candle
  plots: Map<string, Plot<SupportedSeries>>
  crosshairSelectedBar: MouseEventParams | undefined
}): JSX.Element {
  const color = props.candle.open > props.candle.close ? 'red' : 'green'
  const isVisible = props.crosshairSelectedBar !== undefined
    && isCrosshairVisible(props.crosshairSelectedBar)

  const getCrosshairedVolumeValue = (mouseEvent: MouseEventParams): number => {
    const bar = mouseEvent.seriesPrices.get(props.plots.get(VolumeSeriesName)!.series)!
    if (typeof bar !== 'number') {
      throw new Error('Expected volume to be a scalar number')
    }
    return bar
  }

  const { open, high, low, close } = isVisible
    ? {
      open: getCrosshairedCandleValue(
        props.crosshairSelectedBar!,
        props.plots.get(CandleSeriesName)!.series,
        'open',
      ),
      high: getCrosshairedCandleValue(
        props.crosshairSelectedBar!,
        props.plots.get(CandleSeriesName)!.series,
        'high',
      ),
      low: getCrosshairedCandleValue(
        props.crosshairSelectedBar!,
        props.plots.get(CandleSeriesName)!.series,
        'low',
      ),
      close: getCrosshairedCandleValue(
        props.crosshairSelectedBar!,
        props.plots.get(CandleSeriesName)!.series,
        'close',
      ),
    }
    : props.candle

  const volume = Math.round(
    isVisible
      ? getCrosshairedVolumeValue(props.crosshairSelectedBar!)
      : props.candle.volume,
  )

  const candleChange = O.getOrElse(() => 0)(percentageChange(open, close)).toFixed(2)

  return (
    <div className='runButton ml-2'>
      <div style={{ fontSize: '15px', marginTop: '30px' }}>
        O<span style={{ color: color }}>{open}</span>
        &nbsp;H<span style={{ color: color }}>
          {high}
        </span>
        &nbsp;L<span style={{ color: color }}>
          {low}
        </span>
        &nbsp;C<span style={{ color: color }}>
          {close}
        </span>
        &nbsp;<span style={{ color: color }}>({candleChange}%)</span>
      </div>

      <div id='volume' style={{ fontSize: '15px' }}>
        Volume <span style={{ color: color }}>{volume}</span>
      </div>
    </div>
  )
}
