import Divider from '@material-ui/core/Divider'
import React, { useEffect } from 'react'

import { useStore } from '../store'

export function PyodideOutput() {
  const output = useStore((state) => state.output)
  const clearOutput = useStore((state) => state.clearOutput)

  const color = (type: string) => {
    if (type === 'status') {
      return 'gray'
    } else if (type === 'error') {
      return '#c51910'
    }
    return 'black'
  }

  const backgroundColor = (type: string) => {
    // CONSIDER: using an exhaustive check here, a switch statement
    // with no default case, or `match`
    if (type === 'error') {
      return '#FDF2F5'
    }
    return 'transparent'
  }

  let icon: JSX.Element

  const iconValue = (type: string) => {
    if (type === 'error') {
      icon = <i className='bi bi-exclamation-circle-fill mr-2'></i>
    } else {
      icon = <div></div>
    }
    return icon
  }

  useEffect(() => {
    const objDiv = document.getElementById('output')
    if (objDiv !== null) {
      objDiv.scrollTop = objDiv.scrollHeight
    }
  })

  return (
    <div>
      <div className='editorButtons'>
        <button
          className='btn btn-outline-secondary btn-sm'
          onClick={() => clearOutput()}
        >
          <i className='bi bi-x-square mr-1 text-white'></i>
          Clear
        </button>
      </div>

      <div className='monaco'>
        <div
          className='chart behind console'
          id='output'
        >
          {output.map((item, index) => (
            <div key={index} style={{ backgroundColor: backgroundColor(item.type) }}>
              <pre
                style={{
                  color: color(item.type),
                  whiteSpace: 'pre-wrap',
                  display: 'inline',
                }}
              >
                {iconValue(item.type)}
                {item.value}
                <Divider />
              </pre>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
