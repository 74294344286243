import { pipe } from 'fp-ts/function'
import * as $ from 'rxjs'

import type { CoinbaseAuth } from '..'
import type { Balances } from '../..'
import { getCurrentBalances } from '../rest/balances'
import {
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
} from '../websocket/codecs'
import { subscribeToUserBalances } from '../websocket/user-balances'

export const subscribeToBalances = ({
  isTestnet,
  auth,
}: {
  isTestnet: boolean
  auth: CoinbaseAuth | undefined
}) =>
  (parameters: {
    message: $.Observable<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
    >
  }): $.Observable<Balances> => {
    /*
    * Get the user's balance (total for all products)
    */

    // This occurs when the user either doesn't have auth set up or isn't active
    if (auth === undefined) {
      return $.of(new Map())
    }

    return pipe(
      $.from(getCurrentBalances({ isTestnet, auth })),
      $.mergeMap((initialBalances) =>
        $.concat(
          $.of(initialBalances),
          subscribeToUserBalances({
            isTestnet,
            auth,
            initialBalances,
            message: parameters.message,
          }),
        ),
      ),
    )
  }
