import * as crypto from 'crypto'

import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as PathReporter from 'io-ts/lib/PathReporter'
import * as $ from 'rxjs'
import { WebSocketSubject } from 'rxjs/webSocket'
import { match } from 'ts-pattern'

import type { CoinbaseAuth } from '..'
import { CoinbaseTradepairs } from '../rest/codecs'
import {
  CoinbaseAuctionMessage,
  CoinbaseHeartbeatMessage,
  CoinbaseOrderChangeMessage,
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
  CoinbaseOrderReceiveMessage,
  CoinbaseStopOrderActivateMessage,
  CoinbaseSubscriptionMessage,
  CoinbaseUserWebsocketMessage,
} from '../websocket/codecs'

const listenToUserFeedMessages = ({
  userFeedWebsocket,
}: {
  userFeedWebsocket: $.Observable<unknown>
}) => {
  return pipe(
    userFeedWebsocket,
    $.mergeMap((websocketMessage) => {
      // DEBUG:
      // if ((websocketMessage as any).type !== 'heartbeat') {
      //   console.log('Raw LOB websocket message:', websocketMessage)
      // }
      return pipe(
        CoinbaseUserWebsocketMessage.decode(websocketMessage),
        E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
        // Decode the websocket message and filter out messages we do
        // not require to track the user's current balances
        E.map((message) =>
          match<
            CoinbaseUserWebsocketMessage,
            $.Observable<
              | CoinbaseOrderOpenMessage
              | CoinbaseOrderDoneMessage
              | CoinbaseOrderMatchMessage
            >
          >(message)
            .when(CoinbaseHeartbeatMessage.is, () => $.EMPTY)
            .when(CoinbaseOrderOpenMessage.is, (open) => $.of(open))
            .when(CoinbaseOrderDoneMessage.is, (done) => $.of(done))
            .when(CoinbaseOrderMatchMessage.is, (match) => $.of(match))
            .when(CoinbaseOrderChangeMessage.is, () => $.EMPTY)
            .when(CoinbaseStopOrderActivateMessage.is, () => $.EMPTY)
            .when(CoinbaseAuctionMessage.is, () => $.EMPTY)
            .when(CoinbaseOrderReceiveMessage.is, () => $.EMPTY)
            .when(CoinbaseSubscriptionMessage.is, () => $.EMPTY)
            .otherwise(() => {
              console.warn('unmatched user limit order book message:', message)
              return $.EMPTY
            }),
        ),
        E.getOrElseW((error) => {
          throw new Error('User limit order book feed error: ' + error)
        }),
      )
    }),
  )
}

export const subscribeToUserFeedWebsocket = ({
  isTestnet,
  auth,
  mainWebsocket,
}: {
  isTestnet: boolean
  auth: CoinbaseAuth | undefined
  mainWebsocket: WebSocketSubject<unknown>
}) =>
  (parameters: { allProducts: CoinbaseTradepairs }) => {
    // This occurs when the user either doesn't have auth set up or isn't active
    if (auth === undefined) {
      return $.of()
    }

    const timestamp = (Date.now() / 1000).toString()
    const what = timestamp.toString() + 'GET' + '/users/self/verify'
    const key = Buffer.from(auth.apiSecret, 'base64')
    const hmac = crypto.createHmac('sha256', key)
    const signature = hmac.update(what).digest('base64')

    const subscribeToUserFeedMessage = {
      type: 'subscribe',
      channels: ['user'],
      product_ids: parameters.allProducts.map((product) => product.id),

      key: auth.apiKey,
      signature: signature,
      timestamp: timestamp,
      passphrase: auth.apiPassphrase,
    } as const

    const unsubscribeToUserFeedMessage = {
      type: 'unsubscribe',
      channels: ['user'],
      product_ids: parameters.allProducts.map((product) => product.id),
    } as const

    const userFeedWebsocket = pipe(
      mainWebsocket.multiplex(
        () => (subscribeToUserFeedMessage),
        () => (unsubscribeToUserFeedMessage),
        (message: any): boolean =>
          message.type === 'open'
          || message.type === 'done'
          || message.type === 'match',
      ),
      $.retry(),
    )

    return listenToUserFeedMessages({
      userFeedWebsocket,
    })
  }
