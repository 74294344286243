import { API } from 'aws-amplify'
import * as E from 'fp-ts/Either'
import { KrakenTradepairs } from './codecs'

export const getTradepairs = () =>
    async (): Promise<KrakenTradepairs> => {
        const apiName = 'Endpoint'

        return await API.get(apiName, 'backend/kraken/assetpairs', {})
            .then((response: any): KrakenTradepairs => {
                const tradepairs = KrakenTradepairs.decode(Object.values(response.result))

                if (E.isLeft(tradepairs)) {
                    throw new Error('Received unexpected data from kraken AssetPairs endpoint')
                }

                return tradepairs.right
            })
    }
