import React from 'react'

export function OrderNotification() {
  return (
    <div aria-live='polite' aria-atomic='true' style={{ position: 'relative' }}>
      <div
        className='toast'
        role='alert'
        aria-live='assertive'
        aria-atomic='true'
        data-autohide='true'
        data-delay='3000'
        style={{
          position: 'absolute',
          right: 0,
          minWidth: '350px',
          marginRight: '10px',
        }}
      >
        <div className='toast-header'>
          <i className='bi bi-exclamation-square-fill mr-2 text-danger'></i>
          <strong className='mr-auto'>
            Stratos Order
          </strong>
          <small id='toast-date'></small>
          <button
            type='button'
            className='ml-2 mb-1 close'
            data-dismiss='toast'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='toast-body'>
          Error from exchange, resubmitting order
        </div>
      </div>
    </div>
  )
}
