import * as E from 'fp-ts/Either'
import { NonEmptyArray } from 'fp-ts/NonEmptyArray'
import * as NEA from 'fp-ts/NonEmptyArray'
import { pipe } from 'fp-ts/function'
import { nonEmptyArray } from 'io-ts-types'
import * as PathReporter from 'io-ts/lib/PathReporter'
import * as $ from 'rxjs'
import { WebSocketSubject } from 'rxjs/webSocket'
import { match } from 'ts-pattern'

import { previousSessionOpenInclusive } from '@stratos-trade/sessions'

import { coinbaseToTimeframeUnit } from '../../../easyFunctions'
import type { Candle, Timeframe } from '../../../types/index'
import { restEndpoint } from '../endpoints'
import { CoinbaseCandle } from '../rest/codecs'
import {
  CoinbaseHeartbeatMessage,
  CoinbaseSubscriptionMessage,
  CoinbaseTickerMessage,
  CoinbaseTickerWebsocketMessage,
} from '../websocket/codecs'

const getHistoricalCandles = async ({
  timeframe,
  isSandbox,
  productID,
}: {
  timeframe: Timeframe
  isSandbox: boolean
  productID: string
}): Promise<NonEmptyArray<Candle>> => {
  const baseUrl = restEndpoint(isSandbox)

  return fetch(
    `${baseUrl}/products/${productID}/candles?`
    + new URLSearchParams({
      granularity: timeframe.toString(),
      end: new Date().toISOString(),
    }).toString(),
  )
    .then(async (response) => response.json())
    .then((rawResponse) =>
      pipe(
        nonEmptyArray(CoinbaseCandle).decode(rawResponse),
        // coinbase returns candles in reverse-chronological order
        E.map(NEA.reverse),
        E.getOrElseW((errors) => {
          throw new Error(
            // eslint-disable-next-line @typescript-eslint/quotes
            `Received unexpected data from coinbase's 'product' endpoint: `
            + PathReporter.failure(errors).join('\n'),
          )
        }),
      ),
    )
}

const subscribeToExchangeMatches = async ({
  timeframe,
  isSandbox,
  productID,
  mainWebsocket,
}: {
  timeframe: Timeframe
  isSandbox: boolean
  productID: string
  mainWebsocket: WebSocketSubject<unknown>
}): Promise<$.Observable<Candle>> => {
  const subscribeToTickerMessage = {
    type: 'subscribe',
    channels: ['heartbeat', 'ticker'],
    product_ids: [productID],
  } as const

  const unsubscribeToTickerMessage = {
    type: 'unsubscribe',
    channels: ['heartbeat', 'ticker'],
  } as const

  const tickerWebsocket = pipe(
    mainWebsocket.multiplex(
      () => (subscribeToTickerMessage),
      () => (unsubscribeToTickerMessage),
      // This is where we filter the message
      (message: any): boolean =>
        message.type === 'ticker' && message.product_id === productID && message.hasOwnProperty('sequence')
    ),
    $.retry(),
  )

  // OPTIMIZE: we only need to query the latest candle here, not an entire batch. We can
  // do this by supplying the `start` query param
  const candles = await getHistoricalCandles({ timeframe, isSandbox, productID })

  return pipe(
    tickerWebsocket,
    $.mergeMap((websocketMessage) =>
      pipe(
        CoinbaseTickerWebsocketMessage.decode(websocketMessage),
        E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
        E.map((message) =>
          match(message)
            .when(CoinbaseTickerMessage.is, (ticker) => $.of(ticker))
            .when(CoinbaseHeartbeatMessage.is, () => $.EMPTY)
            .when(CoinbaseSubscriptionMessage.is, () => $.EMPTY)
            .otherwise(() => {
              console.warn('unmatched ticker message:', message)
              return $.EMPTY
            }),
        ),
        E.getOrElseW((error) => {
          throw new Error('Ticker feed error: ' + error)
        }),
      ),
    ),
    $.scan((candle, match) => {
      const timeframeUnit = coinbaseToTimeframeUnit(timeframe)
      const matchSessionOpen = previousSessionOpenInclusive(timeframeUnit, match.time)
      const isMatchInNewSession = candle.time < matchSessionOpen

      const nextCandle: Candle = isMatchInNewSession
        ? {
          open: candle.close,
          high: match.price,
          low: match.price,
          close: match.price,
          volume: match.last_size,
          time: matchSessionOpen,
        }
        : {
          open: candle.open,
          high: Math.max(candle.high, match.price),
          low: Math.min(candle.low, match.price),
          close: match.price,
          volume: candle.volume + match.last_size,
          time: candle.time,
        }

      return nextCandle
    }, NEA.last(candles)),
  )
}

export const subscribeToCandles = ({
  isTestnet,
  mainWebsocket,
}: { isTestnet: boolean; mainWebsocket: WebSocketSubject<unknown> }) =>
  (parameters: {
    timeframe: Timeframe
    tradepair: string
  }): $.Observable<Candle> => {
    const historical = pipe(
      $.from(
        getHistoricalCandles({
          timeframe: parameters.timeframe,
          isSandbox: isTestnet,
          productID: parameters.tradepair,
        }),
      ),
      $.mergeMap((candles) => candles),
    )
    const realtime = pipe(
      $.from(
        subscribeToExchangeMatches({
          timeframe: parameters.timeframe,
          isSandbox: isTestnet,
          productID: parameters.tradepair,
          mainWebsocket,
        }),
      ),
      // I don't love this pattern here but it got me compiling when I need it. Happy to
      // replace it
      $.mergeMap((observable) => observable),
    )
    return $.concat(historical, realtime)
  }
