import type { Candle, standardOut } from '../types/index'

// TODO: type this more better
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PyodideClient = any

export async function unregisterAndUnloadJsModule(
  pyodideClient: PyodideClient,
  moduleName: string,
): Promise<void> {
  // Unregister the JavaScript module from the Pyodide global namespace
  // https://pyodide.org/en/stable/usage/api/js-api.html#pyodide.unregisterJsModule
  pyodideClient.unregisterJsModule(moduleName)
  // Force-unload the previously-imported JavaScript module, if applicable
  await pyodideClient.runPythonAsync(`sys.modules.pop("${moduleName}", None)`)
}

export async function evaluatePython({
  pyodideClient,
  program,
  candles,
  addToOutput,
}: {
  pyodideClient: PyodideClient
  program: string
  candles: Candle[]
  addToOutput: (output: standardOut) => void
}): Promise<void> {
  (self as any).candles = candles

  pyodideClient.runPython(`
  from datetime import datetime
  from js import candles

  def default_converter(value, _ignored1, _ignored2):
    if value.constructor.name == "Date":
        return datetime.fromtimestamp(value.valueOf()/1000)
    return value
  
  bar_data = candles.to_py(default_converter=default_converter)
  `)

  try {
    const start = Date.now()
    // Python packages must be loaded manually for programs invoked with `runPythonAsync`
    // https://pyodide.org/en/stable/usage/api/js-api.html#pyodide.runPythonAsync
    await pyodideClient.loadPackagesFromImports(program)

    await pyodideClient.runPythonAsync(`
    import io
    sys.stdout = io.StringIO()
    sys.stderr = io.StringIO()
  `)
    await pyodideClient.runPythonAsync(program)
      .then((output: string) => {
        if (output !== undefined) { addToOutput({ type: 'out', value: output }) }
      })
    const end = Date.now()
    const elapsed = end - start
    console.info(`Evaluation took ${elapsed} ms`)
  } catch (err) {
    // TODO: this seems bad type assertion
    addToOutput({ type: 'error', value: (err as any).toString() })
    console.error('Evaluation error', err)
  }

  const stdout = pyodideClient.runPython('sys.stdout.getvalue()')
  if (stdout !== '' && stdout !== undefined) {
    addToOutput({ type: 'out', value: stdout })
  }
}
