import Big from 'big.js'
import { pipe } from 'fp-ts/function'
import * as $ from 'rxjs'
import * as E from 'fp-ts/Either'
import * as PathReporter from 'io-ts/lib/PathReporter'
import { match } from 'ts-pattern'
import type { KrakenAuth } from '..'
import type { Balances, HeldOrderDetails } from '../../'
import { CoinbaseTradepairs } from '../../coinbase/rest/codecs'
import { CoinbaseOrderDoneMessage, CoinbaseOrderMatchMessage, CoinbaseOrderOpenMessage } from '../../coinbase/websocket/codecs'
import { KrakenTradepairDataGroup, KrakenTradepairs } from '../rest/codecs'

import {
    KrakenOpenOrderWebsocketMessage,
    KrakenOpenOrderMessage,
    KrakenClosedOrderMessage,
    KrakenTradeMessage,
    KrakenUserWebsocketMessage,
    KrakenFilledOrderMessage,
} from './codecs'
import { cons } from 'shades'

/*
 * This is required to be at least 9 to display the user balances
 * in written out instead of numerial form. This will match that
 * of Coinbase.
 */
Big.NE = -9

// TODO: handle dropped messages
export const subscribeToUserBalances = ({
    auth,
    initialBalances,
    message,
    products,
    tradepairData
}: {
    auth: KrakenAuth
    initialBalances: Balances
    message: $.Observable<
        | KrakenUserWebsocketMessage
        | KrakenOpenOrderMessage
        | KrakenClosedOrderMessage
        | CoinbaseOrderOpenMessage
        | CoinbaseOrderDoneMessage
        | CoinbaseOrderMatchMessage
    /* | KrakenUserWebsocketMessage
    | KrakenOpenOrderWebsocketMessage
    | KrakenClosedOrderMessage
    | KrakenTradeMessage */
    >
    products: KrakenTradepairs,
    tradepairData: $.Observable<KrakenTradepairDataGroup>
}): $.Observable<Balances> => {
    return pipe(
        message,
        $.withLatestFrom(tradepairData),
        // With only relevant messages, track updates to the users balances
        // I think we need to ignore the first 50 trades from user websocket message and the first open
        // order message. This comes out to about 9. Should we round to 10?
        //$.skip(10),
        // $.map(([message, products]) => [message, products]),
        $.scan<[
            | KrakenUserWebsocketMessage
            | KrakenOpenOrderMessage
            | KrakenClosedOrderMessage
            | CoinbaseOrderOpenMessage
            | CoinbaseOrderDoneMessage
            | CoinbaseOrderMatchMessage, KrakenTradepairDataGroup],
            //KrakenUserWebsocketMessage | KrakenOpenOrderWebsocketMessage,
            { balances: Balances, heldOrderDetails: Map<string, HeldOrderDetails> }
        >(
            ({ balances, heldOrderDetails }, [message, tradepairData]) => {
                match(message)
                    .exhaustive()
                    .when(KrakenTradeMessage.is, (trades) => {
                        // We only filter by market orders here, limit orders on the openOrders stream.
                        // This stream shows limit orders but not when they are closed.
                        trades[0].map(trade => {
                            Object.values(trade).map((incomingTrade => {
                                if (incomingTrade.ordertype === "market") {

                                    const product = products.find((p) => p.wsname === incomingTrade.pair)

                                    const baseCurrency = product !== undefined ? product.base : ''
                                    const quoteCurrency = product !== undefined ? product.quote : ''

                                    const baseBalance = balances.get(baseCurrency)
                                    const quoteBalance = balances.get(quoteCurrency)

                                    const [readableBase, readableQuote] = incomingTrade.pair.split('/')
                                    const baseData = tradepairData.find((d) => {
                                        return d.altname === readableBase
                                    })

                                    const quoteData = tradepairData.find((d) => {
                                        return d.altname === readableQuote
                                    })

                                    match(incomingTrade.type)
                                        // Just made a market sell so remove the size from
                                        // available base balance and add the total minus fees to quote
                                        // DO NOT TOUCH THIS PART IS RIGHT!!!!!!! And has correct decimal truncation
                                        .with('sell', () => {
                                            //Fee in quote currency
                                            if (baseBalance != undefined) {
                                                baseBalance.available = baseBalance.available.minus(
                                                    incomingTrade.vol
                                                ).round(baseData?.decimals, Big.roundHalfUp)
                                                baseBalance.total = baseBalance.total.minus(
                                                    incomingTrade.vol
                                                ).round(baseData?.decimals, Big.roundHalfUp)
                                            }
                                            if (quoteBalance != undefined) {
                                                // We need to subtract fees since they are no longer part of our balance
                                                // We need to round the cost and round the fee and then round again, which seems crazy!!!
                                                quoteBalance.available = quoteBalance.available.add(incomingTrade.cost.round(quoteData?.decimals, Big.roundHalfUp).minus(incomingTrade.fee.round(quoteData?.decimals, Big.roundHalfUp))).round(quoteData?.decimals, Big.roundHalfUp)
                                                quoteBalance.total = quoteBalance.total.add(incomingTrade.cost.round(quoteData?.decimals, Big.roundHalfUp).minus(incomingTrade.fee.round(quoteData?.decimals, Big.roundHalfUp))).round(quoteData?.decimals, Big.roundHalfUp)
                                            }
                                        })
                                        // Just made a market buy so add size to available base
                                        // and remove the size and fee (total cost) from available
                                        // and total quote balance
                                        // DO NOT TOUCH THIS PART IS RIGHT!!!!!! And has correct decimal truncation
                                        .with('buy', () => {
                                            //Fee in base currency
                                            const fee = incomingTrade.fee.div(incomingTrade.price).round(baseData?.decimals, Big.roundHalfUp)

                                            if (baseBalance !== undefined) {
                                                baseBalance.available = baseBalance.available.plus(
                                                    incomingTrade.vol.minus(fee)
                                                ).round(baseData?.decimals, Big.roundHalfUp)
                                                baseBalance.total = baseBalance.total.plus(
                                                    incomingTrade.vol.minus(fee)
                                                ).round(baseData?.decimals, Big.roundHalfUp)
                                            }
                                            if (quoteBalance != undefined) {
                                                quoteBalance.available = quoteBalance.available.minus(
                                                    incomingTrade.cost.round(quoteData?.decimals, Big.roundHalfUp)
                                                ).round(quoteData?.decimals, Big.roundHalfUp)
                                                quoteBalance.total = quoteBalance.total.minus(
                                                    incomingTrade.cost.round(quoteData?.decimals, Big.roundHalfUp)
                                                ).round(quoteData?.decimals, Big.roundHalfUp)
                                            }
                                        })
                                        .run()
                                }
                            }))
                        })
                    })
                    .when(KrakenOpenOrderMessage.is, (openOrderMessage) => {
                        // This stream is for limit orders. When they open and are closed
                        openOrderMessage[0].map(openOrder => {

                            for (const [id, open] of Object.entries(openOrder)) {

                                const product = products.find((p) => p.wsname === open.descr.pair)

                                const baseCurrency = product !== undefined ? product.base : ''
                                const quoteCurrency = product !== undefined ? product.quote : ''

                                const [readableBase, readableQuote] = open.descr.pair.split('/')
                                const baseData = tradepairData.find((d) => {
                                    return d.altname === readableBase
                                })

                                const quoteData = tradepairData.find((d) => {
                                    return d.altname === readableQuote
                                })

                                const heldOrder: HeldOrderDetails = {
                                    id: id,
                                    price: open.descr.price,
                                    type: open.descr.type,
                                    base: baseCurrency,
                                    quote: quoteCurrency,
                                    pair: open.descr.pair,
                                    vol: open.vol
                                }

                                heldOrderDetails.set(id, heldOrder)
                                const baseBalance = balances.get(baseCurrency)
                                const quoteBalance = balances.get(quoteCurrency)

                                match(open.descr.type)
                                    .exhaustive()
                                    .with('buy', () => {
                                        //This does not include fees. This is the same as Kraken
                                        const heldAmount = open.vol.mul(open.descr.price)

                                        if (quoteBalance !== undefined) {
                                            quoteBalance.available = quoteBalance.available.minus(heldAmount)//.round(quoteData?.decimals, Big.roundUp)
                                            quoteBalance.held = quoteBalance.held.plus(heldAmount)//.round(quoteData?.decimals, Big.roundUp)
                                        }
                                    })
                                    .with('sell', () => {
                                        const heldAmount = open.vol

                                        if (baseBalance != undefined) {
                                            baseBalance.available = baseBalance.available.minus(heldAmount)//.round(baseData?.decimals, Big.roundUp)
                                            baseBalance.held = baseBalance.held.plus(heldAmount)//.round(baseData?.decimals, Big.roundUp)
                                        }
                                    })
                                    .run()
                            }
                        })
                    })
                    .when(KrakenFilledOrderMessage.is, (filledOrderMessage) => {
                        //Only do limit orders. Market orders will be handled in the trade message
                        //We are only doing filled limit orders. Ones that are close due to cancelation are in closed order message
                        //TODO: what about orders that are partially filled? should we do vol-vol_exec?
                        filledOrderMessage[0].map(filledOrder => {

                            for (const [id, filled] of Object.entries(filledOrder)) {
                                const heldOrderById = heldOrderDetails.get(id)
                                
                                if (heldOrderById !== undefined) {
                                    const baseBalance = balances.get(heldOrderById.base)
                                    const quoteBalance = balances.get(heldOrderById.quote)

                                    const [readableBase, readableQuote] = heldOrderById.pair.split('/')
                                    const baseData = tradepairData.find((d) => {
                                        return d.altname === readableBase
                                    })
    
                                    const quoteData = tradepairData.find((d) => {
                                        return d.altname === readableQuote
                                    })
    

                                    match(heldOrderById.type)
                                        .exhaustive()
                                        .with('buy', () => {
                                            //Fee in base currncy
                                            const fee = filled.fee.div(filled.avg_price).round(baseData?.decimals, Big.roundHalfUp)

                                            const heldAmount = filled.vol_exec.minus(fee)
                                            const heldAmountInQuote = heldOrderById?.vol.mul(heldOrderById.price)


                                            if (quoteBalance != undefined) {
                                                //quoteBalance.available = quoteBalance.available.round(quoteData?.decimals, Big.roundHalfUp)
                                                quoteBalance.held = quoteBalance.held.minus(heldAmountInQuote).round(quoteData?.decimals, Big.roundHalfUp)
                                                quoteBalance.total = quoteBalance.total.minus(filled.cost).round(quoteData?.decimals, Big.roundHalfUp)
                                            }

                                            if (baseBalance != undefined) {
                                                baseBalance.available = baseBalance.available.plus(heldAmount).round(baseData?.decimals, Big.roundHalfUp)
                                                baseBalance.total = baseBalance.total.plus(heldAmount).round(baseData?.decimals, Big.roundHalfUp)
                                            }
                                        })
                                        .with('sell', () => {
                                            //Fee in quote currncy
                                            const heldAmount = filled.vol_exec
                                            const sellAmount = filled.cost.minus(filled.fee).round(quoteData?.decimals, Big.roundHalfUp)

                                            if (baseBalance != undefined) {
                                                baseBalance.held = baseBalance.held.minus(heldAmount).round(baseData?.decimals, Big.roundHalfUp)
                                                baseBalance.total = baseBalance.total.minus(heldAmount).round(baseData?.decimals, Big.roundHalfUp)
                                            }

                                            if (quoteBalance != undefined) {
                                                quoteBalance.available = quoteBalance.available.plus(sellAmount).round(quoteData?.decimals, Big.roundHalfUp)
                                                quoteBalance.total = quoteBalance.total.plus(sellAmount).round(quoteData?.decimals, Big.roundHalfUp)
                                            }

                                        })
                                        .run()
                                    // We don't want to delete until fully filled
                                    // heldOrderDetails.delete(id)
                                } 
                            }
                        })
                    })
                    .when(KrakenClosedOrderMessage.is, (closedOrderMessage) => {
                        closedOrderMessage[0].map(closedOrder => {

                            for (const [id, closed] of Object.entries(closedOrder)) {
                                const heldOrderById = heldOrderDetails.get(id)

                                if (heldOrderById !== undefined) {

                                    if (closed.status === 'canceled') {
                                        const baseBalance = balances.get(heldOrderById.base)
                                        const quoteBalance = balances.get(heldOrderById.quote)

                                        const [readableBase, readableQuote] = heldOrderById.pair.split('/')
                                        const baseData = tradepairData.find((d) => {
                                            return d.altname === readableBase
                                        })
        
                                        const quoteData = tradepairData.find((d) => {
                                            return d.altname === readableQuote
                                        })

                                        match(heldOrderById.type)
                                            .exhaustive()
                                            .with('buy', () => {
                                                const heldAmount = heldOrderById.vol.mul(heldOrderById.price)
                                                if (quoteBalance != undefined) {
                                                    quoteBalance.available = quoteBalance.available.plus(heldAmount).round(quoteData?.decimals, Big.roundHalfUp)
                                                    quoteBalance.held = quoteBalance.held.minus(heldAmount).round(quoteData?.decimals, Big.roundHalfUp)
                                                }
                                            })
                                            .with('sell', () => {
                                                const heldAmount = heldOrderById.vol

                                                if (baseBalance != undefined) {
                                                    baseBalance.available = baseBalance.available.plus(heldAmount).round(baseData?.decimals, Big.roundHalfUp)
                                                    baseBalance.held = baseBalance.held.minus(heldAmount).round(baseData?.decimals, Big.roundHalfUp)
                                                }

                                            })
                                            .run()
                                    }
                                    //Closed orders should be updated in the filled category above.
                                    heldOrderDetails.delete(id)
                                } 
                            }
                        })
                    })
                    .otherwise(() => { })
                //TODO: add when limit orders are filled (matched). Right now we are just handling when they are cancelled
                return {
                    balances: balances,
                    heldOrderDetails: heldOrderDetails
                }
            },
            {
                balances: initialBalances,
                heldOrderDetails: new Map<string, HeldOrderDetails>()
            },
        ),
        // Promote `scan` into a poor-man's `loop` -- emit the same value as the
        // loop-back and then separately extract the desired value
        $.map((_) => _.balances),
        // DISCUSS: do we want to emit identical/unchanged values?
    )
}
