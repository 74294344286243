import { KrakenAuth } from ".."
import * as $ from 'rxjs'
import { pipe } from 'fp-ts/function'
import { KrakenClosedOrderMessage, KrakenFilledOrderMessage, KrakenOpenOrderMessage } from "./codecs"
import { LimitOrderBook, zeroLimitOrderBook } from "../../LimitOrderBook"
import { match } from 'ts-pattern'
import { LimitOrder } from '../../LimitOrderBook'

export const subscribeToUserOrderBook = ({
    message
}: {
    message: $.Observable<
        | KrakenOpenOrderMessage
        | KrakenClosedOrderMessage
        | KrakenFilledOrderMessage
    >
}) => {
    return pipe(
        message,
        $.scan<
            | KrakenOpenOrderMessage
            | KrakenFilledOrderMessage
            | KrakenClosedOrderMessage,
            LimitOrderBook
        >(
            (lob, message) => {

                match(message)
                    .exhaustive()
                    // Add the limit order to our limit order book
                    .when(KrakenOpenOrderMessage.is, (openOrders) => {
                        openOrders[0].map(openOrder => {

                            for (const [id, open] of Object.entries(openOrder)) {

                                const limitOrder: LimitOrder = {
                                    price: open.descr.price,
                                    filledQuantity: open.vol_exec,
                                    remainingQuantity: open.vol,
                                    totalQuantity: open.vol,
                                    ID: id,
                                    productID: open.descr.pair,
                                }

                                const price = open.descr.price.toNumber()
                                match(open.descr.type)
                                    .exhaustive()
                                    .with('buy', () => {
                                        let bids = lob.bids.get(price)
                                        if (bids === undefined) {
                                            // no array of orders at this level so create the array
                                            bids = []
                                            lob.bids.set(price, bids)
                                        }

                                        //The bid is already there
                                        if (bids.some((order) => order.ID === limitOrder.ID)) {
                                            return
                                        }
                                        bids.push(limitOrder)
                                    })
                                    .with('sell', () => {
                                        let asks = lob.asks.get(price)
                                        if (asks === undefined) {
                                            // no array of orders at this level so create the array
                                            asks = []
                                            lob.asks.set(price, asks)
                                        }

                                        //The ask is already there
                                        if (asks.some((order) => order.ID === limitOrder.ID)) {
                                            return
                                        }
                                        asks.push(limitOrder)
                                    })
                                    .run()
                            }
                        })
                    })
                    .when(KrakenFilledOrderMessage.is, (filledOrder) => {
                        filledOrder[0].map(fill => {
                            for (const [id, fillData] of Object.entries(fill)) {

                                [...lob.asks]
                                    .filter(([_key, value]) => value.filter((order) => order.ID === id)
                                        .map(filled => {

                                            const price = filled.price.toNumber()

                                            const ordersAtLevel = lob.asks.get(price)

                                            if (ordersAtLevel === undefined) {
                                                console.warn(`Expected to find orders to update at price ${price} or a market order`)
                                                return
                                            }

                                            const order = ordersAtLevel.find((order) => order.ID === id)
                                            if (order === undefined) {
                                                console.warn(
                                                    `Expected to find ${id} at price ${price} to update or a market order`,
                                                )
                                                return
                                            }

                                            order.filledQuantity = order.filledQuantity.plus(fillData.vol_exec)
                                            order.remainingQuantity = order.remainingQuantity.minus(fillData.vol_exec)
                                        })
                                    );

                                [...lob.bids]
                                    .filter(([_key, value]) => value.filter((order) => order.ID === id)
                                        .map(filled => {

                                            const price = filled.price.toNumber()

                                            const ordersAtLevel = lob.asks.get(price)

                                            if (ordersAtLevel === undefined) {
                                                console.warn(`Expected to find orders to update at price ${price} or a market order`)
                                                return
                                            }

                                            const order = ordersAtLevel.find((order) => order.ID === id)
                                            if (order === undefined) {
                                                console.warn(
                                                    `Expected to find ${id} at price ${price} to update or a market order`,
                                                )
                                                return
                                            }

                                            order.filledQuantity = order.filledQuantity.plus(fillData.vol_exec)
                                            order.remainingQuantity = order.remainingQuantity.minus(fillData.vol_exec)
                                        })
                                    )

                            }
                        })
                    })
                    // Remove the limit order from our limit order book because it is closed or cancelled
                    .when(KrakenClosedOrderMessage.is, (doneOrders) => {
                        // We are triple subscribings to user feed
                        doneOrders[0].map(doneOrder => {

                            for (const [id, _doneStatus] of Object.entries(doneOrder)) {

                                [...lob.asks]
                                    .filter(([_key, value]) => value.filter((order) => order.ID === id)
                                        .map(done => {

                                            const price = done.price?.toNumber()

                                            const ordersAtLevel = lob.asks.get(price)

                                            if (ordersAtLevel === undefined) {
                                                console.warn(`Expected to find orders to complete at price ${price} or a market order`)
                                                return
                                            }

                                            // assume the order exists only once in the ordersAtLevel array
                                            const index = ordersAtLevel.findIndex((order) => order.ID === done.ID)
                                            if (index === -1) {
                                                console.warn(
                                                    `Expected to find ${done.ID} at price ${price} to mark as done or a market order`,
                                                )
                                                return
                                            }
                                            // delete one element starting at `index`
                                            ordersAtLevel.splice(index, 1)

                                            // if that was the last order at this level, drop all references
                                            // to the orders array in our limit order book so it is garbage
                                            // collected
                                            if (ordersAtLevel.length === 0) {
                                                lob.asks.delete(price)
                                            }
                                        })
                                    );

                                [...lob.bids]
                                    .filter(([_key, value]) => value.filter((order) => order.ID === id)
                                        .map(done => {

                                            const price = done.price?.toNumber()

                                            const ordersAtLevel = lob.bids.get(price)

                                            if (ordersAtLevel === undefined) {
                                                console.warn(`Expected to find orders to complete at price ${price} or a market order`)
                                                return
                                            }

                                            // assume the order exists only once in the ordersAtLevel array
                                            const index = ordersAtLevel.findIndex((order) => order.ID === done.ID)
                                            if (index === -1) {
                                                console.warn(
                                                    `Expected to find ${done.ID} at price ${price} to mark as done or a market order`,
                                                )
                                                return
                                            }
                                            // delete one element starting at `index`
                                            ordersAtLevel.splice(index, 1)

                                            // if that was the last order at this level, drop all references
                                            // to the orders array in our limit order book so it is garbage
                                            // collected
                                            if (ordersAtLevel.length === 0) {
                                                lob.bids.delete(price)
                                            }
                                        })
                                    )

                            }
                        })
                    })
                    .run()

                return lob
            },
            zeroLimitOrderBook(),
        )
    )
}