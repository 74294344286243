import { pipe } from 'fp-ts/lib/function'
import { match } from 'ts-pattern'

import { Gateway, KrakenGateway, LimitOrderBook, OrderResponse } from '../gateway'

import { EventLogEvent } from './event-log'

const $ = require('jquery')

export function deleteOrders(
  orderBook: LimitOrderBook,
  eventlog: EventLogEvent[],
  tradepair: string,
  gateway: Gateway,
) {
  for (const [_key, value] of [...orderBook.bids, ...orderBook.asks]) {
    value
      .filter((order) => order.productID === tradepair)
      .map((order) => {
        eventlog.push({
          type: 'order delete requested',
          order: order,
        })
        gateway.deleteOrder({ productId: tradepair, orderId: order.ID })
          .then((response) => {
            match(response.type)
              .exhaustive()
              .with('ok', () => {
                eventlog.push({
                  type: 'order deleted successfully',
                  order: order,
                })
              })
              .with('error', () => {
                // I don't think we want to show this. Only submit orders
                // should have order submission error notifications.
                // Delete ordrs will heal itself or it has already been
                // filled/delted and the user doesn't need to know this.
                /* const value = new Date().toLocaleTimeString()
                document.getElementById('toast-date')!.innerHTML = value
                $('.toast').toast('show') */

                eventlog.push({
                  type: 'order deletion failed',
                  order: order,
                })
              })
              .run()
          })
          // just in case
          .catch(() => {
            eventlog.push({
              type: 'order deletion failed',
              order: order,
            })
          })
      })
    // return immediately and on next run of dispatch orders
    // see if the eventlog has received a response from Coinbase
  }
}

export function stopAndDeleteOrders(
  orderBook: LimitOrderBook,
  tradepair: string,
  gateway: Gateway,
) {
  for (const [_key, value] of [...orderBook.bids, ...orderBook.asks]) {
    value
      .filter((order) => order.productID === tradepair)
      .map((order) => {
        gateway.deleteOrder({ productId: tradepair, orderId: order.ID })
          .then((response) => {
            match(response.type)
              .exhaustive()
              .with('ok', () => {
                return
              })
              .with('error', () => {
                const value = new Date().toLocaleTimeString()
                document.getElementById('toast-date')!.innerHTML = value
                $('.toast').toast('show')
              })
              .run()
          })
          // just in case
          .catch(() => {
            const value = new Date().toLocaleTimeString()
            document.getElementById('toast-date')!.innerHTML = value
            $('.toast').toast('show')
          })
      })
  }
}

export function stopAndDeleteKrakenOrders(
  orderBook: LimitOrderBook,
  tradepair: string,
  gateway: KrakenGateway,
) {
  for (const [_key, value] of [...orderBook.bids, ...orderBook.asks]) {
    value
      .filter((order) => order.productID === tradepair)
      .map((order) => {
        gateway.deleteOrder({ txidOrders: [order.ID] }).subscribe((response) => {
            match(response.type)
              .exhaustive()
              .with('ok', () => {
                return
              })
              .with('error', () => {
                const value = new Date().toLocaleTimeString()
                document.getElementById('toast-date')!.innerHTML = value
                $('.toast').toast('show')
              })
              .run()
          })
      })
  }
}

export function deleteKrakenOrders(
  orderBook: LimitOrderBook,
  eventlog: EventLogEvent[],
  tradepair: string,
  gateway: KrakenGateway,
) {
  for (const [_key, value] of [...orderBook.bids, ...orderBook.asks]) {
    const orders = value
      .filter((order) => order.productID === tradepair)
      .map((order) => order.ID)

    eventlog.push({
      type: 'order delete requested',
      order: orders,
    })

    gateway.deleteOrder({ txidOrders: orders }).subscribe((response) => {
      match(response.type)
      .exhaustive()
      .with('ok', () => {
        eventlog.push({
          type: 'order deleted successfully',
          order: orders,
        })
      })
      .with('error', () => {
        // I don't think we want to show this. Only submit orders
        // should have order submission error notifications.
        // Delete ordrs will heal itself or it has already been
        // filled/delted and the user doesn't need to know this.
        /* const value = new Date().toLocaleTimeString()
        document.getElementById('toast-date')!.innerHTML = value
        $('.toast').toast('show') */

        eventlog.push({
          type: 'order deletion failed',
          order: orders,
        })
      })
      .run()
    })
    // return immediately and on next run of dispatch orders
    // see if the eventlog has received a response from Kraken
  }
}