import { Big } from 'big.js'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'

const createBig = (value: string) =>
  E.tryCatch(
    () => Big(value),
    () => new Error(`Unable to create big from ${value}`),
  )

export const BigFromString = new t.Type<Big, string, unknown>(
  'BigFromString',
  (unknown): unknown is Big => {
    if (unknown === null || unknown === undefined) {
      return false
    }
    return Array.isArray((unknown as Big).c)
      && (typeof (unknown as Big).e === 'number')
      && (typeof (unknown as Big).s === 'number')
  },
  (unknown, context) => {
    return pipe(
      t.string.decode(unknown),
      E.chainW(createBig),
      E.match(
        () =>
          t.failure(unknown, context, 'Unable to create Big from ' + String(unknown)),
        t.success,
      ),
    )
  },
  (big) => big.toString(),
)
