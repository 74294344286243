import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as PathReporter from 'io-ts/lib/PathReporter'

import { restEndpoint } from '../endpoints'

import { CoinbaseTradepairs } from './codecs'

export const getTradepairs = ({ isTestnet }: { isTestnet: boolean }) =>
  async (): Promise<CoinbaseTradepairs> => {
    const baseUrl = restEndpoint(isTestnet)

    return fetch(
      `${baseUrl}/products`,
    )
      .then(async (response) => response.json())
      .then((rawResponse) =>
        pipe(
          CoinbaseTradepairs.decode(rawResponse),
          E.getOrElseW((errors) => {
            throw new Error(
              // eslint-disable-next-line @typescript-eslint/quotes
              `Received unexpected data from coinbase's 'product' endpoint: `
                + PathReporter.failure(errors).join('\n'),
            )
          }),
        ),
      )
  }
