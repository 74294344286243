import { Layouts } from 'react-grid-layout'

import { GridItemKey, LocalStorageKey } from './codecs'

export type GridLayout = {
  visible: Layouts
  hidden: Layouts
}

/**
 * Key used to look up the grid layout configuration from local storage
 */
export const GridLayoutKey: LocalStorageKey = 'grid-layout' as LocalStorageKey

/**
 * GridLayout key for the Timeseries Chart component
 */
export const ChartKey: GridItemKey = 'Chart' as GridItemKey

/**
 * GridLayout key for the Timeseries Chart component
 */
export const EditorKey: GridItemKey = 'Editor' as GridItemKey

/**
 * GridLayout key for the Timeseries Chart component
 */
export const ConsoleKey: GridItemKey = 'Console' as GridItemKey

/**
 * GridLayout key for the Timeseries Chart component
 */
export const OrdersKey: GridItemKey = 'Orders' as GridItemKey

// DISCUSS and FIXME: how do we know the initial layout is `lg`?
// What if we load this on a mobile device first?
/**
 * We maintain an invariant in this data structure:
 *
 * For each breakpoint (the index in the `visible` and `hidden` objects),
 * each grid item is present in exactly one of the `visible` or `hidden` object.
 */
export const DEFAULT_GRID_LAYOUT: GridLayout = {
  visible: {
    lg: [
      {
        w: 2,
        h: 4,
        x: 0,
        y: 0,
        i: '1',
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 12,
        h: 18,
        x: 0,
        y: 4,
        i: ChartKey,
        minW: 5,
        minH: 9,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 13,
        x: 6,
        y: 22,
        i: ConsoleKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 4,
        x: 2,
        y: 0,
        i: OrdersKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 13,
        x: 0,
        y: 22,
        i: EditorKey,
        minW: 6,
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    md: [
      {
        w: 3,
        h: 5,
        x: 0,
        y: 0,
        i: '1',
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 13,
        x: 0,
        y: 5,
        i: ChartKey,
        minW: 5,
        minH: 9,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 10,
        x: 6,
        y: 18,
        i: ConsoleKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 7,
        h: 5,
        x: 3,
        y: 0,
        i: OrdersKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 18,
        i: EditorKey,
        minW: 6,
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        w: 2,
        h: 5,
        x: 0,
        y: 0,
        i: '1',
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 13,
        x: 0,
        y: 5,
        i: ChartKey,
        minW: 5,
        minH: 9,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 7,
        x: 0,
        y: 27,
        i: ConsoleKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 2,
        y: 0,
        i: OrdersKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 9,
        x: 0,
        y: 18,
        i: EditorKey,
        minW: 6,
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        w: 4,
        h: 5,
        x: 0,
        y: 0,
        i: '1',
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 13,
        x: 0,
        y: 10,
        i: ChartKey,
        minW: 5,
        minH: 9,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 32,
        i: ConsoleKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 5,
        i: OrdersKey,
        minW: 2,
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 23,
        i: EditorKey,
        minW: 6,
        minH: 3,
        moved: false,
        static: false,
      },
    ],
  },

  hidden: {
    lg: [],
  },
}
