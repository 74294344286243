import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as PathReporter from 'io-ts/lib/PathReporter'
import * as $ from 'rxjs'
import { WebSocketSubject } from 'rxjs/webSocket'
import { match } from 'ts-pattern'
import type { KrakenAuth } from '..'
import {
  KrakenHeartbeatMessage,
  KrakenSubscriptionMessage,
  KrakenUserWebsocketMessage,
  KrakenConnectionMessage,
  KrakenTradeMessage,
  KrakenFirstTradeMessage
} from '../websocket/codecs'

const listenToUserFeedMessages = ({
  userFeedWebsocket,
}: {
  userFeedWebsocket: $.Observable<unknown>
}): $.Observable<KrakenTradeMessage> => {
  return pipe(
    userFeedWebsocket,
    $.mergeMap((websocketMessage) => {
      // DEBUG:
      /* if ((websocketMessage as any).type !== 'heartbeat') {
        console.log('Raw LOB websocket message:', websocketMessage)
      } */
      return pipe(
        KrakenUserWebsocketMessage.decode(websocketMessage),
        E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
        // Decode the websocket message and filter out messages we do
        // not require to track the user's current balances
        E.map((message) =>
          match<
            KrakenUserWebsocketMessage,
            $.Observable<
              any//  | CoinbaseOrderOpenMessage
            >
          >(message)
            .when(KrakenHeartbeatMessage.is, () => $.EMPTY)
            .when(KrakenFirstTradeMessage.is, () => {
              return $.EMPTY
            })
            .when(KrakenTradeMessage.is, (trade) => {
              return $.of(trade)
            })
            .when(KrakenSubscriptionMessage.is, (o) => $.of(o))
            .when(KrakenConnectionMessage.is, () => $.EMPTY)
            .otherwise(() => {
              console.warn('unmatched own trade message:', message)
              return $.EMPTY
            }),
        ),
        E.getOrElseW((error) => {
          throw new Error('User own trades feed error: ' + error)
        }),
      )
    }),
  )
}

export const subscribeToUserFeedWebsocket = ({
  auth,
  privateWebsocket,
}: {
  auth: KrakenAuth | undefined
  privateWebsocket: WebSocketSubject<unknown>
}) =>
  () => {
    // This occurs when the user either doesn't have auth set up or isn't active
    if (auth === undefined) {
      return $.of()
    }

    const subscribeToUserFeedMessage = {
      event: 'subscribe',
      subscription: {
        "name": "ownTrades",
        "token": auth.token
      }
    } as const

    const unsubscribeToUserFeedMessage = {
      event: 'unsubscribe',
      subscription: {
        "name": "ownTrades",
        "token": auth.token
      }
    } as const

    const userFeedWebsocket = pipe(
      privateWebsocket.multiplex(
        () => (subscribeToUserFeedMessage),
        () => (unsubscribeToUserFeedMessage),
        (message: any): boolean => {
          return message.channelName === 'ownTrades' || message[1] === 'ownTrades'
        }
      ),
      $.retry(),
    )

    return listenToUserFeedMessages({
      userFeedWebsocket,
    })
  }
