import { pipe } from 'fp-ts/function'
import * as $ from 'rxjs'
import { match } from 'ts-pattern'

import type { CoinbaseAuth } from '..'
import type { LimitOrderBook } from '../..'
import { LimitOrder, zeroLimitOrderBook } from '../../LimitOrderBook'
import { CoinbaseOrders } from '../rest/codecs'
import {
  CoinbaseOrderDoneMessage,
  CoinbaseOrderMatchMessage,
  CoinbaseOrderOpenMessage,
} from '../websocket/codecs'
import { subscribeToUserOrderBook } from '../websocket/user-order-book'

const getLobOpenOrders = async (
  orders: Promise<CoinbaseOrders>,
): Promise<LimitOrderBook> => {
  return orders.then((orders) =>
    orders.data.reduce<LimitOrderBook>(
      (lob, order) => {
        // create a standardized limit order
        const limitOrder: LimitOrder = {
          price: order.price.price,
          filledQuantity: order.filled_size,
          remainingQuantity: order.size.minus(order.filled_size),
          totalQuantity: order.size,
          ID: order.id,
          productID: order.product_id.product,
        }
        // add the standardized limit order to our limit order book
        const price: number = order.price.price.toNumber() // UNSAFE
        match(order.side)
          .exhaustive()
          .with('buy', () => {
            let existingOrders = lob.bids.get(price)
            if (existingOrders === undefined) {
              // no orders found at this level yet, so create an push an array
              existingOrders = []
              lob.bids.set(price, existingOrders)
            }
            existingOrders.push(limitOrder)
          })
          .with('sell', () => {
            let existingOrders = lob.asks.get(price)
            if (existingOrders === undefined) {
              // no orders found at this level yet, so create an push an array
              existingOrders = []
              lob.asks.set(price, existingOrders)
            }
            existingOrders.push(limitOrder)
          })
          .run()
        return lob
      },
      zeroLimitOrderBook(),
    ),
  )
}

export const subscribeToOrderBook = ({
  isTestnet,
  auth,
}: {
  isTestnet: boolean
  auth: CoinbaseAuth | undefined
}) =>
  (parameters: {
    message: $.Observable<
      | CoinbaseOrderOpenMessage
      | CoinbaseOrderDoneMessage
      | CoinbaseOrderMatchMessage
    >
    openOrders: Promise<CoinbaseOrders>
  }): $.Observable<LimitOrderBook> => {
    /*
    * Get the user's orders
    */
    // This occurs when the user either doesn't have auth set up or isn't active
    if (auth === undefined) {
      return $.of(zeroLimitOrderBook())
    }

    const orderBook$ = pipe(
      $.from(getLobOpenOrders(parameters.openOrders)),
      $.mergeMap((initialLimitOrderBook) =>
        $.concat(
          $.of(initialLimitOrderBook),
          subscribeToUserOrderBook({
            isTestnet,
            auth,
            initialLimitOrderBook,
            message: parameters.message,
          }),
        ),
      ),
    )

    return orderBook$
  }
