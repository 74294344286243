import { webSocket } from 'rxjs/webSocket'

import { GatewayConstructor } from '../'

import { websocketEndpoint } from './endpoints'
import { deleteOrder } from './rest/delete-order'
import { getTradepairs } from './rest/get-tradepairs'
import { getOpenOrders } from './rest/orders'
import { submitLimitOrder } from './rest/submit-limit-order'
import { submitMarketOrder } from './rest/submit-market-order'
import { subscribeToBalances } from './stream/balances'
import { subscribeToCandles } from './stream/candles'
import { subscribeToOrderBook } from './stream/orders'
import { subscribeToUserFeedWebsocket } from './stream/user-feed'

export interface CoinbaseAuth {
  apiPassphrase: string
  apiKey: string
  apiSecret: string
}

export const CoinbaseGateway: GatewayConstructor<CoinbaseAuth | undefined> = (
  { isTestnet, auth },
) => {
  const baseUrl = websocketEndpoint(isTestnet)

  const mainWebsocket = webSocket(baseUrl)

  return {
    subscribeToCandles: subscribeToCandles({ isTestnet, mainWebsocket }),
    subscribeToBalances: subscribeToBalances({ isTestnet, auth }),
    subscribeToOrderBook: subscribeToOrderBook({ isTestnet, auth }),
    subscribeToUserFeed: subscribeToUserFeedWebsocket({
      isTestnet,
      auth,
      mainWebsocket,
    }),
    submitMarketOrder: submitMarketOrder({ isTestnet }),
    submitLimitOrder: submitLimitOrder({ isTestnet }),
    getTradepairs: getTradepairs({ isTestnet }),
    getOpenOrders: getOpenOrders({ isTestnet, auth }),
    deleteOrder: deleteOrder({ isTestnet }),
  }
}
