import * as t from 'io-ts'

export const CoinbaseOrderStatus = t.keyof(
  {
    done: null,
    open: null,
    pending: null,
  },
  'CoinbaseOrderStatus',
)
export type CoinbaseOrderStatus = t.TypeOf<typeof CoinbaseOrderStatus>
