import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { NonEmptyString } from 'io-ts-types'

const CoinbaseCurrencySeparator = '-' as const

interface CoinbaseRawProductIDBrand {
  readonly CoinbaseRawProductID: unique symbol
}

const CoinbaseRawProductID = t.brand(
  NonEmptyString,
  (s): s is t.Branded<NonEmptyString, CoinbaseRawProductIDBrand> => {
    // check that there is only one hyphen
    const occurrences = s.split(CoinbaseCurrencySeparator).length - 1
    if (occurrences != 1) {
      return false
    }

    const indexOfCurrencySeparator = s.indexOf(CoinbaseCurrencySeparator)
    // and that the product ID contains at least one char in the base currency and in the quote currency
    return 1 <= indexOfCurrencySeparator && indexOfCurrencySeparator < (s.length - 1)
  },
  'CoinbaseRawProductID',
)
export type CoinbaseRawProductID = t.TypeOf<typeof CoinbaseRawProductID>

export interface CoinbaseProductID {
  product: CoinbaseRawProductID
  baseCurrency: NonEmptyString
  quoteCurrency: NonEmptyString
}

/**
 * We use this dummy for the generated type-guard function
 */
const _CoinbaseProductID = t.type(
  {
    product: CoinbaseRawProductID,
    baseCurrency: NonEmptyString,
    quoteCurrency: NonEmptyString,
  },
  'DummyCoinbaseProductID',
)

// REFACTOR: rename CoinbaseProductIDFromString
export const CoinbaseProductID = new t.Type<CoinbaseProductID, NonEmptyString, unknown>(
  'CoinbaseProductID',
  _CoinbaseProductID.is.bind(null),
  (u, c) => {
    const decodedRawProductID = CoinbaseRawProductID.decode(u)
    if (E.isLeft(decodedRawProductID)) {
      return t.failure(u, c)
    }
    const rawProductID = decodedRawProductID.right

    const [baseCurrency, quoteCurrency] = rawProductID.split(
      CoinbaseCurrencySeparator,
    )
    // The `NonEmptyString` checks are performed in `CoinbaseRawProductID`
    return t.success({
      product: rawProductID,
      baseCurrency: baseCurrency as NonEmptyString,
      quoteCurrency: quoteCurrency as NonEmptyString,
    })
  },
  (productID) => productID.product,
)
