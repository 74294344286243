import Big from 'big.js'

import { defaultTakerFeeRate } from '../gateway/coinbase/fees'
import { MarketOrderType } from '../types/index'

/*
 * This is required to be at least 9 to display the user balances
 * in written out instead of numerial form. This will match that
 * of Coinbase.
 */
Big.NE = -9

const createSellOrder = (
  have: Big,
  need: Big,
  baseBalance: Big,
  tradepair: string,
  increment: number,
  candlePrice: number,
): MarketOrderType => {
  const exposureNeeded = (have.minus(need)).div(have)

  const totalQuantity = exposureNeeded.times(baseBalance)

  const total = totalQuantity.minus(totalQuantity.mod(increment))
  const quantity = total.valueOf()

  const fee = total.mul(defaultTakerFeeRate.div(100))
  const totalWithoutFees = total.minus(fee)
  const amount = totalWithoutFees.mul(candlePrice)

  return {
    side: 'sell',
    quantity,
    tradepair,
    amount,
  }
}

const createBuyOrder = (
  have: Big,
  need: Big,
  tradepair: string,
  increment: number,
  candlePrice: number,
): MarketOrderType => {
  const totalQuantity = need.minus(have)

  const total = totalQuantity.minus(totalQuantity.mod(increment))
  const quantity = total.valueOf()

  const fee = total.mul(defaultTakerFeeRate.div(100))
  const totalWithoutFees = total.minus(fee)
  const amount = totalWithoutFees.div(candlePrice)
  return {
    side: 'buy',
    quantity,
    tradepair,
    amount,
  }
}

export default function getMarketOrder(
  baseBalanceInBaseCurrency: Big,
  baseBalanceInQuoteCurrency: Big,
  tradepair: string,
  baseIncrement: number,
  quoteIncrement: number,
  needQuanity: Big,
  candlePrice: number,
) {
  const order = baseBalanceInQuoteCurrency.gt(needQuanity)
    ? createSellOrder(
      baseBalanceInQuoteCurrency,
      needQuanity,
      baseBalanceInBaseCurrency,
      tradepair,
      baseIncrement,
      candlePrice,
    )
    : createBuyOrder(
      baseBalanceInQuoteCurrency,
      needQuanity,
      tradepair,
      quoteIncrement,
      candlePrice,
    )

  return order
}
