import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import React, { useEffect, useState } from 'react'
import { Observable, scan, Subscription, tap } from 'rxjs'

import { zeroCandle } from '../gateway'
import { useStore } from '../store'
import type { Candle, CandleEvent, ReadableTimeframe, KrakenReadableTimeframe } from '../types/index'
import {
  barData,
  CandleSeriesName,
  createChart,
  CrosshairMode,
  IChartApi,
  IPriceLine,
  LineData,
  LineStyle,
  LineWidth,
  MouseEventParams,
  Plot,
  SeriesMarker,
  SupportedSeries,
  Time,
  UTCTimestamp,
  volumeBar,
  VolumeSeriesName,
} from '../vendor/lightweight-charts'

import { TradingviewChartLegend } from './TradingviewChartLegend'
import { TradingviewLogo } from './TradingviewLogo'

let candles: Candle[] = []

export function Chart(
  props: {
    candles: Observable<CandleEvent>
    tradepair: string
    timeframe: ReadableTimeframe | KrakenReadableTimeframe
  },
): JSX.Element {
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>

  const [chart, setChart] = useState<IChartApi | undefined>(undefined)
  const [candleSubscription, setCandleSubscription] = useState<
    Subscription | undefined
  >(undefined)
  // TYPE: domain as IndicatorName
  const [chartPlots, setChartPlots] = useState<Map<string, Plot<SupportedSeries>>>(
    new Map(),
  )
  const [candle, setCandle] = useState(zeroCandle())
  const [crosshairSelectedBar, setCrosshairSelectedBar] = useState<
    MouseEventParams | undefined
  >(undefined)

  /**
   * We want to call `chart.timeScale().fitContent()` when all the historical candles
   * have been added to the chart to make the TradingView display show all the loaded data.
   * However, since we use a single observable to track historical and realtime candle updates,
   * we don't know exactly when this function should be called.
   *
   * As a workaround, we schedule the call to happen a short while after the first
   * candle loads, which hopefully permits the full historical timeseries to be
   * displayed too.
   */
  const [isFirstCandle, setIsFirstCandle] = useState(true)
  const [priceLines, setPriceLines] = useState<IPriceLine[]>([])

  const pyodidePlots = useStore((state) => state.plots)
  const tick = useStore((state) => state.tick)
  const backtestTrades = useStore((state) => state.backtestTrades)
  const openOrders = useStore((state) => state.orders)
  const showMarkers = useStore((state) => state.showMarkers)

  useEffect(() => {
    if (chart !== undefined) {
      chart.applyOptions({
        watermark: {
          text: `${props.tradepair}, ${props.timeframe}`,
        },
      })
    }
  }, [props.tradepair, props.timeframe])

  useEffect(
    () => {
      const tradingviewChart = createChart(ref.current, {
        height: 10,
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        timeScale: {
          timeVisible: true,
        },
      })

      setChart(tradingviewChart)

      tradingviewChart.applyOptions({
        watermark: {
          color: '#e9ecef',
          visible: true,
          text: `${props.tradepair}, ${props.timeframe}`,
          fontSize: 100,
          horzAlign: 'center',
          vertAlign: 'center',
        },
      })

      const newChartPlots = new Map(chartPlots)

      const candleSeries = tradingviewChart.addCandlestickSeries({
        priceFormat: {
          type: 'custom',
          minMove: 0.000001,
          formatter: (price: any) => {
            if (price < 0.000001) { return parseFloat(price).toFixed(8) }
            else if (price >= 0.000001 && price < 1) {
              return parseFloat(price).toFixed(6)
            } else { return parseFloat(price).toFixed(2) }
          },
        },
      })
      newChartPlots.set(CandleSeriesName, {
        type: 'Candlestick',
        series: candleSeries,
      })

      const volumeSeries = tradingviewChart.addHistogramSeries({
        color: '#26a69a',
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
        scaleMargins: {
          top: 0.8,
          bottom: 0,
        },
      })
      newChartPlots.set(VolumeSeriesName, {
        type: 'Histogram',
        series: volumeSeries,
      })

      // Add our candles and volume to the chart component
      setChartPlots(newChartPlots)

      // Creating candle legend OHLC
      tradingviewChart.subscribeCrosshairMove((mouseEvent) =>
        setCrosshairSelectedBar(mouseEvent),
      )

      const ro = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect
        tradingviewChart.applyOptions({ width, height })
        setTimeout(() => tradingviewChart.timeScale().fitContent(), 0)
      })

      ro.observe(document.querySelector('.chart') as HTMLDivElement)

      return function cleanup() {
        ro.disconnect()
        if (candleSubscription !== undefined) {
          candleSubscription.unsubscribe()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (chart === undefined) {
      // still initializing
      return
    }

    const subscription = pipe(
      props.candles,
      // tap(candle => console.log('candle', candle)),
      scan(
        (
          previousCandle: CandleEvent,
          candle: CandleEvent,
        ) => {
          const candlePlot = chartPlots.get(CandleSeriesName)
          const volumePlot = chartPlots.get(VolumeSeriesName)

          if (candle.event === 'new series') {
            candlePlot?.series.setData([])
            volumePlot?.series.setData([])
          } else if (candlePlot !== undefined && volumePlot !== undefined) {
            /*
          * We have to use setData if there are no items and we have cleared out the chart
          * Otherwise we will use update to add a candle in realtime
          */
            if (previousCandle.event === 'new series') {
              candlePlot.series.setData([barData(candle.candle)])
              volumePlot.series.setData([volumeBar(candle.candle)])
            } else {
              // This is due to when we switch streams, sometimes the websocket data and historical come
              // at different times. This prevents the error from lightweight charts not being able to update
              // old data. This happens on low liquidity tradepairs with no data fro awhile
              if (previousCandle.candle.time <= candle.candle.time) {
                candlePlot.series.update(barData(candle.candle))
                volumePlot.series.update(volumeBar(candle.candle))
              } 
            }
          } else {
            console.warn('Unable to update chart data with candle:', candle.candle)
          }

          if (isFirstCandle) {
            setIsFirstCandle(false)
          }
          return candle
        },
      ),
      scan((candles: Candle[], candle: CandleEvent) => {
        if (candle.event === 'new series') {
          setCandle(zeroCandle)
          candles = []
        } else {
          setCandle(candle.candle)
          pipe(
            NEA.fromArray(candles),
            O.map((candles) => {
              const previous = NEA.last(candles)
              if (previous.time < candle.candle.time) {
                candles.push(candle.candle)
              } else {
                candles[candles.length - 1] = candle.candle
              }
            }),
            O.getOrElse(() => {
              candles.push(candle.candle)
            }),
          )
        }

        return candles
      }, []),
    ).subscribe({
      next: (mostRecentCandles) => {
        candles = mostRecentCandles
      },
      error: (err) => {
        // TODO: do something drastic here
        console.error('The upstream observable has errored with', err)
      },
      complete: () => {
        // TODO: do something drastic here, this should never happen
        console.warn('The upstream observable has closed')
      },
    })

    setCandleSubscription(subscription)

    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [chart])

  useEffect(() => {
    if (chart === undefined) {
      return
    }
    setTimeout(() => chart.timeScale().fitContent(), 25)
  }, [chart, isFirstCandle])

  useEffect(() => {
    if (chart === undefined) {
      // still loading
      return
    }
    // remove the old plots before adding the new plots
    for (const [name, { series }] of chartPlots) {
      if (name === CandleSeriesName || name === VolumeSeriesName) {
        continue
      }
      chartPlots.delete(name)
      chart.removeSeries(series)
    }

    const newPlots = new Map(chartPlots)
    for (const [name, series] of pyodidePlots) {
      const linePlot = chart.addLineSeries({
        title: name,
        color: series.color,
      })
      newPlots.set(name, { type: 'Line', series: linePlot })
      const plotData: LineData[] = []
      for (
        let candlesIndex = 0, seriesIndex = 0;
        candlesIndex < candles.length && seriesIndex < series.values.length;
        candlesIndex++, seriesIndex++
      ) {
        if (Number.isNaN(series.values[seriesIndex])) {
          // skip over candles without corresponding signal data
          continue
        }
        /* eslint-disable security/detect-object-injection */
        plotData.push({
          time: ((candles[candlesIndex].time.getTime()
            / 1000) as unknown) as UTCTimestamp,
          value: series.values[seriesIndex],
        })
        /* eslint-enable security/detect-object-injection */
      }
      linePlot.setData(plotData)
    }

    // Add our pyodide plots to the chart component's map
    setChartPlots(newPlots)
  }, [chart, tick])

  // See if you can delete the price line
  // See if you can drag price line

  /*
  * This is to display the open orders on the chart
  */
  useEffect(() => {
    const lines: IPriceLine[] = []
    const lineWidth = 2 as LineWidth
    const candlePlot = chartPlots.get(CandleSeriesName)

    if (priceLines.length !== 0) {
      priceLines.forEach((line) => {
        candlePlot?.series.removePriceLine(line)
      })
    }

    openOrders.forEach((order) => {
      if (order.productID === props.tradepair) {
        const priceLine = {
          price: order.price,
          color: order.side === 'buy' ? 'green' : 'red',
          lineWidth: lineWidth,
          lineStyle: LineStyle.Dashed,
          axisLabelVisible: true,
          title: order.size.toString(),
          lineVisible: true,
        }
        const line = candlePlot?.series.createPriceLine(priceLine)

        if (line !== undefined) {
          lines.push(line)
        }
      }
    })

    setPriceLines(lines)
  }, [openOrders, props.tradepair, props.timeframe])

  useEffect(() => {
    if (chart === undefined) {
      // still loading
      return
    }
    const markers: SeriesMarker<Time>[] = []

    if (showMarkers) {
      for (
        let candlesIndex = 0, markersIndex = 0;
        candlesIndex < candles.length && markersIndex < backtestTrades.trades.length;
        candlesIndex++, markersIndex++
      ) {
        /* eslint-disable security/detect-object-injection */
        if (backtestTrades.trades[markersIndex].length > 0) {
          const element = backtestTrades.trades[markersIndex]
          const orderMessage = element.length > 1
            ? 'multiple orders'
            : `type: ${element[0].type}, exposure: ${element[0].exposure}`

          markers.push({
            time: ((candles[candlesIndex].time.getTime()
              / 1000) as unknown) as UTCTimestamp,
            position: 'belowBar',
            color: '#343a40',
            shape: 'arrowUp',
            text: orderMessage,
          })
        }
        /* eslint-disable security/detect-object-injection */
      }
    }
    // Assumption: we always want to set the markers on the candles series
    const candlesSeries = chartPlots.get(CandleSeriesName)!.series
    candlesSeries.setMarkers(markers)
  }, [chart, tick, showMarkers])

  return (
    <>
      <div className='chart' id='chart' ref={ref}></div>
      <TradingviewChartLegend
        candle={candle}
        plots={chartPlots}
        crosshairSelectedBar={crosshairSelectedBar}
      />
      <TradingviewLogo />
    </>
  )
}
