import React from 'react'
import { TerminalLayout } from '../Layout'

class PrivateTerminalRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { component: Component, location, ...rest } = this.props
    return (
      <TerminalLayout>
        <Component {...rest} />
      </TerminalLayout>
    )
  }
}

export default PrivateTerminalRoute
