import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as PathReporter from 'io-ts/lib/PathReporter'
import * as $ from 'rxjs'
import { WebSocketSubject } from 'rxjs/webSocket'
import { match } from 'ts-pattern'
import type { KrakenAuth } from '..'
import {
  KrakenHeartbeatMessage,
  KrakenSubscriptionMessage,
  KrakenOpenOrderWebsocketMessage,
  KrakenConnectionMessage,
  KrakenOpenOrderMessage,
  KrakenClosedOrderMessage,
  KrakenConfirmOpenMessage,
  KrakenMarketOpenOrderMessage,
  KrakenFilledOrderMessage,
} from '../websocket/codecs'

const listenToOpenOrderFeedMessages = ({
  openOrderFeedWebsocket,
}: {
  openOrderFeedWebsocket: $.Observable<unknown>
}) => {
  return pipe(
    openOrderFeedWebsocket,
    $.mergeMap((websocketMessage) => {
      // DEBUG:
      // if ((websocketMessage as any).type !== 'heartbeat') {
      //   console.log('Raw LOB websocket message:', websocketMessage)
      // }
      return pipe(
        KrakenOpenOrderWebsocketMessage.decode(websocketMessage),
        E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
        // Decode the websocket message and filter out messages we do
        // not require to track the user's current balances
        E.map((message) =>
          match<
            KrakenOpenOrderWebsocketMessage,
            $.Observable<
              | KrakenOpenOrderMessage
              | KrakenClosedOrderMessage
              | KrakenFilledOrderMessage
            >
          >(message)
            .when(KrakenHeartbeatMessage.is, () => $.EMPTY)
            .when(KrakenOpenOrderMessage.is, (order) => $.of(order))
            .when(KrakenMarketOpenOrderMessage.is, () => $.EMPTY)
            .when(KrakenFilledOrderMessage.is, (order) => $.of(order))
            .when(KrakenClosedOrderMessage.is, (order) => $.of(order))
            .when(KrakenSubscriptionMessage.is, () => $.EMPTY)
            .when(KrakenConnectionMessage.is, () => $.EMPTY)
            .when(KrakenConfirmOpenMessage.is, () => $.EMPTY)
            .otherwise(() => {
              console.warn('unmatched order message:', message)
              return $.EMPTY
            }),
        ),
        E.getOrElseW((error) => {
          throw new Error('Open order feed error: ' + error)
        }),
      )
    }),
  )
}

export const subscribeToOpenOrderWebsocket = ({
  auth,
  privateWebsocket,
}: {
  auth: KrakenAuth | undefined
  privateWebsocket: WebSocketSubject<unknown>
}) =>
  () => {
    // This occurs when the user either doesn't have auth set up or isn't active
    if (auth === undefined) {
      return $.of()
    }

    const subscribeToOpenOrderMessage = {
      event: 'subscribe',
      subscription: {
        "name": "openOrders",
        "token": auth.token
      }
    } as const

    const unsubscribeToOpenOrderMessage = {
      event: 'unsubscribe',
      subscription: {
        "name": "openOrders",
        "token": auth.token
      }
    } as const

    const openOrderFeedWebsocket = pipe(
      privateWebsocket.multiplex(
        () => (subscribeToOpenOrderMessage),
        () => (unsubscribeToOpenOrderMessage),
        (message: any): boolean => {
          return message.channelName === "openOrders" || message[1] === "openOrders"
        }
      ),
      $.retry(),
    )

    return listenToOpenOrderFeedMessages({
      openOrderFeedWebsocket,
    })
  }
