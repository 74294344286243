import * as t from 'io-ts'
import * as E from 'fp-ts/Either'
import {
  DateFromUnixTime,
  NumberFromString,
} from 'io-ts-types'
import { BigFromString } from '../../BigCodec'

export const KrakenTradepair = t.type({
  altname: t.string,
  wsname: t.string,
  base: t.string,
  quote: t.string,
  tick_size: NumberFromString,
  status: t.string,
  ordermin: NumberFromString,
}, 'KrakenTradepair')

export const KrakenTradepairs = t.array(KrakenTradepair, 'KrakenTradepairs')
export type KrakenTradepairs = t.TypeOf<typeof KrakenTradepairs>

export const KrakenTradepairData = 
  t.type({
    aclass: t.string,
    altname: t.string,
    decimals: t.number,
    display_decimals: t.number,
    status: t.string,
  }, 'KrakenTradepairData')

export const KrakenTradepairDataGroup = t.array( KrakenTradepairData, 'KrakenTradepairDataGroup')
export type KrakenTradepairDataGroup = t.TypeOf<typeof KrakenTradepairDataGroup>

export const KrakenProfileAccounts = t.type({
  result: t.record(
    t.string,
    BigFromString
  )
}, 'KrakenProfileAccounts')

t.type({
  time: DateFromUnixTime,
  open: NumberFromString,
  high: NumberFromString,
  low: NumberFromString,
  close: NumberFromString,
  vwap: NumberFromString,
  volume: NumberFromString,
  count: t.number
})

type TupleFn = <TCodecs extends readonly [t.Mixed, ...t.Mixed[]]>(
  codecs: TCodecs,
  name?: string,
) => t.TupleType<
  {
    -readonly [K in keyof TCodecs]: TCodecs[K];
  },
  {
    [K in keyof TCodecs]: TCodecs[K] extends t.Mixed
    ? t.TypeOf<TCodecs[K]>
    : unknown;
  },
  {
    [K in keyof TCodecs]: TCodecs[K] extends t.Mixed
    ? t.OutputOf<TCodecs[K]>
    : unknown;
  }
>;
const tuple: TupleFn = t.tuple as any;

const KrakenHistoricalCandle = tuple([
  DateFromUnixTime,
  NumberFromString,
  NumberFromString,
  NumberFromString,
  NumberFromString,
  NumberFromString,
  NumberFromString,
  t.Int
])
type KrakenHistoricalCandle = t.TypeOf<typeof KrakenHistoricalCandle>;

export type KrakenHistoricalCandleObject = {
  asset: KrakenHistoricalCandle[];
  last: t.Int;
}

export const KrakenHistoricalCandleGroupFromKrakenHistoricalCandles = new t.Type<KrakenHistoricalCandleObject, unknown, unknown>(
  'KrakenHistoricalCandleGroupFromKrakenHistoricalCandles',
  (u): u is KrakenHistoricalCandleObject => {
    throw new Error("This type guard is unimplemented")
  },
  (value, context) => {
    const maybeHistoricalCandles = KrakenHistoricalCandles.validate(value, context)
    if (E.isLeft(maybeHistoricalCandles)) {
      return maybeHistoricalCandles
    }

    const historicalCandles = maybeHistoricalCandles.right
    let asset: KrakenHistoricalCandle[] | undefined = undefined
    let last: t.Int | undefined = undefined
    // This codec is only implemented to handle a response with OHLC data
    // about a single tradepair
    if (Object.keys(historicalCandles.result).length > 2) {
      return t.failure(value, context)
    }

    // The value has decoded into the shape we expected, so now we map
    // it into the shape we desire
    for (const [key, value] of Object.entries(historicalCandles.result)) {
      if (t.Int.is(value)) {
        last = value
      } else {
        asset = value
      }
    }
    if (asset === undefined || last === undefined) {
      return t.failure(value, context)
    }

    return t.success({
      asset,
      last
    })
  },
  (value) => {
    throw new Error("This encode function is unimplemented")
  }
)

export const KrakenHistoricalCandles = t.type({
  result: t.record(
    t.string,
    t.union([
      t.array(KrakenHistoricalCandle),
      t.Int
    ])
  )
}, 'KrakenHistoricalCandles')

export type KrakenHistoricalCandles = t.TypeOf<typeof KrakenHistoricalCandles>