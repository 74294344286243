import { Subject } from 'rxjs'

/**
 * Thank the lord in heaven for this article:
 * https://jsonworld.com/blog/communicate-between-components-with-observable-and-subject-in-reactjs
 */
const subject = new Subject<string>()

export const programCodeService = {
  changeCode: (code: string) => subject.next(code),
  getCode: () => subject.asObservable(),
}
