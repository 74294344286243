import Big from 'big.js'

/*
 * This is required to be at least 9 to display the user balances
 * in written out instead of numerial form. This will match that
 * of Coinbase.
 */
Big.NE = -9

// https://help.coinbase.com/en/pro/trading-and-funding/trading-rules-and-fees/fees

// The docs say the highest is .4, but it it .6 when you run in sandbox...
// These rates are in percents
export const defaultMakerFeeRate = Big(0.60)
export const defaultTakerFeeRate = Big(0.60)

export const makerThresholdPercent = defaultMakerFeeRate.times(2)
export const takerThresholdPercent = defaultTakerFeeRate

// Threshold for market orders
// const threshold = (updatedBalances.minSize / signal.price * 2)
