import type { PyodideClient } from '../'
import { EventLogEvent, parseTradeParameters } from '../'
import { PyProxyTradeParameters, TradeParameters as BacktestTrades } from '../codecs'
import { UserSignalEvent } from '../event-log'
import { ProgramFlowEvent } from '../pyodide-states'

import { Unsafe } from './unsafe'

export const trade = ({
  pyodideClient,
  programFlowEvent,
  setBacktestTrades,
  eventlog,
}: {
  pyodideClient: PyodideClient
  programFlowEvent: ProgramFlowEvent
  setBacktestTrades: (backtestTrades: BacktestTrades) => void
  eventlog: EventLogEvent[]
}) =>
  (unparsedTradeParameters: Unsafe<PyProxyTradeParameters>): void => {
    const parameters: BacktestTrades = parseTradeParameters(pyodideClient)(
      unparsedTradeParameters,
    )
    setBacktestTrades(parameters)

    // Add the signal group to the event log for processing, if the user workflow is
    // in live-trading mode
    if (programFlowEvent === 'execute') {
      const latestSignals = parameters
        .trades[parameters.trades.length - 1] as UserSignalEvent[]

      eventlog.push(
        {
          type: 'signal',
          signals: latestSignals,
        },
      )
    }
  }
