import * as E from 'fp-ts/Either'
import { pipe } from "fp-ts/lib/function";
import { WebSocketSubject } from "rxjs/webSocket";
import * as $ from 'rxjs'
import { KrakenDeleteOrderErrorMessage, KrakenDeleteOrderResponseWebsocketMessage, KrakenDeleteOrderSuccessMessage } from "./codecs";
import * as PathReporter from 'io-ts/lib/PathReporter'
import { match } from 'ts-pattern'
import type { KrakenAuth } from '..'
import { OrderResponse } from '../..';

const listenToResponseMessages = ({
    deleteOrderWebsocket,
}: {
    deleteOrderWebsocket: $.Observable<unknown>,
}): $.Observable<OrderResponse> => {
    return pipe(
        deleteOrderWebsocket,
        $.mergeMap((websocketMessage): $.Observable<OrderResponse> => {
            return pipe(
                KrakenDeleteOrderResponseWebsocketMessage.decode(websocketMessage),
                E.mapLeft((errors) => PathReporter.failure(errors).join('\n')),
                // Decode the websocket message and filter out messages we do
                // not require to track the user's current balances
                E.map((message) =>
                    match<
                        KrakenDeleteOrderResponseWebsocketMessage,
                        $.Observable<OrderResponse>
                    >(message)
                        .when(KrakenDeleteOrderSuccessMessage.is, () => $.of({ type: 'ok', order: `deleted` }))
                        .when(KrakenDeleteOrderErrorMessage.is, (error) => $.of({ type: 'error', message: `error deleting order: ${error}` }))
                        .otherwise(() => {
                            console.warn('unmatched delete order response message:', message)
                            return $.EMPTY
                        }),
                ),
                E.getOrElseW((error) => {
                    throw new Error('Private websocket feed error: ' + error)
                }),
            )
        }),
    )
}

export const deleteOrder = ({ privateWebsocket, auth }: {
    privateWebsocket: WebSocketSubject<unknown>, auth: KrakenAuth | undefined
}) => ({
    txidOrders
}: {
    txidOrders: string[]
}): $.Observable<OrderResponse> => {
        if (auth === undefined) {
            return $.of({ type: 'error', message: `error deleting order: auth not defined` })
        }

        const deleteOrder = {
            event: "cancelOrder",
            token: auth.token,
            txid: txidOrders
        } as const

        const deleteOrderWebsocket =
            privateWebsocket.multiplex(
                () => (deleteOrder),
                //DISCUSS: do we need to put anything here?
                () => ({}),
                (message: any): boolean => message.event === "cancelOrderStatus"
            )

        return listenToResponseMessages({
            deleteOrderWebsocket
        })
    }