import Big from 'big.js'

import { Balances } from '../gateway'
import { CoinbaseTradepairs } from '../gateway/coinbase/rest/codecs'
import { KrakenTradepairs } from '../gateway/kraken/rest/codecs'
import { PortfolioType, ProductBalance } from '../types/index'
import { KrakenProductBalance } from '../types/OrderType'

/*
 * This is required to be at least 9 to display the user balances
 * in written out instead of numerial form. This will match that
 * of Coinbase.
 */
Big.NE = -9

export const getBalances = (
  tradepair: string,
  balances: Balances,
  products: CoinbaseTradepairs,
): ProductBalance => {
    const product = products
      .find((p) => p.id === tradepair)

    if (product !== undefined) {
      // Quote is the second listed tradepair. i.e. BTC-USD, the quote is USD
      // I believe this is total because we want expsoure to be on the entire account value of the asset
      const stringQuoteBalance = balances.get(product.quote_currency)?.total
      const quoteBalance = stringQuoteBalance !== undefined
        ? stringQuoteBalance
        : new Big(0)

      // Base is the first listed tradepair. i.e. BTC-USD, the base is BTC
      const stringBaseBalance = balances.get(product.base_currency)?.total
      const baseBalanceInBaseCurrency = stringBaseBalance !== undefined
        ? stringBaseBalance
        : new Big(0)

      const baseIncrement = product.base_increment
      const quoteIncrement = product.quote_increment
      const minSize = product.min_market_funds

      return {
        baseBalanceInBaseCurrency,
        quoteBalance,
        baseIncrement,
        quoteIncrement,
        minSize,
      }
    } else {
      return {
        baseBalanceInBaseCurrency: new Big(0),
        quoteBalance: new Big(0),
        baseIncrement: 0,
        quoteIncrement: 0,
        minSize: 0,
      }
    }
}

export const getKrakenBalances = (
  tradepair: string,
  balances: Balances,
  products: KrakenTradepairs,
): KrakenProductBalance => {
    const product = products
      .find((p) => p.wsname === tradepair)

    if (product !== undefined) {

      const baseCurrency = product.base
      const quoteCurrency = product.quote
      
      // Quote is the second listed tradepair. i.e. BTC-USD, the quote is USD
      // I believe this is total because we want expsoure to be on the entire account value of the asset
      const stringQuoteBalance = balances.get(quoteCurrency)?.total
      const quoteBalance = stringQuoteBalance !== undefined
        ? stringQuoteBalance
        : new Big(0)

      // Base is the first listed tradepair. i.e. BTC-USD, the base is BTC
      const stringBaseBalance = balances.get(baseCurrency)?.total
      const baseBalanceInBaseCurrency = stringBaseBalance !== undefined
        ? stringBaseBalance
        : new Big(0)

      const minSize = product.ordermin

      return {
        baseBalanceInBaseCurrency,
        quoteBalance,
        minSize,
      }
    } else {
      return {
        baseBalanceInBaseCurrency: new Big(0),
        quoteBalance: new Big(0),
        minSize: 0,
      }
    }
}

export const getCurrentTradepairPorfolio = (
  // Market orders with be candle price
  // Limit orders with be limit price
  price: number,
  baseBalanceInBaseCurrency: Big,
  quoteBalance: Big,
): PortfolioType => {
  const baseBalanceInQuoteCurrency = baseBalanceInBaseCurrency?.times(price)

  const totalBalanceInQuote = quoteBalance?.plus(baseBalanceInQuoteCurrency)

  const totalBalanceInBase = baseBalanceInBaseCurrency.plus(quoteBalance.div(price))

  return {
    baseBalanceInQuoteCurrency,
    totalBalanceInQuote,
    totalBalanceInBase,
  }
}
