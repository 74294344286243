import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as PathReporter from 'io-ts/lib/PathReporter'

import {
  PlotParameters,
  PyProxyPlotParameters,
  PyProxyTradeParameters,
  TradeParameters,
} from './codecs'
import { PyodideClient } from './pyodide-client'

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, security/detect-object-injection */
const parsePythonValue = <P extends t.Mixed, C extends t.Mixed>(
  {
    pythonTypeParser,
    javascriptTypeParser,
  }: {
    pythonTypeParser: P
    javascriptTypeParser: C
  },
) =>
  (pyodideClient: PyodideClient) =>
    (value: unknown) => {
      const pythonValue: any = pipe(
        pythonTypeParser.decode(value),
        E.getOrElseW((errors) => {
          throw new Error(
            // eslint-disable-next-line @typescript-eslint/quotes
            `Incorrect value format: `
              + PathReporter.failure(errors).join('\n'),
          )
        }),
      )
      for (const key of Object.keys(pythonValue)) {
        if (pyodideClient.isPyProxy(pythonValue[key]) === true) {
          pythonValue[key] = pythonValue[key].toJs({
            dict_converter: Object.fromEntries,
          })
        }
      }
      const parsedJs = pipe(
        javascriptTypeParser.decode(pythonValue),
        E.getOrElseW((errors) => {
          throw new Error(
            // eslint-disable-next-line @typescript-eslint/quotes
            `Incorrect type format: `
              + PathReporter.failure(errors).join('\n'),
          )
        }),
      )
      return parsedJs
    }
/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, security/detect-object-injection */

export const parsePlotParameters = parsePythonValue({
  pythonTypeParser: PyProxyPlotParameters,
  javascriptTypeParser: PlotParameters,
})

export const parseTradeParameters = parsePythonValue({
  pythonTypeParser: PyProxyTradeParameters,
  javascriptTypeParser: TradeParameters,
})
